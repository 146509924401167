import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { connectionSuccessImg } from 'src/images'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModalContainer } from 'src/portal/components/modals/ActionModal/ActionModalContainer'
import useModalContext from 'src/portal/hooks/useModalContext'
import { getAnalyticsPageView } from 'src/portal/utils/analytics'

const PaymentSuccessModalContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <InfoMessage content={t('Portal.Components.modal.paymentSuccess.infoMessage')} />
    </>
  )
}

export default function PaymentSuccessModal() {
  const { t } = useTranslation()
  const { closeAllModals } = useModalContext()
  const { paymentAmount } = useModalContext()
  const { currencyFormat } = useLocalizedFormatters()
  const location = useLocation()
  const modalName = `${location.pathname}/(manual-payment-success-modal)`
  useEffect(() => {
    getAnalyticsPageView(modalName)
  }, [])

  return (
    <ActionModalContainer
      modalName={modalName}
      title={t('Portal.Components.modal.paymentSuccess.title')}
      description={t('Portal.Components.modal.paymentSuccess.description', {
        amount: currencyFormat(paymentAmount),
      })}
      icon={connectionSuccessImg}
      content={<PaymentSuccessModalContent />}
      buttonText={t('Portal.Components.modal.paymentSuccess.buttonText')}
      closeButtonHandler={() => closeAllModals(modalName)}
      nextButtonHandler={() => closeAllModals(modalName)}
    />
  )
}
