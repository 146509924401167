import { Box, Typography } from '@mui/material'
import { CSSProperties } from 'react'

import PortalTheme from 'src/themes/portal'

type DropdownOption = {
  key: string
  label: string
  icon?: string
  isHidden?: boolean
}

type DropdownProps = {
  options: DropdownOption[]
  onSelect: (key: string) => void
  isOpen: boolean
  onToggle: () => void
  label: string
  icon?: string
  alt?: string
  toggleIcon?: { open: string; closed: string }
  dropdownStyle?: CSSProperties
}

const Dropdown = ({
  options,
  onSelect,
  label,
  icon,
  alt,
  toggleIcon,
  isOpen,
  onToggle,
  dropdownStyle,
}: DropdownProps) => {
  const handleSelect = (key: string) => {
    onSelect(key)
    onToggle() // Close the dropdown after selection
  }

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      {icon ? (
        <Box
          onClick={onToggle}
          sx={{ cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          aria-label={alt || label}
          onKeyDown={e => e.key === 'Enter' && onToggle()}
        >
          <img src={icon} alt={alt || label} />
        </Box>
      ) : (
        <Typography
          onClick={onToggle}
          variant="body2"
          fontWeight={600}
          color={PortalTheme.color.brand1}
          role="button"
          tabIndex={0}
          aria-expanded={isOpen}
          aria-label={label}
          onKeyDown={e => e.key === 'Enter' && onToggle()}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {label}
          {toggleIcon && (
            <Box component="span" sx={{ ml: 1 }}>
              <img src={isOpen ? toggleIcon.open : toggleIcon.closed} alt="" />
            </Box>
          )}
        </Typography>
      )}
      {isOpen && (
        <Box
          sx={{
            position: 'absolute',
            top: '100%',
            right: 0,
            mt: 1,
            p: 1,
            backgroundColor: 'white',
            border: `1px solid ${PortalTheme.color.grey4}`,
            borderRadius: '4px',
            boxShadow: '0px 4px 16px 0px #00000014',
            zIndex: 1,
            minWidth: '150px',
            maxWidth: '250px',
          }}
          style={dropdownStyle}
          role="menu"
        >
          {options
            .filter(x => !x.isHidden)
            .map(({ key, label, icon }) => (
              <Box
                key={key}
                onClick={() => handleSelect(key)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '8px 12px',
                }}
                role="menuitem"
                tabIndex={0}
                aria-label={label}
                onKeyDown={e => e.key === 'Enter' && handleSelect(key)}
              >
                {icon && <img src={icon} alt={label} style={{ marginRight: 8 }} />}
                <Typography
                  variant="body2"
                  color={PortalTheme.color.black}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {label}
                </Typography>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  )
}

export default Dropdown
