import { Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PortalRoutesEnum } from 'src/portal/utils/common'
import { PortalLink } from 'src/portal/components/PortalLink'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StatusIconEnum } from 'src/portal/components/types'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

interface PaymentProtectionContainerProps {
  paymentProtectionActive: boolean
}
export const PaymentProtectionContainer = ({
  paymentProtectionActive,
}: PaymentProtectionContainerProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.SUMMARY_TAB)

  if (!paymentProtectionActive) {
    return null
  }

  const navigateToPaymentProtectionPage = () => {
    navigate(`/${PortalRoutesEnum.PORTAL_BASE}/${PortalRoutesEnum.PORTAL_PAYMENT_PROTECTION}`)
    track(
      ANALYTICS_SEGMENT_CONSTANTS.SUMMARY_TAB_VIEW_PAYMENT_PROTECTION_CLICKED,
      ANALYTICS_OBJECT_CONSTANTS.BUTTON,
      ANALYTICS_ACTION_CONSTANTS.CLICKED,
    )
  }

  return (
    <SpacedRow>
      <Grid2>
        <StatusBadge
          iconVariant={StatusIconEnum.ACTIVE}
          label={t('Portal.Home.label.summary.paymentProtectionActive')}
        />
      </Grid2>
      <Grid2>
        <PortalLink
          text={t('Portal.Components.label.viewDetails')}
          onClickHandler={navigateToPaymentProtectionPage}
          iconDirection="right"
        />
      </Grid2>
    </SpacedRow>
  )
}
