import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { PrimaryCTA } from 'src/components/common/Buttons'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { genericErrorImg } from 'src/images'
import { ConnectionStepEnum } from 'src/portal/pages/flinks/types'
import {
  bankErrorConnectBankAccountClicked,
  bankErrorContactFigClicked,
  bankErrorLiveChatClicked,
} from 'src/utils'
import { getAnalyticsPageView } from 'src/portal/utils/analytics'

interface Props {
  setStep: (value: string) => void
}

export default function AccountAlreadyLinked({ setStep }: Props) {
  const { t } = useTranslation()
  const location = useLocation()
  useEffect(() => {
    getAnalyticsPageView(`${location.pathname}/(payment-method-already-linked-view)`)
  }, [])

  const screen = ConnectionStepEnum.ACCOUNT_ALREADY_LINKED as any

  const content = (
    <Trans i18nKey="Portal.Components.modal.accountAlreadyLinked.description">
      We weren’t able to connect to your bank account. Click the ‘Try again’ button, or{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={bankErrorLiveChatClicked}
        contactFigAnalytics={bankErrorContactFigClicked}
      />
    </Trans>
  )
  const tryAgainButton = (
    <PrimaryCTA
      onClick={() => {
        bankErrorConnectBankAccountClicked(t('common.retryLabel'), screen)
        setStep(ConnectionStepEnum.BANK_AUTO_CONNECT)
      }}
      buttonText={t('common.retryLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: genericErrorImg, alt: t('BankConnectError.alt') }}
      title={t('Portal.Components.modal.accountAlreadyLinked.title')}
      content={content}
      buttons={[tryAgainButton]}
    />
  )
}
