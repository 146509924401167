import React, { ReactNode } from 'react'

import { DetailsIcon } from 'src/portal/components/DetailsIcon'
import { ResponsiveTooltip } from 'src/portal/components/tooltips/ResponsiveTooltip'
import { useTooltip } from 'src/portal/components/tooltips/useTooltip'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'
interface TooltipContainerProps {
  title: string
  linkElement?: ReactNode
  content: ReactNode
  eventScreen: ANALYTICS_SCREEN_CONSTANTS
  eventName: string
}

export const TooltipContainer: React.FC<TooltipContainerProps> = ({
  title,
  linkElement,
  content,
  eventScreen,
  eventName,
}) => {
  const { isTooltipOpen, anchorEl, handleIconClick, handleCloseTooltip } = useTooltip()
  const track = getAnalyticsScreenCallback(eventScreen || ANALYTICS_SCREEN_CONSTANTS.TOOLTIP)

  const handleIconClickInternal = (event: React.MouseEvent<HTMLElement>) => {
    handleIconClick(event)
    track(
      `${eventScreen} / ${ANALYTICS_SEGMENT_CONSTANTS.HELP_ICON} / ${eventName} Show` as ANALYTICS_SEGMENT_CONSTANTS,
      ANALYTICS_OBJECT_CONSTANTS.ICON,
      ANALYTICS_ACTION_CONSTANTS.CLICKED,
    )
  }

  return (
    <>
      {linkElement ? (
        <span onClick={handleIconClickInternal}>{linkElement}</span>
      ) : (
        <DetailsIcon onClickHandler={handleIconClickInternal} />
      )}

      <ResponsiveTooltip
        isOpen={isTooltipOpen}
        anchorEl={anchorEl}
        onClose={() => {
          handleCloseTooltip()
          track(
            `${eventScreen} / ${ANALYTICS_SEGMENT_CONSTANTS.HELP_ICON} / ${eventName} Hide` as ANALYTICS_SEGMENT_CONSTANTS,
            ANALYTICS_OBJECT_CONSTANTS.ICON,
            ANALYTICS_ACTION_CONSTANTS.CLICKED,
          )
        }}
        title={title}
        content={content}
      />
    </>
  )
}
