import { useTranslation } from 'react-i18next'

import { LoanQueryResponseSchema, PaymentDetailResponseSchema } from 'src/portal/api/api.schemas'
import { PortalModalsEnum } from 'src/portal/utils/common'
import useModalContext from 'src/portal/hooks/useModalContext'
import { PayNowButton } from 'src/portal/pages/home/components/PayNowButton'
import { DueDateContainer } from 'src/portal/pages/home/summary/containers/DueDateContainer'
import { OutstandingAmountContainer } from 'src/portal/pages/home/summary/containers/OutstandingAmountContainer'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

export const OutstandingPaymentContainer = ({
  paymentDetailData,
  loanDetailData,
}: {
  paymentDetailData: PaymentDetailResponseSchema[] | undefined
  loanDetailData: LoanQueryResponseSchema | undefined
}) => {
  const outstandingAmount = paymentDetailData?.[0]?.outstanding_amount || 0
  const dueDate = loanDetailData?.overdue_payment_due_date || ''
  const { openModal } = useModalContext()
  const { t } = useTranslation()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.SUMMARY_TAB)
  return (
    <>
      <OutstandingAmountContainer outstandingAmount={outstandingAmount} />
      <DueDateContainer dueDate={dueDate} />
      <PayNowButton
        buttonText={t('Portal.Home.label.summary.payOutstandingAmount')}
        onClickHandler={() => {
          openModal(PortalModalsEnum.MANUAL_PAYMENT_MODAL)
          track(
            ANALYTICS_SEGMENT_CONSTANTS.SUMMARY_TAB_PAY_OUTSTANDING_CLICKED,
            ANALYTICS_OBJECT_CONSTANTS.BUTTON,
            ANALYTICS_ACTION_CONSTANTS.CLICKED,
          )
        }}
      />
    </>
  )
}
