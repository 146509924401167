import { AnalyticsBrowser } from '@segment/analytics-next'
import * as Sentry from '@sentry/react'

import { PortalRoutesEnum } from 'src/portal/utils/common'
import { ENVIRONMENT, SSP_SEGMENT_WRITE_KEY } from 'src/utils/constants'
import log from 'src/utils/log'

interface SegmentAnalytics {
  identify: (event: any, params: any) => void
  track: (event: any, params: any) => void
  page: (params?: any) => void
  group: () => void
}

const loadSegment = (): SegmentAnalytics => {
  // If for some reason segment isn't loading don't crash the app
  const isSSP = window.location.pathname.includes('/portal')
  try {
    if (ENVIRONMENT && SSP_SEGMENT_WRITE_KEY && isSSP) {
      return AnalyticsBrowser.load({ writeKey: SSP_SEGMENT_WRITE_KEY })
    }
  } catch (e) {
    Sentry.captureMessage(`Segment did not load due to ${e}`, 'warning')
  }

  return {
    identify: (event: any, params: any) => log.info('segment', 'identify', event, params),
    track: (event: any, params: any) => log.info('segment', 'track', event, params),
    page: (properties: any) => log.info('segment', 'page', properties),
    group: () => log.info('segment', 'group'),
  }
}

// This object is only set on local testing, staging, preprod and production
export const segmentAnalytics: SegmentAnalytics = loadSegment()

export enum ANALYTICS_SEGMENT_CONSTANTS {
  // Common
  HELP_ICON = 'Help Icon',
  FRESH_CHAT_WIDGET_OPEN = 'Fresh Chat Widget Open',
  FRESH_CHAT_WIDGET_CLOSE = 'Fresh Chat Widget Close',
  FRESH_CHAT_WIDGET_MESSAGE_SENT = 'Fresh Chat Widget Message Sent',
  FRESH_CHAT_WIDGET_MESSAGE_RECEIVED = 'Fresh Chat Widget Message Received',
  FRESH_CHAT_WIDGET_DIALOG_OPEN = 'Fresh Chat Widget Dialog  Open',
  FRESH_CHAT_WIDGET_DIALOG_CLOSE = 'Fresh Chat Widget Dialog Close',

  // Navigation
  NAV_ITEM_CLICKED = 'Navigation / Item Clicked',
  NAV_HOME_CLICKED = 'Navigation / Home Clicked',
  NAV_MANAGE_PAYMENT_CLICKED = 'Navigation / Manage Payment Clicked',
  NAV_VIEW_DOCUMENTS_CLICKED = 'Navigation / Document Clicked',
  NAV_HELP_CENTER_CLICKED = 'Navigation / Help Center Clicked',
  NAV_PRIVACY_CLICKED = 'Navigation / Privacy Clicked',
  NAV_TOC_CLICKED = 'Navigation / Terms And Conditions Clicked',
  NAV_TERMS_CLICKED = 'Navigation / Terms Clicked',
  NAV_MORE_CLICKED = 'Navigation / More Clicked',
  NAV_LOGOUT_CLICKED = 'Navigation / Logout Clicked',
  NAV_PROFILE_CLICKED = 'Navigation / Profile Clicked',
  NAV_LANGUAGE_CLICKED = 'Navigation / Language Clicked',
  NAV_REFERRAL_CLICKED = 'Navigation / Referral Clicked',

  // Home page - Summary tab
  HOME_SUMMARY_TAB_CLICKED = 'Home / Summary Tab Clicked',
  HOME_TRANSACTIONS_TAB_CLICKED = 'Home / Transactions Tab Clicked',
  HOME_UPCOMING_PAYMENTS_TAB_CLICKED = 'Home / Upcoming Payments Tab Clicked',

  // Profile
  PROFILE_UPDATE_SUBMIT_BUTTON_CLICKED = 'Profile / Update Submit Button Clicked',

  // Summary Tab
  SUMMARY_TAB_PAY_OUTSTANDING_CLICKED = 'Home / Summary / Pay Outstanding Amount Clicked',
  SUMMARY_TAB_SHOW_DETAILS_CLICKED = 'Home / Summary / Show Details Clicked',
  SUMMARY_TAB_HIDE_DETAILS_CLICKED = 'Home / Summary / Hide Details Clicked',
  SUMMARY_TAB_VIEW_AUTOMATED_PAYMENT_CLICKED = 'Home / Summary / Automated Payment View Details Clicked',
  SUMMARY_TAB_PAY_NOW_BUTTON_CLICKED = 'Home / Summary / Pay Now Button Clicked',
  SUMMARY_TAB_VIEW_PAYMENT_PROTECTION_CLICKED = 'Home / Summary / Payment Protection View Details Clicked',

  AUTOMATIC_PAYMENT_SCHEDULE_CHANGE_BUTTON_CLICKED = 'Summary / Automatic Payments / Change Payment Schedule Button Clicked',
  AUTOMATIC_PAYMENT_DELAY_NEXT_PAYMENT_BUTTON_CLICKED = 'Summary / Automatic Payments /  Delay Next Payment Button Clicked',
  AUTOMATIC_PAYMENT_METHOD_CHANGE = 'Summary / Automatic Payments / Change Payment Method Button Clicked',

  // Manual Payment
  PAYMENT_MANUAL_PAYMENT_BUTTON_CLICKED = 'Payments / Manual Payment Button Clicked',
  PAYMENT_MANUAL_PAYMENT_NEXT_BUTTON_CLICKED = 'Payments / Manual Payment / Choose Option / Next Button Clicked',
  PAYMENT_MANUAL_PAYMENT_OPTION_SELECTED = 'Payments / Manual Payment / Choose Option / Option Selected',
  PAYMENT_MANUAL_PAYMENT_CONFIRM_BUTTON_CLICKED = 'Payments / Manual Payment / Confirm / Submit Button Clicked',
  PAYMENT_MANUAL_PAYMENT_CONFIRM_CONSENT_CHECKED = 'Payments / Manual Payment / Confirm / Consent Checked',
  PAYMENT_MANUAL_PAYMENT_CONFIRM_CONSENT_UNCHECKED = 'Payments / Manual Payment / Confirm / Consent Unchecked',
  PAYMENT_MANUAL_PAYMENT_CONFIRM_BREAKDOWN_SHOW = 'Payments / Manual Payment / Confirm / Breakdown Show',
  PAYMENT_MANUAL_PAYMENT_CONFIRM_BREAKDOWN_HIDE = 'Payments / Manual Payment / Confirm / Breakdown Hide',
  PAYMENT_MANUAL_PAYMENT_ERROR_TRIGGERED = 'Payments / Manual Payment / Error Api / Triggered',

  // Payment schedule view
  PAYMENT_SCHEDULE_CHANGE_BUTTON_CLICKED = 'Payments / Change Payment Schedule Button Clicked',
  PAYMENT_SCHEDULE_CHANGE_SELECT_BUTTON_CLICKED = 'Payments / Change Payment Schedule / Select / Payment Schedule Button Clicked',
  PAYMENT_SCHEDULE_CHANGE_SELECT_ERROR_TRIGGERED = 'Payments / Payment Schedule / Select / Api Error Triggered',
  PAYMENT_SCHEDULE_CHANGE_EDIT_CONTINUE_BUTTON_CLICKED = 'Payments / Payment Schedule / Edit / Continue Clicked',
  PAYMENT_SCHEDULE_CHANGE_EDIT_FREQUENCY_SELECTED = 'Payments / Payment Schedule / Edit / Frequency Selected',
  PAYMENT_SCHEDULE_CHANGE_EDIT_ERROR_TRIGGERED = 'Payments / Payment Schedule / Edit / Api Error Triggered',
  PAYMENT_SCHEDULE_CHANGE_CONFIRM_COMPLETE_BUTTON_CLICKED = 'Payments / Payment Schedule / Confirm / Complete Clicked',
  PAYMENT_SCHEDULE_CHANGE_CONFIRM_FILE_DOWNLOADED = 'Payments / Payment Schedule / Confirm / File Pad Agreement Downloaded',
  PAYMENT_SCHEDULE_CONFIRM_ERROR_TRIGGERED = 'Payments / Payment Schedule / Confirm / Api Error Triggered',

  // Payment Method change
  PAYMENT_METHOD_CHANGE = 'Payments / Change Payment Method Button Clicked',
  PAYMENT_METHOD_CONFIRM_COMPLETE_BUTTON_CLICKED = 'Payments / Change Payment Method / Confirm / Complete Clicked',
  PAYMENT_METHOD_CONFIRM_CONSENT_CHECKED = 'Payments / Change Payment Method / Confirm / Consent Checked',
  PAYMENT_METHOD_CONFIRM_CONSENT_UNCHECKED = 'Payments / Change Payment Method / Confirm / Consent Unchecked',
  PAYMENT_METHOD_FILE_DOWNLOADED = 'Payments / Change Payment Method / File Pad Agreement Downloaded',
  PAYMENT_METHOD_ERROR_TRIGGERED = 'Payments / Change Payment Method / Error Triggered',

  // Delay next payment
  PAYMENT_DELAY_PAYMENT_SCHEDULE_SELECT_BUTTON_CLICKED = 'Payments / Change Payment Schedule / Select / Delay Payment Button Clicked',
  PAYMENT_DELAY_NEXT_PAYMENT_BUTTON_CLICKED = 'Payments / Delay Next Payment Button Clicked',
  PAYMENT_DELAY_START_CONTINUE_PAYMENT_BUTTON_CLICKED = 'Payments / Delay Payment / Start / Continue Button Clicked',
  PAYMENT_DELAY_START_ERROR_TRIGGERED = 'Payments / Delay Payment / Start / Api Error Triggered',
  PAYMENT_DELAY_CONFIRM_ERROR_TRIGGERED = 'Payments / Delay Payment / Confirm / Api Error Triggered',
  PAYMENT_DELAY_CONFIRM_NEXT_PAYMENT_SUCCESS_BUTTON_CLICKED = 'Payments / Delay Payment / Confirm / Complete Button Clicked',
  PAYMENT_DELAY_CONFIRM_FILE_DOWNLOADED = 'Payments / Delay Payment / Confirm / File Pad Agreement Downloaded',

  // Documents page
  DOCUMENTS_FILE_PAD_AGREEMENT_DOWNLOADED = 'Documents / File Pad Agreement Downloaded',
  DOCUMENTS_FILE_LOAN_AGREEMENT_DOWNLOADED = 'Documents / File Loan Agreement Downloaded',

  // Flinks
  FLINKS_EVENT = 'Flinks Event',
}

export enum ANALYTICS_SCREEN_CONSTANTS {
  NAVIGATION = 'Navigation',
  HOME_PAGE = 'Home Page',
  SUMMARY_TAB = 'Summary Tab',
  TRANSACTIONS_TAB = 'Transactions Tab',
  UPCOMING_PAYMENTS_TAB = 'Upcoming Payments Tab',
  PAYMENT_PAGE = 'Payments Page',
  DELAY_NEXT_PAYMENT = 'Delay Next Payment View',
  PAYMENT_SCHEDULE_VIEW = 'Payment Schedule View',
  PAYMENT_SCHEDULE_EDIT_VIEW = 'Payment Schedule Edit View',
  PAYMENT_SCHEDULE_CONFIRM_VIEW = 'Payment Schedule Confirm View',
  MAKE_PAYMENT = 'Make Payment View',
  DOCUMENT_PAGE = 'Document Page',
  PAYMENT_METHOD_CHANGE_VIEW = 'Payment Change Method View',
  MANUAL_PAYMENT = 'Manual Payment View',
  TOOLTIP = 'Tooltip',
  FRESH_CHAT_WIDGET = 'Fresh Chat Widget',
}

export enum ANALYTICS_OBJECT_CONSTANTS {
  BUTTON = 'Button',
  OPTION = 'Option',
  FILE = 'File',
  MODAL = 'Modal',
  API = 'API',
  ICON = 'Icon',
  TAB = 'Tab',
  MENU = 'Menu',
  CHECKBOX = 'Checkbox',
  EVENT = 'Event',
  FRESH_CHAT_WIDGET = 'Fresh Chat Widget',
}

export enum ANALYTICS_ACTION_CONSTANTS {
  CLICKED = 'Clicked',
  DOWNLOADED = 'Downloaded',
  RESPONSE = 'Response',
  SELECTED = 'Selected',
  TRIGGERED = 'Triggered',
}

//   TODO: write for each page

export const getAnalyticsCallback =
  (segmentName: string) =>
  (object: string, page_name: PortalRoutesEnum, action = 'Clicked', extra?: Record<string, any>) =>
    segmentAnalytics.track(segmentName, {
      object,
      page_name,
      action,
      ...extra,
    })

export const getAnalyticsScreenCallback =
  (screen: ANALYTICS_SCREEN_CONSTANTS) =>
  (
    segmentName: ANALYTICS_SEGMENT_CONSTANTS,
    object: ANALYTICS_OBJECT_CONSTANTS,
    action: ANALYTICS_ACTION_CONSTANTS = ANALYTICS_ACTION_CONSTANTS.CLICKED,
    extra?: Record<string, any>,
  ) =>
    segmentAnalytics.track(segmentName, {
      object,
      page_name: screen,
      action,
      ...extra,
    })

export const getAnalyticsPageView = (path: string) =>
  segmentAnalytics.page({
    path,
    title: document.title,
    referrer: document.URL,
    url: document.URL,
  })
