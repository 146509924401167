import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { connectionSuccessImg } from 'src/images'
import { ActionModalContainer } from 'src/portal/components/modals/ActionModal/ActionModalContainer'
import useModalContext from 'src/portal/hooks/useModalContext'
import { getAnalyticsPageView } from 'src/portal/utils/analytics'

export default function DelayNextPaymentSuccessModal() {
  const { t } = useTranslation()
  const { closeAllModals } = useModalContext()
  const location = useLocation()
  const modalName = `${location.pathname}/(payment-deferral-success-modal)`
  useEffect(() => {
    getAnalyticsPageView(modalName)
  }, [])

  return (
    <ActionModalContainer
      modalName={modalName}
      title={t('Portal.Components.modal.paymentDeferralSuccess.title')}
      icon={connectionSuccessImg}
      content={<></>}
      buttonText={t('Portal.Components.modal.paymentDeferralSuccess.buttonText')}
      closeButtonHandler={() => closeAllModals(modalName)}
      nextButtonHandler={() => closeAllModals(modalName)}
    />
  )
}
