import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PortalTheme from 'src/themes/portal'

interface TabNavigationProps {
  tabList: TabItem[]
  selectedTab: number
  onTabChange: (newValue: number) => void
}

export interface TabItem {
  label: string
}

/**
 * TabNavigation component renders a navigation bar with tabs.
 *
 * @param {TabItem[]} tabList - An array of tab items to be displayed.
 * @param {number} selectedTab - The index of the currently selected tab.
 * @param {(newValue: number) => void} onTabChange - Callback function to handle tab change.
 *
 * @returns {JSX.Element} The rendered TabNavigation component.
 */
export const TabNavigation: React.FC<TabNavigationProps> = ({
  tabList,
  selectedTab,
  onTabChange,
}) => {
  const { t } = useTranslation()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        border: `1px solid ${PortalTheme.color.grey3}`,
        overflow: 'hidden',
        backgroundColor: PortalTheme.color.white,
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="fullWidth"
        centered
        TabIndicatorProps={{
          style: { display: 'none' }, // Hide default indicator
        }}
        sx={{
          width: '100%',
        }}
      >
        {tabList.map((tab: TabItem, index: number) => (
          <Tab
            key={index}
            label={t(tab.label as any)}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: selectedTab === index ? 600 : 400,
              color: selectedTab === index ? PortalTheme.color.brand1 : PortalTheme.color.grey9,
              bgcolor: selectedTab === index ? PortalTheme.color.brand5 : 'transparent',
              borderRadius: '8px',
              minHeight: '48px',
              minWidth: '100px',
              '&:hover': {
                bgcolor: selectedTab === index ? PortalTheme.color.brand5 : PortalTheme.color.grey2,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  )
}
