import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { captureMessage } from '@sentry/react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'

import { EnglishLanguageKey, supportedLanguages } from 'src/locales'
import { LanguagePreferenceType, StepsEnum } from 'src/types'
import { saveLanguagePreference } from 'src/hooks/useApi'
import { EXPIRY_DETAIL } from 'src/utils/constants'
import { parseUrl } from 'src/utils'

/*
 *
 */
export const useChangeLanguage = (jwtOrigination: string | undefined) => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const { currentStep } = useMemo(parseUrl, [location.pathname])

  const { mutate: saveLanguageMutation } = useMutation(
    ({ payload, jwtOrigination }: { payload: LanguagePreferenceType; jwtOrigination: string }) =>
      saveLanguagePreference(payload, jwtOrigination),
    {
      useErrorBoundary: false,
      onError: (e: any) => {
        if (e.response.data.detail === EXPIRY_DETAIL) {
          captureMessage('Save language preference called on an expired app', 'info')
          return
        }
      },
    },
  )

  useEffect(() => {
    if (currentStep === StepsEnum.LOAN_PENDING) {
      return
    }
    if (!jwtOrigination) {
      return
    }
    const selectedLanguage = i18n.resolvedLanguage

    for (const supportedLanguage of supportedLanguages) {
      if (supportedLanguage === selectedLanguage) {
        saveLanguageMutation({ payload: { locale: selectedLanguage }, jwtOrigination })
        return
      }
    }
    captureMessage(`Unhandled language: ${selectedLanguage}`, 'warning')
    i18n.changeLanguage(EnglishLanguageKey) // caution setting dependency within a useEffect
  }, [jwtOrigination, i18n.resolvedLanguage])
}
