import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, GlobalStyles, Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { getNinjaAPI } from 'src/portal/api/api'
import PortalTheme from 'src/themes/portal'
import { connectionSuccessImg } from 'src/images'
import { DivRoot } from 'src/portal/pages/reschedule/styles'
import { getAnalyticsPageView } from 'src/portal/utils/analytics'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import Footer from 'src/portal/layout/footer/Footer'
import { captureExceptionHelper } from 'src/utils'
import { chevronDownicon, chevronUpicon, figLogo } from 'src/images'
import { EnglishLanguageKey, FrenchLanguageKey } from 'src/locales'
import Dropdown from 'src/portal/layout/header/Dropdown'
import { DropdownMenuEnum, PortalRoutesEnum } from 'src/portal/utils/common'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'
import { genericErrorImg } from 'src/images'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

const CustomImage = styled('img')({
  width: '80px',
  height: '80px',
})

const CustomTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  textAlign: 'center',
  marginBottom: '16px',
  whiteSpace: 'pre-line',
}))

const CustomSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  textAlign: 'center',
  marginBottom: '16px',
  whiteSpace: 'pre-line',
}))

const GridImage = styled(Grid2)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '32px',
})

const CustomImageGrid = styled(GridImage)({
  marginBottom: '32px',
})

const Header = () => {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const [openDropdown, setOpenDropdown] = useState<DropdownMenuEnum | null>(null)
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.NAVIGATION)

  const handleDropdownToggle = (dropdownKey: DropdownMenuEnum) => {
    setOpenDropdown(prevKey => (prevKey === dropdownKey ? null : dropdownKey))
  }

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${PortalTheme.color.grey4}`,
        backgroundColor: PortalTheme.color.white,
      }}
    >
      <Grid2 container sx={{ py: 2, px: 4 }} alignItems="center">
        <Grid2 size={6}>
          <img
            src={figLogo}
            alt={t('Portal.Header.alt.figLogo')}
            onClick={() => {
              track(
                ANALYTICS_SEGMENT_CONSTANTS.NAV_HOME_CLICKED,
                ANALYTICS_OBJECT_CONSTANTS.MENU,
                ANALYTICS_ACTION_CONSTANTS.CLICKED,
              )
              navigate(PortalRoutesEnum.PORTAL_INDEX)
            }}
            role="button"
            tabIndex={0}
            aria-label={t('Portal.Header.alt.figLogo')}
          />
        </Grid2>
        <Grid2 size={6}>
          <Grid2 container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid2>
              <Dropdown
                options={[
                  { key: EnglishLanguageKey, label: 'English' },
                  { key: FrenchLanguageKey, label: 'Français' },
                ]}
                isOpen={openDropdown === DropdownMenuEnum.LANGUAGE}
                onToggle={() => handleDropdownToggle(DropdownMenuEnum.LANGUAGE)}
                onSelect={languageKey => {
                  i18n.changeLanguage(languageKey)
                  track(
                    `${ANALYTICS_SEGMENT_CONSTANTS.NAV_LANGUAGE_CLICKED} ${languageKey}` as ANALYTICS_SEGMENT_CONSTANTS,
                    ANALYTICS_OBJECT_CONSTANTS.MENU,
                    ANALYTICS_ACTION_CONSTANTS.CLICKED,
                  )
                }}
                label={i18n.resolvedLanguage?.toUpperCase() || ''}
                toggleIcon={{ open: chevronUpicon, closed: chevronDownicon }}
                dropdownStyle={{ marginTop: '16px' }}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  )
}

function Success() {
  const { t } = useTranslation()

  return (
    <DivRoot>
      <Grid2 container>
        <Grid2 size={12}>
          <CustomImageGrid size={12}>
            <CustomImage src={connectionSuccessImg} alt={t('Unsubscribe.title')} />
          </CustomImageGrid>

          <Grid2 size={12}>
            <CustomTitle data-testid="errorTitle">{t('Unsubscribe.title')}</CustomTitle>
            <CustomSubTitle data-testid="errorTitle">{t('Unsubscribe.description')}</CustomSubTitle>
          </Grid2>
        </Grid2>
      </Grid2>
    </DivRoot>
  )
}

export default function Unsubscribe() {
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const api = getNinjaAPI()
  const [searchParams] = useSearchParams()

  const lang = searchParams.get('lang')
  const guid = searchParams.get('guid')

  const portalApiUnsubscribe = async (guid: string) => {
    const { data } = await api.portalApiUnsubscribe(guid)
    return data
  }

  const { mutate } = useMutation(['portalApiUnsubscribe'], portalApiUnsubscribe, {
    useErrorBoundary: false,
    onError: (error: any) => {
      setIsError(true)
      captureExceptionHelper(`Error unsubscribing user: ${error}`, error)
    },
    onSuccess: () => {
      setIsSuccess(true)
    },
  })

  useEffect(() => {
    getAnalyticsPageView(location.pathname)
  }, [])

  useEffect(() => {
    if (guid) {
      mutate(guid)
    }
  }, [guid])

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [lang])

  let component = <LoadingAnimation subtitle={t('Unsubscribe.loading')} />
  if (isSuccess) {
    component = <Success />
  }

  if (isError) {
    component = (
      <ErrorPage
        image={{
          src: genericErrorImg,
          alt: t('GenericError.alt'),
        }}
        title={t('GenericError.title')}
        content=""
        buttons={[]}
      />
    )
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <GlobalStyles
        styles={{
          width: '100%',
          [PortalTheme.breakpoints.up('md')]: {
            '.fc-widget-normal': { bottom: '48px !important', right: '48px !important' },
          },
          [PortalTheme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '120px !important', right: '24px !important' },
          },
          backgroundColor: PortalTheme.color.brand1,
        }}
      />

      <Header />

      <DivRoot>{component}</DivRoot>

      <Footer />
    </Box>
  )
}
