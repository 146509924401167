import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FigTextField from 'src/components/common/FigTextField'

interface NameInputProps {
  value: string
  label: string
}

export const NameInput = ({ value, label }: NameInputProps) => {
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { t } = useTranslation()
  return (
    <FigTextField
      data-testid="confirm-name-input"
      value={value}
      autoComplete="name"
      label={label}
      onClick={() => {
        setErrorState(true)
        setErrorMessage(t('CreditRenewals.ConfirmInformation.nameInput.errorMessage'))
      }}
      InputProps={{
        'aria-invalid': errorState, // Inform screen readers when the input is invalid
        'aria-describedby': errorState ? 'name-input-error-text' : undefined, // Link to error text
        readOnly: true, // Still keep readOnly, but ensure focusable for keyboard users
        tabIndex: 0, // Ensure it's still keyboard navigable
      }}
      helperText={errorState ? errorMessage : ''}
      error={errorState}
      errorMessage={errorMessage}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: errorState ? 'grey.400' : 'grey.400',
          },
          '& .MuiInputBase-input': {
            color: errorState ? 'grey.600' : 'grey.600',
          },
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: 'grey.600',
        },
        '& .MuiFormHelperText-root': {
          color: errorState ? 'error.main' : 'grey.600',
        },
      }}
    />
  )
}
