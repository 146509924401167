import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useOriginationContext } from 'src/hooks'
import { GetApplicationStatusResponse, StepsEnum } from 'src/types'
import { captureExceptionHelper } from 'src/utils'
import { EXPIRY_DETAIL } from 'src/utils/constants'

export default LoanOffer
/**
 *
 */
function LoanOffer() {
  const [searchParams] = useSearchParams()
  const { jwtApiKey, setStep } = useOriginationContext()
  const { getApplicationSyncStatus } = useApi()
  const { t } = useTranslation()

  const [retryCount, setRetryCount] = useState<number>(0)
  const pollingTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const pollTimeMs = 2000
  const maxRetry = 30

  // this query will be run once on init
  // This will keep polling until application sync to Q2 is completed.
  // Once completed we should get the application url and redirect to it.
  const { refetch: getApplicationSyncStatusQuery } = useQuery<
    GetApplicationStatusResponse,
    any,
    any
  >(['get_application_sync_status'], getApplicationSyncStatus, {
    cacheTime: 0,
    enabled: !!jwtApiKey,
    onSuccess: (result: GetApplicationStatusResponse) => {
      const data = result.data

      if (!data.is_finished) {
        // keep polling until Application Sync is complete
        continuePolling()
        return
      } else {
        window.location.href = data.application_url + '?' + searchParams.toString()
        return
      }
    },
    useErrorBoundary: false,
    onError: (result: any) => {
      if (result.response?.data?.detail === EXPIRY_DETAIL) {
        setStep(StepsEnum.EXPIRED)
        return
      }
      captureExceptionHelper('Error polling for application sync status', result)
      continuePolling()
      return
    },
  })

  const clearExistingPollingTimeout = () => {
    clearTimeout(pollingTimeoutRef.current ?? undefined)
  }

  const continuePolling = () => {
    setRetryCount(prevCount => prevCount + 1)
    if (retryCount == maxRetry) {
      setStep(StepsEnum.ERROR)
      return
    }
    clearExistingPollingTimeout()
    pollingTimeoutRef.current = setTimeout(getApplicationSyncStatusQuery, pollTimeMs)
  }

  useEffect(() => {
    return clearExistingPollingTimeout
  }, [])

  return <LoadingAnimation subtitle={t('init.loading')} />
}
