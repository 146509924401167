import { Grid2, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import {
  portalPaymentsChangePaymentMethodIcon,
  portalPaymentsChangePaymentScheduleIcon,
  portalPaymentsDelayNextPaymentIcon,
  portalPaymentsMakeManualPaymentIcon,
} from 'src/images'
import { LoanStatusEnum, PortalModalsEnum, PortalRoutesEnum } from 'src/portal/utils/common'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { OverdueMessage } from 'src/portal/pages/payments/components/OverdueMessage'
import { PaymentMenuItem } from 'src/portal/pages/payments/components/PaymentMenuItem'
import { usePaymentDeferralEligibility } from 'src/portal/pages/payments/hooks/usePaymentDeferralEligibility'
import PortalTheme from 'src/themes/portal'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'
import { ApplicationStatus } from 'src/types'

const Payments = () => {
  const navigate = useNavigate()
  const { openModal } = useModalContext()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.PAYMENT_PAGE)
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const {
    portalApiPaymentScheduleChange: {
      validateMutation,
      processSelectedDateMutation,
      completeMutation,
    },
  } = usePortalContext()

  const isDesktop = useMediaQuery(PortalTheme.breakpoints.down('md'))
  const { isEligibleToDelayNextPayment, manualPaymentsOn, loanStatus } =
    usePaymentDeferralEligibility()
  const isSettled =
    (loanDetailData?.application_status as ApplicationStatus) === ApplicationStatus.SETTLED

  const isEligibleToChangePaymentSchedule =
    loanStatus !== LoanStatusEnum.OVERDUE && !manualPaymentsOn

  const getManualPaymentDescription = () => {
    if (loanStatus === LoanStatusEnum.OVERDUE) {
      return t('Portal.Payments.label.options.overduePaymentDescription')
    }
    if (manualPaymentsOn) {
      return t('Portal.Payments.label.options.manualPaymentUserDescription')
    }
    return t('Portal.Payments.label.options.manualPayment.description')
  }

  const menuItemList = [
    {
      title: t('Portal.Payments.label.options.manualPayment.title'),
      description: getManualPaymentDescription(),
      icon: portalPaymentsMakeManualPaymentIcon,
      onClickHandler: () => {
        openModal(PortalModalsEnum.MANUAL_PAYMENT_MODAL)
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_MANUAL_PAYMENT_BUTTON_CLICKED,
          ANALYTICS_OBJECT_CONSTANTS.BUTTON,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
      },
    },
    isEligibleToDelayNextPayment
      ? {
          title: t('Portal.Payments.label.options.delayNextPayment.title'),
          description: t('Portal.Payments.label.options.delayNextPayment.description'),
          icon: portalPaymentsDelayNextPaymentIcon,
          onClickHandler: () => {
            openModal(PortalModalsEnum.DELAY_NEXT_PAYMENT_MODAL)
            track(
              ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_DELAY_NEXT_PAYMENT_BUTTON_CLICKED,
              ANALYTICS_OBJECT_CONSTANTS.BUTTON,
              ANALYTICS_ACTION_CONSTANTS.CLICKED,
            )
          },
        }
      : null,
    isEligibleToChangePaymentSchedule
      ? {
          title: t('Portal.Payments.label.options.changePaymentSchedule.title'),
          description: t('Portal.Payments.label.options.changePaymentSchedule.description'),
          icon: portalPaymentsChangePaymentScheduleIcon,
          onClickHandler: () => {
            // Reset all internal states to clear cached data in order for the
            // user to start fresh when changing the payment schedule
            validateMutation.reset()
            processSelectedDateMutation.reset()
            completeMutation.reset()
            validateMutation.mutate({})
            openModal(PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_START)
            track(
              ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_SCHEDULE_CHANGE_BUTTON_CLICKED,
              ANALYTICS_OBJECT_CONSTANTS.BUTTON,
              ANALYTICS_ACTION_CONSTANTS.CLICKED,
            )
          },
        }
      : null,
    {
      title: t('Portal.Payments.label.options.changePaymentMethod.title'),
      description: t('Portal.Payments.label.options.changePaymentMethod.description'),
      icon: portalPaymentsChangePaymentMethodIcon,
      onClickHandler: () => {
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_METHOD_CHANGE,
          ANALYTICS_OBJECT_CONSTANTS.BUTTON,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
        navigate(`/${PortalRoutesEnum.PORTAL_BASE}/${PortalRoutesEnum.PORTAL_CHANGE_BANK_ACCOUNT}`)
      },
    },
  ]

  if (!loanStatus || !isSettled) {
    return <LoadingAnimation />
  }

  return (
    <Grid2 container>
      <Grid2 pb="16px" size={12}>
        <Typography variant="h3">{t('Portal.Payments.label.title')}</Typography>
        <Typography variant="body2">{t('Portal.Payments.label.description')}</Typography>
        {loanStatus === LoanStatusEnum.OVERDUE && <OverdueMessage />}
      </Grid2>
      {menuItemList
        .filter(item => item !== null)
        .map((item, index) => (
          <Grid2 key={index} size={{ sm: 12, md: 6 }} sx={{ pr: isDesktop ? 0 : 3 }}>
            <PaymentMenuItem
              title={item.title}
              description={item.description}
              icon={item.icon}
              onClickHandler={item.onClickHandler}
            />
          </Grid2>
        ))}
    </Grid2>
  )
}

export default Payments
