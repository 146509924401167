import { useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Box, Link, Slider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { throttle } from 'throttle-debounce'
import { useQuery } from 'react-query'
import * as Sentry from '@sentry/react'
import { useTranslation, Trans } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useOriginationContext } from 'src/hooks'
import { DivRoot } from 'src/components/common/DivRoot'
import { Navigator, StepsEnum } from 'src/types'
import theme, { figColors } from 'src/themes'
import { DEFAULT_CONSENTS, ENVIRONMENT, LOAN_AMOUNT_STEP } from 'src/utils/constants'
import {
  loanOfferTrackAmountChanged,
  privacyPolicyDownloaded,
  selectLoanOfferAmountConfirmed,
} from 'src/utils/analytics'
import { lockIcon } from 'src/images'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'

const ConsentTypography = styled(Typography)({
  fontWeight: 400,
  color: theme.color.grey6,
  fontSize: '12px',
  lineHeight: '18px',
})

const ConsentLink = styled(Link)({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '18px',
})

const APRGuarantee = styled(Grid)({
  backgroundColor: figColors.brand6,
  marginTop: '12px',
  padding: '12px 33px',
  justifyContent: 'center',
  borderRadius: '8px',
  flexDirection: 'column',
  '> img': {
    marginBottom: '4px',
  },
  '> p': {
    lineHeight: '24px',
    color: 'black',
  },
})

export default LoanAmount
/**
 *
 */
function LoanAmount() {
  const {
    bootstrapInfo,
    isBootstrapSuccess,
    maxLoanAmount,
    minLoanAmount,
    cachedApplication,
    updateCachedApplication,
    setStep,
    campaignId,
    partnerSessionId,
    isQCResident,
  } = useOriginationContext()
  const { t } = useTranslation()
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const { savePartnerSession, saveCampaignId, saveEnteredOrigination } = useApi()

  const hasSavedQCConsents =
    cachedApplication.has_saved_qc_consents ||
    (bootstrapInfo?.application?.accept_biometric_consent &&
      bootstrapInfo.application.accept_right_to_access_consent)

  useEffect(() => {
    if (isQCResident && !hasSavedQCConsents) {
      setStep(StepsEnum.QC_CONSENTS)
    }
  }, [isQCResident, cachedApplication.has_saved_qc_consents, bootstrapInfo.application])

  const selectedLoanAmount = cachedApplication.selected_loan_amount || maxLoanAmount

  const consents = cachedApplication.consents || DEFAULT_CONSENTS

  const privacyPolicyLabel = t('common.privacyPolicyLabel')
  const termsAndConditionsLabel = t('common.termsAndConditionsLabel')

  const throttledTrackAmountChanged = useRef(
    throttle(2000, (amount: number | number[]) => {
      loanOfferTrackAmountChanged(amount)
    }),
  )

  const iOS = () => {
    const platform = (navigator as Navigator).userAgentData?.platform || navigator.platform
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }
  const isIOS = iOS()

  const handleLoanAmountChange = (event: Event, selectedLoanAmount: number | number[]) => {
    // Mouse events and touch events seem to both fire for some mobile browsers.
    // https://github.com/mui/material-ui/issues/31869#issuecomment-1357755366
    if (isIOS && event.type === 'mousedown') {
      return
    }

    if (typeof selectedLoanAmount === 'number') {
      updateCachedApplication({
        selected_loan_amount: selectedLoanAmount,
        saved_repayment_schedule: null,
        // Reset repayment schedule to monthly as the amount/offers are now changed.
      })
      throttledTrackAmountChanged.current(selectedLoanAmount)
    }
  }

  const submitLoanAmount = () => {
    updateCachedApplication({
      consents: { ...consents, accept_policy: true },
    })
    selectLoanOfferAmountConfirmed(StepsEnum.LOAN_AMOUNT)
    setStep(StepsEnum.SELECT_OFFER)
  }

  const { refetch: refetchPartnerSessionId } = useQuery(
    ['savePartnerSessionId', partnerSessionId],
    () => savePartnerSession(partnerSessionId),
    {
      enabled: false,
      useErrorBoundary: false,
      onError: () => {
        Sentry.captureMessage(
          `Save partner session encountered an error at Loan Amount page in ${ENVIRONMENT}`,
          'warning',
        )
      },
    },
  )

  const { refetch: refetchCampaignId } = useQuery(
    ['saveCampaignId', campaignId],
    () => saveCampaignId(campaignId),
    {
      enabled: false,
      useErrorBoundary: false,
      onError: () => {
        Sentry.captureMessage(
          `Save campaign id encountered an error at Loan Amount page in ${ENVIRONMENT}`,
          'warning',
        )
      },
    },
  )

  // Runs when the page loads
  useQuery(['saveEnteredOrigination'], () => saveEnteredOrigination(), {
    enabled: true,
    useErrorBoundary: false,
    onError: () => {
      Sentry.captureMessage(
        `Save Entered Origination encountered an error at Loan Amount page in ${ENVIRONMENT}`,
        'warning',
      )
    },
  })

  const firstName = bootstrapInfo?.borrower?.borrower_first_name
  const apr = bootstrapInfo?.application?.apr

  useEffect(() => {
    if (partnerSessionId) {
      refetchPartnerSessionId()
    }

    if (campaignId) {
      refetchCampaignId()
    }
  }, [])

  function openPrivacyPolicy() {
    privacyPolicyDownloaded(privacyPolicyLabel)
    window.open(t('common.privacyPolicyUrl'), '_blank')
  }

  function openTermsAndConditions() {
    privacyPolicyDownloaded(termsAndConditionsLabel)
    window.open(t('common.termsAndConditionsUrl'), '_blank')
  }

  if (!isBootstrapSuccess) {
    return <LoadingAnimation />
  }

  const TermsAndConditionsLink = () => (
    <ConsentLink onClick={openTermsAndConditions}>{termsAndConditionsLabel}</ConsentLink>
  )
  const PrivacyPolicyLink = () => (
    <ConsentLink onClick={openPrivacyPolicy}>{privacyPolicyLabel}</ConsentLink>
  )

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('LoanAmount.loanAmountLabel', { firstName })}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop="32px" marginBottom="4px">
          <Typography
            data-testid="loanAmount"
            variant="h2"
            color={theme.color.grey9}
            textAlign="center"
            fontSize="24px"
          >
            {currencyFormat(selectedLoanAmount, 0)}
          </Typography>
          <Box marginTop="12px" paddingLeft="0px" paddingRight="0px">
            <Slider
              data-testid="loanAmountSlider"
              value={selectedLoanAmount}
              onChange={handleLoanAmountChange}
              aria-label="Default"
              step={LOAN_AMOUNT_STEP}
              min={minLoanAmount}
              max={maxLoanAmount}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="left" data-testid="minLoanAmt">
            {currencyFormat(minLoanAmount, 0)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="right" data-testid="maxLoanAmt">
            {currencyFormat(maxLoanAmount, 0)}
          </Typography>
        </Grid>
        <APRGuarantee container>
          <img src={lockIcon} alt="lock" />
          <Typography variant="body2" textAlign="center">
            {t(isQCResident ? 'LoanAmount.guaranteedAprLabelQC' : 'LoanAmount.guaranteedAprLabel', {
              apr: percentFormat(apr),
            })}
          </Typography>
        </APRGuarantee>
        <Grid item xs={12} marginTop="32px">
          <PrimaryCTA onClick={submitLoanAmount} buttonText={t('common.continueLabel')} />
        </Grid>
        {!isQCResident && !bootstrapInfo?.application?.accept_right_to_access_consent && (
          <Grid item xs={12} marginTop="9px" textAlign="center">
            <ConsentTypography>
              <Trans i18nKey="common.consent">
                By continuing, you agree to Fig Financial Inc's <TermsAndConditionsLink />
                and <PrivacyPolicyLink />
              </Trans>
            </ConsentTypography>
          </Grid>
        )}
      </Grid>
    </DivRoot>
  )
}
