import { Link } from '@mui/material'
import { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { PaymentDeferralProcessSelectedDateResponseSchema } from 'src/portal/api/api.schemas'
import { PortalModalsEnum } from 'src/portal/utils/common'
import { CheckboxWithLabel } from 'src/portal/components/CheckboxWithLabel'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModalContainer } from 'src/portal/components/modals/ActionModal/ActionModalContainer'
import { NextPaymentsOverviewBox } from 'src/portal/components/modals/DelayNextPayment/NextPaymentsOverviewBox'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { WarningMessage } from 'src/portal/components/WarningMessage'
import { useLocale } from 'src/portal/hooks/useLocale'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { PaymentDeferralContent } from 'src/portal/pages/home/summary/content/TooltipContent'
import PortalTheme from 'src/themes/portal'
import { captureExceptionHelper } from 'src/utils'
import { pdfFileDownload } from 'src/utils/files'
import {
  getAnalyticsScreenCallback,
  getAnalyticsPageView,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

interface ConfirmNewPaymentDateModalContentProps {
  boxChecked: boolean
  setBoxChecked: (checked: boolean) => void
  paymentScheduleData: PaymentDeferralProcessSelectedDateResponseSchema | undefined
  deferralFormData: any
}

const ConfirmNewPaymentDateModalContent = ({
  boxChecked,
  setBoxChecked,
  paymentScheduleData,
  deferralFormData,
}: ConfirmNewPaymentDateModalContentProps) => {
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const { portalApiDownloadDocument } = usePortalApi()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.DELAY_NEXT_PAYMENT)

  const { isFrenchLocale } = useLocale()

  if (!loanDetailData || !paymentScheduleData) {
    return <LoadingAnimation />
  }
  const contractualDueDateText = isFrenchLocale
    ? loanDetailData.payment_schedule_french
    : loanDetailData.payment_schedule

  const monthlyPaymentAmount = loanDetailData.due_amount

  const isOutCycle = paymentScheduleData.is_in_cycle_or_outcycle === 'outCycle'
  const shouldShowCheckboxAndAgreementLink = isOutCycle && deferralFormData.form_url

  const handleLinkClick = async () => {
    const documentId = deferralFormData.form_url.split('/').pop()
    const resp = await portalApiDownloadDocument(loanDetailData?.id || '', documentId)
    pdfFileDownload(resp?.content, `${loanDetailData.id}_payment_deferral_form.pdf`)
    track(
      ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_DELAY_CONFIRM_FILE_DOWNLOADED,
      ANALYTICS_OBJECT_CONSTANTS.FILE,
      ANALYTICS_ACTION_CONSTANTS.DOWNLOADED,
      {
        file: 'payment_deferral_form',
      },
    )
  }

  const repaymentEntries = [
    {
      amount: paymentScheduleData.payment_schedule_amount_one,
      date: paymentScheduleData.payment_schedule_date_one,
    },
    {
      amount: paymentScheduleData.payment_schedule_amount_two,
      date: paymentScheduleData.payment_schedule_date_two,
    },
    {
      amount: paymentScheduleData.payment_schedule_amount_three,
      date: paymentScheduleData.payment_schedule_date_three,
    },
  ]

  return (
    <>
      <WarningMessage
        content={
          <Trans
            i18nKey="Portal.Components.modal.confirmNewPaymentDate.warningMessage"
            components={{
              underline: (
                <TooltipContainer
                  title={t('Portal.Components.tooltip.paymentDeferral.title')}
                  linkElement={
                    <span style={{ textDecoration: 'underline' }}>
                      {t('Portal.Components.modal.confirmNewPaymentDate.paymentDeferral')}
                    </span>
                  }
                  eventScreen={ANALYTICS_SCREEN_CONSTANTS.DELAY_NEXT_PAYMENT}
                  eventName="Payment Deferral Clicked"
                  content={
                    <PaymentDeferralContent
                      contractualDueDate={contractualDueDateText}
                      monthlyPaymentAmount={monthlyPaymentAmount}
                    />
                  }
                />
              ),
            }}
          />
        }
      />
      <NextPaymentsOverviewBox
        repaymentEntries={repaymentEntries}
        finalPaymentDateStr={paymentScheduleData.final_payment_date || ''}
      />

      <InfoMessage content={t('Portal.Components.modal.confirmNewPaymentDate.infoMessage')} />
      {shouldShowCheckboxAndAgreementLink && (
        <CheckboxWithLabel
          content={
            <Trans
              i18nKey="Portal.Components.modal.confirmNewPaymentDate.checkboxLabel"
              components={{
                linkComponent: (
                  <Link
                    onClick={handleLinkClick}
                    sx={{ color: PortalTheme.color.brand1 }}
                    color={PortalTheme.color.brand1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    payment deferral form
                  </Link>
                ),
              }}
            />
          }
          checkBoxHandler={() => setBoxChecked(!boxChecked)}
          checkboxChecked={boxChecked}
        />
      )}
    </>
  )
}
export default function ConfirmNewPaymentDateModal({
  nextStep,
  previousStep,
}: {
  nextStep: VoidFunction
  previousStep: VoidFunction
}) {
  const { t } = useTranslation()
  const { closeAllModals, delayedNextPaymentDate, openModal } = useModalContext()
  const { loanDetailData, portalApiGetLoanDetailRefetch } = usePortalContext()
  const [boxChecked, setBoxChecked] = useState(false)
  const {
    portalApiPaymentDeferralProcessSelectedDate,
    portalApiPaymentDeferralComplete,
    portalApiPaymentDeferralGetDeferralForm,
  } = usePortalApi()
  const { dateFormatLocal, currencyFormat } = useLocalizedFormatters()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.DELAY_NEXT_PAYMENT)
  const location = useLocation()
  const modalName = `${location.pathname}/(payment-deferral-confirm-date-modal)`
  useEffect(() => {
    getAnalyticsPageView(modalName)
  }, [])

  const { isLoading: isDeferralDataLoading, data: paymentScheduleData } = useQuery(
    ['portalApiPaymentDeferralProcessSelectedDate', loanDetailData?.id, delayedNextPaymentDate],
    async () => {
      if (!delayedNextPaymentDate || !loanDetailData?.id) {
        return Promise.reject('Missing required parameters')
      }
      return await portalApiPaymentDeferralProcessSelectedDate(loanDetailData.id, {
        repayment_start_date: delayedNextPaymentDate.toISOString().split('T')[0], // convert to YYYY-MM-DD
      })
    },
    {
      enabled: !!(loanDetailData?.id && delayedNextPaymentDate),
      useErrorBoundary: false,
      onError: error => {
        captureExceptionHelper(`Error delaying next payment: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_DELAY_CONFIRM_ERROR_TRIGGERED,
          ANALYTICS_OBJECT_CONSTANTS.API,
          ANALYTICS_ACTION_CONSTANTS.RESPONSE,
        )
      },
    },
  )

  const { isLoading: isDeferralFormLoading, data: deferralFormData } = useQuery(
    ['portalApiPaymentDeferralGetDeferralForm', loanDetailData?.id],
    async () => {
      if (!loanDetailData?.id) {
        return Promise.reject('Missing required parameters')
      }
      return await portalApiPaymentDeferralGetDeferralForm(loanDetailData.id)
    },
    {
      enabled: !!loanDetailData?.id && paymentScheduleData?.is_in_cycle_or_outcycle === 'outCycle', // a new agreement form is only generated in outcycle cases
      onError: error => {
        captureExceptionHelper(`Error fetching payment deferral form: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_DELAY_CONFIRM_ERROR_TRIGGERED,
          ANALYTICS_OBJECT_CONSTANTS.API,
          ANALYTICS_ACTION_CONSTANTS.RESPONSE,
        )
      },
    },
  )

  const { isLoading: isProcessingDeferral, mutate: completeDeferral } = useMutation(
    ['portalApiPaymentDeferralComplete', loanDetailData?.id, delayedNextPaymentDate],
    async () => {
      if (!delayedNextPaymentDate || !loanDetailData?.id) {
        return Promise.reject('Missing required parameters')
      }
      return portalApiPaymentDeferralComplete(loanDetailData.id, {
        selected_payment_date: delayedNextPaymentDate.toISOString().split('T')[0],
      })
    },
    {
      useErrorBoundary: false,
      onSuccess: _ => {
        nextStep()
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_DELAY_CONFIRM_NEXT_PAYMENT_SUCCESS_BUTTON_CLICKED,
          ANALYTICS_OBJECT_CONSTANTS.BUTTON,
        )
        portalApiGetLoanDetailRefetch()
      },
      onError: error => {
        captureExceptionHelper(`Error delaying next payment: ${error}`, error)
        openModal(PortalModalsEnum.PAYMENT_ERROR_MODAL)
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_DELAY_CONFIRM_ERROR_TRIGGERED,
          ANALYTICS_OBJECT_CONSTANTS.API,
          ANALYTICS_ACTION_CONSTANTS.RESPONSE,
        )
      },
    },
  )

  const isNextButtonEnabled = () => {
    if (!paymentScheduleData) {
      return false
    }
    if (isProcessingDeferral || isDeferralFormLoading) {
      return false
    }

    if (paymentScheduleData?.is_in_cycle_or_outcycle === 'outCycle' && !boxChecked) {
      return false
    }

    return true
  }

  return (
    <ActionModalContainer
      modalName={modalName}
      title={t('Portal.Components.modal.confirmNewPaymentDate.title')}
      description={t('Portal.Components.modal.confirmNewPaymentDate.description', {
        nextPaymentAmount: currencyFormat(paymentScheduleData?.next_payment_amount || 0),
        bankAccountName: paymentScheduleData?.loan_payment_mode,
        newPaymentDate: paymentScheduleData?.next_working_payment_date
          ? dateFormatLocal(paymentScheduleData?.next_working_payment_date)
          : '',
      })}
      content={
        isDeferralDataLoading || isProcessingDeferral || isDeferralFormLoading ? (
          <LoadingAnimation />
        ) : (
          <ConfirmNewPaymentDateModalContent
            boxChecked={boxChecked}
            setBoxChecked={setBoxChecked}
            paymentScheduleData={paymentScheduleData}
            deferralFormData={deferralFormData}
          />
        )
      }
      buttonText={t('Portal.Components.modal.confirmNewPaymentDate.buttonText')}
      closeButtonHandler={() => closeAllModals(modalName)}
      backButtonHandler={previousStep}
      nextButtonHandler={completeDeferral}
      nextButtonEnabled={isNextButtonEnabled()}
    />
  )
}
