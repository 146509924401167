import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FigTextField from 'src/components/common/FigTextField'

interface EmailInputProps {
  value: string
  label: string
  handleChange: (field: string, value: any) => void
}

export const EmailInput = ({ value, handleChange, label }: EmailInputProps) => {
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { t } = useTranslation()
  const emailErrorMessage = t('Portal.ProfileSettings.errorMessage.emailInput')

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    handleChange('email', email)

    if (email && !isValidEmail(email)) {
      setErrorState(true)
      setErrorMessage(emailErrorMessage)
    } else {
      setErrorState(false)
      setErrorMessage('')
    }
  }

  return (
    <FigTextField
      data-testid="email-input"
      value={value}
      autoComplete="email"
      label={label}
      InputProps={{
        'aria-invalid': errorState,
        'aria-describedby': errorState ? 'email-input-error-text' : undefined,
        tabIndex: 0,
      }}
      onChange={handleEmailChange}
      onBlur={() => {
        if (value && !isValidEmail(value)) {
          setErrorState(true)
          setErrorMessage(emailErrorMessage)
        }
      }}
      helperText={errorState ? errorMessage : ''}
      error={errorState}
      errorMessage={errorMessage}
      sx={{
        backgroundColor: 'white',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: errorState ? 'error.main' : 'grey.400',
          },
          '& .MuiInputBase-input': {
            color: errorState ? 'error.main' : 'grey.900',
          },
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: 'error.main',
        },
        '& .MuiFormHelperText-root': {
          color: errorState ? 'error.main' : 'grey.900',
        },
      }}
    />
  )
}
