import { useEffect } from 'react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { DivRoot } from 'src/components/common/DivRoot'
import { connectionSuccessImg } from 'src/images'
import theme from 'src/themes'
import { getAnalyticsPageView } from 'src/portal/utils/analytics'

const ImgStyle = styled('img')({
  width: '80px',
  height: '80px',
})

const GridImage = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '16px',
})

export default function ConnectBankAccountSuccess() {
  const { t } = useTranslation()
  const location = useLocation()
  useEffect(() => {
    getAnalyticsPageView(`${location.pathname}/(payment-method-success-view)`)
  }, [])

  return (
    <DivRoot>
      <GridImage item xs={12}>
        <ImgStyle src={connectionSuccessImg} alt="Checkmark" />
      </GridImage>
      <Grid item xs={12}>
        <Typography variant="h1" textAlign="center" marginBottom="16px">
          {t('ConnectBankAccountSuccess.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Typography variant="body2" color={theme.color.grey6}>
          {t('ConnectBankAccountSuccess.subtitle')}
        </Typography>
      </Grid>
    </DivRoot>
  )
}
