import { Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { LoanDetailsTitle } from 'src/portal/pages/home/summary/content/Titles'
import {
  InterestRateContent,
  LoanIdContent,
  LoanTermContent,
} from 'src/portal/pages/home/summary/content/TooltipContent'
import { ANALYTICS_SCREEN_CONSTANTS } from 'src/portal/utils/analytics'
import PortalTheme from 'src/themes/portal'

interface LoanDetailsContainerProps {
  loanName: string
  loanAmount: number
  interestRate: number
  loanTerm: number
  loanStartDate: string
  loanClosedDate?: string | null
}
export const LoanDetailsContainer = ({
  loanName,
  loanAmount,
  interestRate,
  loanTerm,
  loanStartDate,
  loanClosedDate,
}: LoanDetailsContainerProps) => {
  const { t } = useTranslation()
  const { currencyFormat, percentFormat, dateFormatLocal } = useLocalizedFormatters()

  return (
    <>
      <SpacedRow>
        <LoanDetailsTitle />
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanId')}
          </Typography>
          <TooltipContainer
            title={t('Portal.Components.tooltip.title.loanId')}
            content={<LoanIdContent />}
            eventScreen={ANALYTICS_SCREEN_CONSTANTS.SUMMARY_TAB}
            eventName="Loan ID Clicked"
          />
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {loanName}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanAmount')}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {currencyFormat(loanAmount)}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.interestRate')}
          </Typography>
          <TooltipContainer
            title={t('Portal.Components.tooltip.title.interestRate')}
            content={<InterestRateContent />}
            eventScreen={ANALYTICS_SCREEN_CONSTANTS.SUMMARY_TAB}
            eventName="Interest Rate Clicked"
          />
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {percentFormat(interestRate)}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanTerm')}
          </Typography>
          <TooltipContainer
            title={t('Portal.Components.tooltip.title.loanTerm')}
            content={<LoanTermContent />}
            eventScreen={ANALYTICS_SCREEN_CONSTANTS.SUMMARY_TAB}
            eventName="Loan Term Clicked"
          />
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanTermInMonths', { count: loanTerm })}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {t('Portal.Home.label.summary.loanStartDate')}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey9}>
            {dateFormatLocal(loanStartDate)}
          </Typography>
        </Grid2>
      </SpacedRow>
      {loanClosedDate && (
        <SpacedRow>
          <Grid2>
            <Typography variant="body3" color={PortalTheme.color.grey9}>
              {t('Portal.Home.label.summary.loanClosedDate')}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography variant="body3" color={PortalTheme.color.grey9}>
              {dateFormatLocal(loanClosedDate)}
            </Typography>
          </Grid2>
        </SpacedRow>
      )}
    </>
  )
}
