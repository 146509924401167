import { useState } from 'react'

import { SimpleActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import StartModal from 'src/portal/components/modals/DelayNextPayment/DelayNextPaymentModal'
import ConfirmModal from 'src/portal/components/modals/DelayNextPayment/ConfirmNewPaymentDateModal'
import SuccessModal from 'src/portal/components/modals/DelayNextPayment/DelayNextPaymentSuccessModal'

enum StepEnum {
  CONFIRM = 'CONFIRM',
  SUCCESS = 'SUCCESS',
  EDIT = 'EDIT',
}

function ChangePaymentScheduleContainer() {
  const [step, setStep] = useState<StepEnum>(StepEnum.EDIT)

  if (step === StepEnum.CONFIRM) {
    return (
      <ConfirmModal
        nextStep={() => setStep(StepEnum.SUCCESS)}
        previousStep={() => setStep(StepEnum.EDIT)}
      />
    )
  }
  if (step === StepEnum.SUCCESS) {
    return <SuccessModal />
  }
  return <StartModal nextStep={() => setStep(StepEnum.CONFIRM)} />
}

export default function ManualPaymentModal() {
  return (
    <SimpleActionModal modalName={''}>
      <ChangePaymentScheduleContainer />
    </SimpleActionModal>
  )
}
