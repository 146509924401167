import { FormControl, RadioGroup } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { ManualPaymentDetailResponseSchema } from 'src/portal/api/api.schemas'
import { LoanStatusEnum } from 'src/portal/utils/common'
import { InfoMessage } from 'src/portal/components/InfoMessage'
import { ActionModalContainer } from 'src/portal/components/modals/ActionModal/ActionModalContainer'
import { ExtraAmountInput } from 'src/portal/components/modals/ManualPayments/ExtraAmountInput'
import { PaymentOptionSelect } from 'src/portal/components/modals/ManualPayments/PaymentOptionSelect'
import { PaymentTypeEnum } from 'src/portal/contexts/modal'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { OverdueMessage } from 'src/portal/pages/payments/components/OverdueMessage'
import {
  getAnalyticsScreenCallback,
  getAnalyticsPageView,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

enum PaymentOptionsEnum {
  outstandingAmount = `outstandingAmount`,
  nextPayment = 'nextPayment',
  extraAmount = 'extraAmount',
  remainingBalance = 'remainingBalance',
}

interface ManualPaymentModalContentProps {
  selectedOption: string
  setSelectedOption: (value: string) => void
  setExtraAmount: (value: number) => void
  manualPaymentData: ManualPaymentDetailResponseSchema | undefined
  loanDetailData: any
}

const ManualPaymentModalContent = ({
  selectedOption,
  setSelectedOption,
  setExtraAmount,
  loanDetailData,
  manualPaymentData,
}: ManualPaymentModalContentProps) => {
  const { dateFormatLocal } = useLocalizedFormatters()
  const { t } = useTranslation()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.MANUAL_PAYMENT)

  const nextPaymentDate = manualPaymentData?.next_debit_date || ''
  const nextPaymentAmount = manualPaymentData?.next_payment_amount || 0
  const outstandingAmount = manualPaymentData?.overdue_amount || 0
  const remainingBalanceAmount = manualPaymentData?.full_payment_amount || 0
  const nextPaymentDateText = dateFormatLocal(nextPaymentDate)

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
    track(
      `${ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_MANUAL_PAYMENT_OPTION_SELECTED} ${event.target.value}` as ANALYTICS_SEGMENT_CONSTANTS,
      ANALYTICS_OBJECT_CONSTANTS.OPTION,
      ANALYTICS_ACTION_CONSTANTS.SELECTED,
      {
        option: event.target.value,
      },
    )
  }

  const paymentOptionsList = [
    ...(loanDetailData?.loan_status === LoanStatusEnum.OVERDUE
      ? [
          {
            title: t(
              'Portal.Components.modal.manualPayments.paymentOptions.outstandingAmount.title',
            ),
            description: t(
              'Portal.Components.modal.manualPayments.paymentOptions.outstandingAmount.description',
            ),
            value: PaymentOptionsEnum.outstandingAmount,
            amount: outstandingAmount,
          },
        ]
      : []),
    ...(manualPaymentData?.is_next_pay_applicable === true &&
    loanDetailData?.loan_status !== LoanStatusEnum.OVERDUE
      ? [
          {
            title: t('Portal.Components.modal.manualPayments.paymentOptions.nextPayment.title'),
            description: t(
              'Portal.Components.modal.manualPayments.paymentOptions.nextPayment.description',
            ),
            value: PaymentOptionsEnum.nextPayment,
            amount: nextPaymentAmount,
          },
        ]
      : []),
    {
      title: t('Portal.Components.modal.manualPayments.paymentOptions.extraAmount.title'),
      description: t(
        'Portal.Components.modal.manualPayments.paymentOptions.extraAmount.description',
      ),
      value: PaymentOptionsEnum.extraAmount,
    },
    {
      title: t('Portal.Components.modal.manualPayments.paymentOptions.remainingBalance.title'),
      description: t(
        'Portal.Components.modal.manualPayments.paymentOptions.remainingBalance.description',
      ),
      value: PaymentOptionsEnum.remainingBalance,
      amount: remainingBalanceAmount,
    },
  ]

  return (
    <>
      {loanDetailData.loan_status === LoanStatusEnum.OVERDUE && <OverdueMessage />}

      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="payment-options" value={selectedOption} onChange={handleOptionChange}>
          {paymentOptionsList.map((option, index) => (
            <PaymentOptionSelect
              key={index}
              title={option.title}
              description={option.description}
              value={option.value}
              amount={option.amount}
            />
          ))}
        </RadioGroup>

        {selectedOption === PaymentOptionsEnum.nextPayment && (
          <>
            <InfoMessage
              content={t('Portal.Components.modal.manualPayments.infoMessage.nextPayment', {
                nextPaymentDate: nextPaymentDateText,
              })}
            />
          </>
        )}
        {selectedOption === PaymentOptionsEnum.extraAmount && (
          <>
            <ExtraAmountInput setExtraAmount={setExtraAmount} />
            <InfoMessage
              content={t('Portal.Components.modal.manualPayments.infoMessage.extraAmount')}
            />
          </>
        )}
      </FormControl>
    </>
  )
}

export default function ManualPaymentModal({ nextStep }: { nextStep: VoidFunction }) {
  const { t } = useTranslation()
  const { closeAllModals, setPaymentAmount, setPaymentType } = useModalContext()
  const [extraAmount, setExtraAmount] = useState<number>(0)
  const { loanDetailData } = usePortalContext()
  const { portalApiGetManualPaymentDetail } = usePortalApi()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.MANUAL_PAYMENT)
  const location = useLocation()
  const modalName = `${location.pathname}/(manual-payment-start-modal)`
  useEffect(() => {
    getAnalyticsPageView(modalName)
  }, [])

  const { data: manualPaymentData } = useQuery<ManualPaymentDetailResponseSchema>(
    ['portalApiGetManualPaymentDetail', loanDetailData?.id],
    async () => {
      if (loanDetailData?.id) {
        return portalApiGetManualPaymentDetail(loanDetailData?.id)
      }
      return Promise.reject('No application ID')
    },
    {
      enabled: !!loanDetailData?.id,
      useErrorBoundary: false,
    },
  )

  const [selectedOption, setSelectedOption] = useState<string>('')

  const MINIMUM_PAYMENT_AMOUNT = 0.01 // current SSP minimum payment amount is 0.01

  const isExtraAmountValid =
    extraAmount >= MINIMUM_PAYMENT_AMOUNT &&
    extraAmount <= (manualPaymentData?.full_payment_amount ?? 0)

  let trackAmount: any = 0
  let trackPaymentType: any = ''

  const onSubmit = () => {
    if (selectedOption === PaymentOptionsEnum.outstandingAmount) {
      setPaymentAmount(manualPaymentData?.overdue_amount || 0)
      setPaymentType(PaymentTypeEnum.overduePayment)
      trackAmount = manualPaymentData?.overdue_amount || 0
      trackPaymentType = PaymentTypeEnum.overduePayment
    }
    if (selectedOption === PaymentOptionsEnum.nextPayment) {
      setPaymentAmount(manualPaymentData?.next_payment_amount || 0)
      setPaymentType(PaymentTypeEnum.nextPayment)
      trackAmount = manualPaymentData?.next_payment_amount || 0
      trackPaymentType = PaymentTypeEnum.nextPayment
    }

    if (selectedOption === PaymentOptionsEnum.remainingBalance) {
      setPaymentAmount(manualPaymentData?.full_payment_amount || 0)
      setPaymentType(PaymentTypeEnum.remainingBalance)
      trackAmount = manualPaymentData?.full_payment_amount || 0
      trackPaymentType = PaymentTypeEnum.remainingBalance
    }

    if (selectedOption === PaymentOptionsEnum.extraAmount) {
      setPaymentAmount(extraAmount)
      setPaymentType(PaymentTypeEnum.extraPayment)
      trackAmount = extraAmount
      trackPaymentType = PaymentTypeEnum.extraPayment
    }

    track(
      ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_MANUAL_PAYMENT_NEXT_BUTTON_CLICKED,
      ANALYTICS_OBJECT_CONSTANTS.BUTTON,
      ANALYTICS_ACTION_CONSTANTS.CLICKED,
      {
        amount: trackAmount,
        paymentType: trackPaymentType,
      },
    )
    nextStep()
  }

  const manualPaymentModalContentProps = {
    selectedOption,
    setSelectedOption,
    setExtraAmount,
    loanDetailData,
    manualPaymentData,
  }

  const isNextButtonDisabled =
    !selectedOption || (selectedOption === PaymentOptionsEnum.extraAmount && !isExtraAmountValid)

  return (
    <ActionModalContainer
      modalName={modalName}
      title={t('Portal.Components.modal.manualPayments.title')}
      description={t('Portal.Components.modal.manualPayments.description')}
      content={
        Object.keys(manualPaymentData || {}).length === 0 || !loanDetailData?.id ? (
          <>
            <LoadingAnimation />
          </>
        ) : (
          <ManualPaymentModalContent {...manualPaymentModalContentProps} />
        )
      }
      buttonText={t('Portal.Components.modal.manualPayments.buttonText')}
      closeButtonHandler={() => closeAllModals(modalName)}
      nextButtonHandler={onSubmit}
      nextButtonEnabled={!isNextButtonDisabled}
    />
  )
}
