import enPortalTranslation from 'src/locales/en/portalTranslation'
const portalTranslation: typeof enPortalTranslation = {
  Portal: {
    title: 'Fig | Portail client',
    common: {
      dateFormat: 'EEEE dd MMMM yyyy',
    },
    Header: {
      alt: {
        figLogo: 'Logo Fig',
        userIcon: 'Icône utilisateur',
        languageDropdown: 'Basculer le menu déroulant',
      },
      label: {
        languageDropdown: 'Language',
        profileSettings: 'Paramètres du profil',
        logout: 'Se déconnecter',
      },
    },
    Menu: {
      links: {
        referral: `https://fig.ca/fr/referral/`,
        terms: `https://fig.ca/fr/legal/terms-of-use/`,
        privacy: `https://fig.ca/fr/legal/privacy-statement/`,
        helpCenter: `https://support.fig.ca/fr/support/home`,
      },
      alt: {
        homeIcon: 'Icône d’accueil',
        paymentsIcon: 'Icône de paiements',
        giftIcon: 'Icône de cadeau',
        documentsIcon: 'Icône de documents',
        moreIcon: 'Icône de plus',
        paymentProtectionIcon: 'Icône de protection des paiements',
        loanIncreaseIcon: 'Icône d’augmentation de prêt',
        helpCenterIcon: 'Icône du centre d’aide',
        termsIcon: 'Icône des conditions d’utilisation',
        notificationIcon: 'Icône de notification',
        dividerIcon: 'Séparateur de menu',
      },
      label: {
        home: 'Accueil',
        payments: 'Paiements',
        documents: 'Documents',
        managePayments: 'Gérer les paiements',
        viewDocuments: 'Voir les documents',
        more: 'Plus',
        paymentProtection: 'Protection des paiements',
        loanIncrease: 'Demander une augmentation du montant du prêt',
        helpCenter: `Centre d'aide`,
        terms: `Conditions d'utilisation`,
      },
    },
    Intermittent: {
      title: 'Bienvenue sur votre Compte Fig',
      sectionTitle: `Compte Fig`,
      sectionContentV1: `Si vous avez réservé votre prêt avant le {{dateSection}}, connectez-vous ici`,
      sectionContentV2: `Connectez-vous ici si vous avez contracté votre prêt le {{dateSection}} ou après.`,
      newText: 'Nouveau',
      login: 'Ouvrir une session',
    },
    Home: {
      label: {
        tab: {
          summary: 'Sommaire',
          transactions: 'Transactions',
          upcomingPayments: 'Paiements à venir',
        },
        summary: {
          title: 'Sommaire',
          loanStatus: {
            good: 'En règle',
            overdue: 'Paiement en retard',
            closed: 'Clôturé',
          },
          loanClosedMessage: `Votre prêt a été remboursé.`,
          remainingBalance: 'Solde restant',
          upcomingPaymentAmount: 'Montant du paiement à venir',
          upcomingPaymentDate: 'Date du paiement à venir',
          automaticPaymentsOn: 'Paiements automatiques : Activé',
          automaticPaymentsOff: 'Paiements automatiques : Désactivé',
          paymentProtectionActive: 'Protection des paiements : Active',
          loanDetails: 'Voir les détails',
          loanId: 'Identifiant du prêt',
          loanAmount: 'Montant du prêt',
          interestRate: `Taux d'intérêt`,
          loanTerm: `Durée du prêt`,
          loanTermInMonths: 'Durée du prêt',
          loanStartDate: 'Date de début du prêt',
          outstandingAmount: 'Montant impayé',
          outstandingAmountDueDate: `Date d'échéance`,
          payNow: 'Payer maintenant',
          payOutstandingAmount: 'Payer le montant impayé',
          loanClosedDate: `Date de clôture du prêt`,
        },
        transactions: {
          title: `Transactions`,
          description:
            'Ci-dessous, vous pouvez voir tous les paiements que vous avez effectués, y compris les paiements programmés et les paiements uniques, ainsi que les frais supplémentaires sur votre compte.',
          status: {
            processing: 'En cours de traitement',
            failed: 'Échoué',
          },
          message: {
            processing: `Veuillez vous assurer qu'il y a suffisamment de fonds sur votre compte jusqu'à ce que le paiement soit traité dans 2 jours ouvrables`,
          },
        },
        upcomingPayments: {
          title: `Paiements à venir`,
          description: `Si vous avez effectué un paiement forfaitaire, le montant de votre paiement mensuel ne sera pas réduit, mais la durée de votre prêt sera raccourcie.`,
          remainingBalance: `Solde restant`,
        },
        greeting: {
          morning: 'Bonjour',
          afternoon: 'Bon après-midi',
          evening: 'Bonsoir',
        },
      },
    },
    Payments: {
      label: {
        title: `Gérer vos paiements`,
        description: `Choisissez une option ci-dessous`,
        options: {
          manualPayment: {
            title: `Effectuer un paiement manuel`,
            description:
              'Vous souhaitez rembourser votre prêt plus rapidement ? Vous pouvez effectuer un paiement supplémentaire ou rembourser votre prêt sans pénalités.',
          },
          overduePaymentDescription: `Choisissez de payer le montant en souffrance, de payer un montant différent ou de rembourser votre prêt sans pénalités.`,
          manualPaymentUserDescription: `Choisissez de payer le prochain paiement, d'effectuer un paiement supplémentaire ou de rembourser votre prêt sans pénalités.`,
          delayNextPayment: {
            title: `Retarder le prochain paiement`,
            description: `Vous avez besoin de plus de temps pour effectuer votre prochain paiement ? Vous pouvez le retarder d'un mois maximum, une fois tous les 12 mois.`,
          },
          changePaymentSchedule: {
            title: `Modification de l'échéancier de paiement`,
            description: `Sélectionnez la fréquence et la date de paiement qui vous conviennent le mieux.`,
          },
          changePaymentMethod: {
            title: `Modifier le mode de paiement`,
            description: `Mise à jour du compte bancaire utilisé pour les paiements futurs`,
          },
        },
        overdueMessage: `Votre paiement est en retard. Veuillez payer le montant dû dès que possible afin d'éviter des intérêts supplémentaires et un impact négatif sur votre cote de crédit.`,
      },
    },
    Documents: {
      title: 'Documents',
      subtitle: 'Ci-dessous, vous pouvez voir tous vos documents pour {{loanName}}',
      download: 'Télécharger',
      loanAgreement: 'Contrat de prêt',
      padAgreement: 'Entente de prélèvement automatique',
    },
    Reschedule: {
      title: `Modification de l'échéancier de paiement`,
      subtitle: '',
      change: {
        sectionTitle: `Votre changement prendra effet à partir de votre prochain cycle de paiement, à compter du {{currentDate}}`,
        headerTitle: `Date de début du changement de l’échéancier`,
        buttonText: 'Confirmer et continuer',
        sectionContent: `Date de début du prochain cycle de facturation`,
      },
      delay: {
        sectionTitle: `Nous avons remarqué que vous avez programmé des paiements dans le cycle de facturation actuel :`,
        headerTitle: 'Paiements prévus',
        buttonText: 'Retarder le prochain paiement',
        sectionContent: 'Date de fin du cycle de facturation : ',
        footerText:
          'Si vous devez reporter le(s) paiement(s) de votre cycle de facturation actuel, veuillez cliquer sur « Retarder le prochain paiement ».',
      },
      EditPaymentSchedule: {
        title: `Modification de l'échéancier de paiement`,
        frequencyTitle: `Votre changement interviendra à partir de votre prochain cycle de paiement, à compter du {{startDate}}`,
        frequencyQuestion: `À quelle fréquence souhaitez-vous effectuer des paiements ?`,
        buttonNext: 'Prochain',
      },
      ConfirmSchedule: {
        title: 'Confirmer le nouvel échéancier de paiement',
        frequencyTitle: `Nous retirerons {{withdrawAmount}} toutes les {{duration}} à compter du {{startDate}} du compte {{paymentMethod}}.`,
        currentPayment: 'Paiements à venir dans le cycle de paiement actuel',
        upcomingPayment: 'Paiements à venir à partir du prochain cycle de paiement',
        buttonConfirm: 'Confirmer',
        notification:
          'Si la date de votre paiement tombe une fin de semaine ou un jour férié, il sera traité le jour ouvrable suivant.',
        padAgreement: {
          text: `J'accepte la mise à jour de la <2>{{loanAgreementLabel}}</2>`,
          link: `convention de DPA.`,
        },
        ScheduleFrequency: {
          weekly: 'chaque semaine',
          biweekly: ' toutes les deux semaines',
          twiceAMonth: ' deux fois par mois',
          monthly: ' une fois par mois',
        },
        twiceAMonthText: `Deux fois par mois (1er et 16)`,
      },
      Success: {
        title: `Mise à jour de l'échéancier de paiement réussie!`,
        buttonConfirm: 'Terminé',
      },
    },
    CreditRenewal: {
      title: 'Demande d’augmentation du montant du prêt',
      subtitle: 'Application sécurisée',
      eligibility: {
        title: 'Admissibilité',
        content: 'Découvrez si vous êtes admissible au renouvellement de crédit.',
      },
      howItWorks: {
        title: 'Comment ça fonctionne',
        content:
          'Apprenez-en davantage sur le processus de renouvellement de crédit et ses avantages.',
      },
      button: 'Renouveler le crédit',
    },
    PaymentProtection: {
      title: `Plan de protection des paiements`,
      activeBadge: `Actif`,
      download: `Télécharger`,
      certificateOfInsurance: `Certificat d’assurance`,
      documentName: `Certificat d’assurance`,
    },
    ProfileSettings: {
      title: `Mettre à jour les informations de profil`,
      description: `Pour mettre à jour ou modifier votre nom complet, veuillez nous contacter via le chat en direct Fig en bas à droite de cette page.`,
      firstName: `Prénom`,
      lastName: `Nom`,
      emailAddress: `Adresse Courriel`,
      phoneNumber: `Numéro de téléphone`,
      address: `Addresse`,
      unit: `Appart. (optionel)`,
      errorMessage: {
        nameInput: `Veuillez nous contacter pour mettre à jour cette information.`,
        emailInput: `Veuillez entrer une adresse courriel valide`,
        phoneInput: `Veuillez entrer un numéro de téléphone valide`,
        addressInput: `Veuillez entrer une adresse valide`,
      },
      submitButtonText: `Update profile info`,
      updateSuccess: `Mise à jour des infos réussie`,
      updateCheckEmailTitle: `Vérifiez votre boîte courriel`,
      updateCheckEmailContent: `Suivez les instructions dans le courriel pour confirmer votre adresse courriel. Votre lien électronique expirera dans les 72 heures.`,
      updateFailedTitle: `Oups, quelque chose n'a pas fonctionné`,
      updateFailedContent: `Veuillez réessayer plus tard.`,
      updateFailedEmailTakenContent: `Please try again with a different email address`,
      updateFailedPhoneNumberTakenContent: `Please try again with a different phone number`,
    },
    EmailChanged: {
      title: `Adresse courriel modifiée`,
      description: `L'adresse courriel de votre compte Fig a été modifiée avec succès.`,
      buttonText: `Ouvrir une session`,
    },
    Components: {
      alt: {
        infoCircleIcon: 'Icône d’information',
        questionCircleIcon: 'Icône de question',
        statusIcon: 'Icône de statut',
        chevronIcon: 'Icône de chevron',
        checkmarkIcon: 'Icône de coche',
        errorIcon: 'Icône d’erreur',
      },
      label: {
        viewDetails: 'Voir les détails',
        manage: 'Gérer',
        learnMore: 'En savoir plus',
        showDetails: 'Afficher les détails',
        hideDetails: 'Masquer les détails',
      },
      tooltip: {
        title: {
          accountStatus: 'Statut du compte',
          remainingBalance: 'Solde restant',
          loanId: 'Identifiant du prêt',
          loanTerm: 'Durée du prêt',
          paymentDetails: 'Détail du paiement',
          upcomingPaymentAmount: `Montant du paiement à venir`,
          upcomingPaymentDate: 'Date du paiement à venir',
          interestRate: `Taux d'intérêt`,
        },
        content: {
          accountStatus: `<typography>Votre statut de compte sera l'un des suivants :<br/><br/><strong>En règle</strong> - Tous les paiements jusqu'à votre dernière date d'échéance ont été reçus.<br/><br/><strong>Paiement en retard</strong> - Un ou plusieurs paiements attendus ont été manqués.<br/><br/><strong>Clôturé</strong> - Votre prêt a été remboursé.<br/><br/>Si vous voyez le message "Votre paiement est en retard", veuillez payer votre montant impayé dès que possible ou contacter notre équipe de support pour planifier un paiement afin de remettre votre compte en règle.</typography>`,
          remainingBalance: `<typography>Le solde de votre prêt augmentera au fur et à mesure que les intérêts quotidiens seront calculés, et diminuera à mesure que les paiements seront appliqués. Si vous souhaitez rembourser votre prêt, voici le montant dû à ce jour.</typography>`,
          loanId: `<typography>Utilisez ce numéro comme votre numéro de compte lorsque vous essayez d'effectuer un paiement de facture via votre banque.</typography>`,
          loanTerm: `<typography>Si vous effectuez un paiement forfaitaire ou avez un calendrier de remboursement plus fréquent qu'une fois par mois, votre durée réelle de remboursement peut être plus courte que prévu à l'origine. Cependant, si vous manquez ou retardez des paiements, cela peut prolonger votre durée de remboursement au-delà de la date de fin initialement prévue.</typography>`,
          upcomingPaymentAmount: `<typography>Il s'agit de votre montant de paiement régulier et récurrent, basé sur la fréquence de vos paiements et les détails de votre prêt.
<br/><br/>Ce montant inclura également les frais résultant des paiements manqués.</typography>`,
          upcomingPaymentDate: `<typography>Pour retarder votre prochain paiement, veuillez vous rendre sur la page des Paiements et sélectionner l'option Retarder le prochain paiement.</typography>`,
          interestRate: `<typography>Les intérêts sur votre prêt Fig sont calculés quotidiennement. Cela signifie que chaque jour, une fraction de votre taux d'intérêt annuel est ajoutée au solde restant de votre prêt existant.</typography>`,
        },
        label: {
          principalRemaining: 'Solde en capital',
          interestAccrued: 'Intérêts accumulés depuis le dernier paiement',
          feesAccrued: 'Frais accumulés depuis le dernier paiement',
          total: 'Total',
          principal: 'Capital',
          interest: `Intérêt`,
          creditCharges: 'Frais de crédit',
          paymentProtectionPremium: 'Prime de protection de paiement',
          paymentProtectionPremiumTax: 'Taxe sur la prime de protection de paiement',
          nsfFees: 'Frais NSF',
          totalAmount: 'Total',
        },
        paymentDeferral: {
          title: `Report de paiement`,
          content: `<typography>Lorsque vous souscrivez à votre prêt, nous fixons une date de fin de cycle de facturation qui correspond à la date à laquelle vous devez vous acquitter de votre obligation de paiement mensuel.<br/><br/>Si vous n'êtes pas en mesure de respecter votre obligation de paiement mensuel à la date de fin du cycle de facturation, vous pouvez choisir de reporter votre prochain paiement. Vous pouvez reporter votre paiement une fois tous les 12 mois.<br/><br/>Dans ce cas, le statut de votre prêt et votre dossier de crédit resteront en règle. Toutefoie, veuillez noter que la date d'échéance de votre prêt sera reportée et que le montant total des intérêts que vous paierez pendant la durée du prêt augmentera</typography>`,
          label: {
            monthlyPaymentDetails: `Détails de votre paiement mensuel`,
            contractualDueDate: `Date d'échéance contractuelle`,
            monthlyPaymentAmount: `Montant du paiement mensuel`,
          },
        },
      },
      modal: {
        automaticPayments: {
          on: {
            description: 'Votre prochain paiement automatique est prévu pour {{date}}',
            status: 'Activé',
            linkText: null,
          },
          off: {
            description: `Vous ne voulez pas manquer votre paiement ? Activez les paiements automatiques en contactant notre <linkComponent>équipe d'assistance !</linkComponent>`,

            status: 'Inactive',
            linkText: 'Activer',
          },
          title: {
            paymentAccount: 'Compte de paiement',
            paymentSchedule: 'Calendrier de paiement',
            paymentScheduleEndingThisCycle: `Échéancier de paiement se terminant à la fin de ce cycle`,
            paymentScheduleStartingNextCycle: `Calendrier de paiement à partir du prochain cycle`,
          },
          linkText: {
            delay: 'Reporter',
            change: 'Modifier',
          },
          paymentModes: {
            automaticPayment: 'Paiement automatique',
            billPayment: 'Paiement de facture',
            manualPayment: 'Paiement manuel',
            thirdPartyPayment: 'Paiement par un tiers',
            disbursal: 'Décaissement',
            refund: 'Remboursement',
            interestWaiver: "Dispense d'intérêts",
            feeWaiver: 'Dispense de frais',
            loanCancellation: 'Annulation de prêt',
            unknown: 'Inconnu',
          },
        },
        manualPayments: {
          title: `Effectuer un paiement manuel`,
          description: `Choisir une option de paiement`,
          buttonText: `Prochain`,
          paymentOptions: {
            outstandingAmount: {
              title: `Payer le montant impayé`,
              description: `Effectuez un paiement pour éviter des intérêts et frais supplémentaires`,
            },
            nextPayment: {
              title: `Effectuer mon prochain paiement`,
              description: `Payez votre prochain paiement aujourd'hui`,
            },
            extraAmount: {
              title: `Payez un montant supplémentaire`,
              description: `Remboursez votre prêt plus rapidement en effectuant des paiements supplémentaires`,
            },
            remainingBalance: {
              title: `Payez le solde de mon prêt`,
              description: `Remboursez votre prêt sans pénalités`,
            },
          },
          infoMessage: {
            extraAmount: `Veuillez noter que si vous avez opté pour des paiements automatiques, votre prochain paiement sera toujours traité comme prévu.`,
            nextPayment: `Votre prochain paiement prévu le {{nextPaymentDate}} sera traité aujourd'hui.`,
          },
        },
        confirmPayment: {
          title: `Confirmer le paiement`,
          buttonText: `Effectuer un paiement`,
          label: {
            paymentAmount: `Montant du paiement`,
            viewPaymentBreakdown: `Voir la répartition du paiement`,
            paymentDate: `Date de paiement`,
            transactionTime: `Cette transaction peut prendre jusqu’à 2 jours ouvrables avant d'apparaître dans votre compte bancaire.`,
            paymentAccount: `Compte de paiement`,
            consentCheckbox: `Je comprends que mon compte sera débité du montant choisi dans un délai d'un jour ouvrable.`,
            infoMessage: `Effectuer des paiements additionnels ne changera pas le montant de vos paiements réguliers. Ces paiements additionnels vous aideront plutôt à rembourser votre prêt plus rapidement et réduiront le montant total d’intérêts que vous paierez au fil du temps.`,
            principal: `Capital`,
            interest: `Intérêt`,
            creditCharges: `Frais de crédit`,
            paymentProtection: `Protection des paiements`,
            fees: `Frais`,
            totalAmount: `Montant total`,
          },
        },
        confirmNewPaymentMethod: {
          title: `Confirmer le nouveau mode de paiement`,
          description: `Êtes-vous sûr de vouloir changer votre compte bancaire lié en {{bankAccountName}} ?`,
          buttonText: `Confirmer`,
          label: {
            consentCheckbox: `J'accepte le <linkComponent>contrat de prélèvement automatique</linkComponent> mis à jour.`,
          },
        },
        accountAlreadyLinked: {
          title: `Votre compte bancaire est déjà lié.`,
          description: `Il semble que ce compte bancaire soit déjà lié à votre profil.`,
        },
        paymentSuccess: {
          title: `Paiement Réussi !`,
          description: `Merci. Votre paiement de {{amount}} a été effectué.`,
          infoMessage: `Le traitement du paiement peut prendre jusqu'à 2 jours ouvrables. Veuillez vous assurez que votre compte soit suffisamment approvisionné pendant cette période.`,
          buttonText: `Terminé`,
        },
        paymentDeferralSuccess: {
          title: `Votre prochain paiement a été mis à jour avec succès !`,
          buttonText: `Terminé`,
        },
        errorModal: {
          title: `Oups, quelque chose s'est mal passé`,
          description: `Nous travaillons à résoudre ce problème dès que possible. Cliquez sur le bouton « Réessayer » ou contactez-nous via le <liveChatLink>chat en direct Fig</liveChatLink> ou par <1>email au support Fig.</1>`,
          buttonText: `Réessayer`,
        },
        delayNextPayment: {
          title: `Modifier la date du prochain paiement`,
          description: `Votre prochain paiement est actuellement prévu pour le {{nextPaymentDate}}`,
          infoMessage: `Le choix d'une date après le {{nextPaymentDate}} entraînera <underline>un report de paiement</underline>, limité à une fois tous les 12 mois.`,
          paymentDeferral: `Report de paiement`,
          datePicker: {
            label: `Quand souhaitez-vous commencer le nouvel échéancier de paiement ?`,
          },
          buttonText: `Prochain`,
        },
        confirmNewPaymentDate: {
          title: `Confirmer la nouvelle date de paiement`,
          description: `Votre prochain paiement de {{nextPaymentAmount}} sera traité le {{newPaymentDate}} à partir de {{bankAccountName}}.`,
          warningMessage: `La date choisie entraîne un report de paiement, limité à une fois tous les 12 mois.`,
          infoMessage: `Si la date de votre paiement tombe une fin de semaine ou un jour férié, il sera traité le jour ouvrable suivant.`,
          checkboxLabel: `J'accepte le <linkComponent>formulaire de report de paiement</linkComponent> mis à jour.`,
          paymentDeferral: `Report de paiement`,
          paymentsOverview: {
            title: `Aperçu des 3 prochains paiements`,
            finalPaymentLabel: `Paiement final`,
          },
          buttonText: `Prochain`,
        },
      },
    },
  },
}

export default portalTranslation
