import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { Grid2, IconButton, Typography } from '@mui/material'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { ActionModalProps } from 'src/portal/components/types'
import PortalTheme from 'src/themes/portal'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

export const ActionModalContainer = ({
  modalName,
  icon,
  iconAlt,
  title,
  description,
  content,
  buttonText = '',
  backButtonHandler,
  closeButtonHandler,
  nextButtonHandler,
  nextButtonEnabled = true,
}: ActionModalProps) => {
  const track = getAnalyticsScreenCallback(modalName as ANALYTICS_SCREEN_CONSTANTS)

  return (
    <Grid2
      data-testid="action-modal-container"
      container
      direction="column"
      sx={{
        height: '100vh', // Full viewport height
        overflow: 'hidden', // Prevent outer scroll
      }}
    >
      {/* Header and Close Button */}
      <Grid2 container alignItems="center" justifyContent="space-between" p="24px" pb="16px">
        <Grid2>
          {backButtonHandler && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                track(
                  `${modalName} / Back Button Clicked` as ANALYTICS_SEGMENT_CONSTANTS,
                  ANALYTICS_OBJECT_CONSTANTS.BUTTON,
                  ANALYTICS_ACTION_CONSTANTS.CLICKED,
                )
                backButtonHandler?.()
              }}
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
        </Grid2>
        <Grid2>
          <IconButton
            edge="end"
            color="inherit"
            onClick={closeButtonHandler}
            aria-label="close"
            data-testid="action-modal-close-button"
          >
            <CloseIcon />
          </IconButton>
        </Grid2>
      </Grid2>

      {/* Scrollable Content */}
      <Grid2
        sx={{
          flex: 1,
          overflowY: 'auto',
          px: '24px',
        }}
      >
        <Grid2 size={12} textAlign="center" pb={icon ? '48px' : '24px'}>
          {icon && <img src={icon} alt={iconAlt} width="80px" height="80px" />}

          {title && (
            <Typography variant="h2" fontWeight={500} color={PortalTheme.color.grey10}>
              {title}
            </Typography>
          )}

          {description && (
            <Typography variant="body2" color={PortalTheme.color.grey10}>
              {description}
            </Typography>
          )}
        </Grid2>

        <Grid2 sx={{ flexGrow: 1 }}>{content}</Grid2>
      </Grid2>

      {/* Sticky CTA */}
      <Grid2
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          padding: '16px',
          borderTop: `1px solid ${PortalTheme.color.grey2}`,
          zIndex: 10,
        }}
      >
        {nextButtonHandler && (
          <PrimaryCTA
            data-testid="action-modal-next-button"
            onClick={nextButtonHandler}
            fullWidth
            buttonText={buttonText}
            disabled={!nextButtonEnabled}
          />
        )}
      </Grid2>
    </Grid2>
  )
}
