import { LoanQueryResponseSchema } from 'src/portal/api/api.schemas'
import { LoanStatusEnum, PortalModalsEnum } from 'src/portal/utils/common'
import useModalContext from 'src/portal/hooks/useModalContext'
import { PayNowButton } from 'src/portal/pages/home/components/PayNowButton'
import { AutomaticPaymentsContainer } from 'src/portal/pages/home/summary/containers/AutomaticPaymentsContainer'
import { PaymentProtectionContainer } from 'src/portal/pages/home/summary/containers/PaymentProtectionContainer'
import { UpcomingPaymentAmountContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentAmountContainer'
import { UpcomingPaymentDateContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentDateContainer'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

export const PaymentDetailsContainer = ({
  loanDetailData,
}: {
  loanDetailData: LoanQueryResponseSchema
}) => {
  const { openModal } = useModalContext()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.SUMMARY_TAB)

  const upcomingPaymentAmount = loanDetailData.due_amount
  const upcomingPaymentDate = loanDetailData.due_date
  const paymentProtectionActive = loanDetailData.payment_protection_applied!
  const automaticPaymentsOn = !loanDetailData.manual_pay
  const shouldShowPayNowButton =
    !automaticPaymentsOn && loanDetailData.loan_status === LoanStatusEnum.GOOD // note: pay now button will be shown in the OutstandingPaymentContainer if loan is overdue

  return (
    <>
      {!!upcomingPaymentAmount && (
        <UpcomingPaymentAmountContainer upcomingPaymentAmount={upcomingPaymentAmount} />
      )}
      {!!upcomingPaymentDate && (
        <UpcomingPaymentDateContainer upcomingPaymentDate={upcomingPaymentDate} />
      )}

      <AutomaticPaymentsContainer
        automaticPaymentsOn={automaticPaymentsOn}
        viewDetailsClicked={() => {
          openModal(PortalModalsEnum.AUTOMATIC_PAYMENTS_DETAIL)
          track(
            ANALYTICS_SEGMENT_CONSTANTS.SUMMARY_TAB_VIEW_AUTOMATED_PAYMENT_CLICKED,
            ANALYTICS_OBJECT_CONSTANTS.BUTTON,
            ANALYTICS_ACTION_CONSTANTS.CLICKED,
          )
        }}
      />
      <PaymentProtectionContainer paymentProtectionActive={paymentProtectionActive} />

      {shouldShowPayNowButton && (
        <PayNowButton
          onClickHandler={() => {
            openModal(PortalModalsEnum.MANUAL_PAYMENT_MODAL)
            track(
              ANALYTICS_SEGMENT_CONSTANTS.SUMMARY_TAB_PAY_NOW_BUTTON_CLICKED,
              ANALYTICS_OBJECT_CONSTANTS.BUTTON,
              ANALYTICS_ACTION_CONSTANTS.CLICKED,
            )
          }}
        />
      )}
    </>
  )
}
