import InfoIcon from '@mui/icons-material/Info'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AlertColor, FigAlert } from 'src/components/common/FigAlert'
import theme from 'src/themes'

export const AlertCheckEmail = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <FigAlert
      icon={<InfoIcon color="warning" />}
      title={t('Portal.ProfileSettings.updateCheckEmailTitle')}
      content={t('Portal.ProfileSettings.updateCheckEmailContent')}
      color={AlertColor.WARNING}
      fade={{ lifespanMs: 3000 }}
      alertSx={{ top: isMobile ? '60vh' : '72vh', width: isMobile ? '87%' : '75%' }}
    />
  )
}
