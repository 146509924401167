import { Box, Typography } from '@mui/material'

import { MenuButtonItem } from 'src/portal/layout/menu/common'
import PortalTheme from 'src/themes/portal'

export const MobileMenuItem = ({ label, icon, iconAlt, onClickHandler }: MenuButtonItem) => {
  return (
    <Box
      key={label}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        py: '24px',
      }}
      onClick={() => onClickHandler?.()}
    >
      <img src={icon} alt={iconAlt} style={{ verticalAlign: 'middle', paddingRight: '8px' }} />
      <Typography variant="body2" color={PortalTheme.color.grey10}>
        {label}
      </Typography>
    </Box>
  )
}
