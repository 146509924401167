import { Box, Grid2, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { DesktopMenuItemProps, handleNavigation } from 'src/portal/layout/menu/common'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'
import PortalTheme from 'src/themes/portal'
import { onKeySelect } from 'src/utils/keyboardHandlers'

export const DesktopMenuItem: React.FC<DesktopMenuItemProps> = ({ item, index, isActive }) => {
  const navigate = useNavigate()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.NAVIGATION)

  return (
    <Grid2 key={index} role="listitem">
      <Box
        onClick={() => {
          handleNavigation(item, navigate)
          track(
            item.event || ANALYTICS_SEGMENT_CONSTANTS.NAV_ITEM_CLICKED,
            ANALYTICS_OBJECT_CONSTANTS.MENU,
            ANALYTICS_ACTION_CONSTANTS.CLICKED,
            {
              item: item.label,
            },
          )
        }}
        onKeyDown={onKeySelect(() => {
          handleNavigation(item, navigate)
          track(
            item.event || ANALYTICS_SEGMENT_CONSTANTS.NAV_ITEM_CLICKED,
            ANALYTICS_OBJECT_CONSTANTS.MENU,
            ANALYTICS_ACTION_CONSTANTS.CLICKED,
            {
              item: item.label,
            },
          )
        })}
        tabIndex={0}
        role="button"
        aria-current={isActive ? 'page' : undefined}
        aria-label={item.label}
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          py: 1.5,
          backgroundColor: isActive ? PortalTheme.color.brand5 : 'transparent',
          borderRadius: '4px',
          cursor: 'pointer',
          overflow: 'hidden',
          '&:hover': {
            backgroundColor: PortalTheme.color.brand5,
          },
        }}
      >
        <img
          src={isActive ? item.iconActive : item.icon}
          alt={`${item.label} icon`}
          style={{ width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle' }}
        />
        <Typography
          variant="body2"
          sx={{
            fontWeight: '400',
            color: isActive ? PortalTheme.color.brand1 : PortalTheme.color.grey10,
            maxHeight: '3rem', // Limit to approximately 2 lines to be wrapped
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2, // Allows only up to 2 lines to be wrapped
            lineHeight: 1.5,
          }}
        >
          {item.label}
        </Typography>
      </Box>
    </Grid2>
  )
}
