import { Box } from '@mui/material'

import PortalTheme from 'src/themes/portal'

/**
 * StretchDivider component renders a centered image inside a Box component.
 *  Similar to the MenuDivider component but stretched to 91% of the parent width.
 *
 * @returns {JSX.Element} The rendered StretchDivider component.
 */
export const StretchDivider = () => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        borderBottom: `1px solid ${PortalTheme.color.grey4}`,
        marginTop: '16px',
        marginBottom: '16px',
      }}
    />
  )
}
