import { useAuth } from '@clerk/clerk-react'
import Axios from 'axios'

import { getNinjaAPI } from 'src/portal/api/api'
import {
  ChangePaymentMethodGetPadAgreementRequestSchema,
  ChangePaymentMethodLinkAccountRequestSchema,
  FlinksPaymentCheckRequestSchema,
  FlinksSetAccountRequestSchema,
  FlinksTrackEventRequestSchema,
  PaymentBreakdownRequestSchema,
  PaymentDeferralCompleteRequestSchema,
  PaymentDeferralProcessSelectedDateRequestSchema,
  PaymentScheduleChangeCompleteRequestSchema,
  PaymentScheduleChangeProcessSelectedDateRequestSchema,
  PaymentScheduleValidationRequestSchema,
  ProfileSettingsUpdateRequestSchema,
} from 'src/portal/api/api.schemas'
import { API_URL } from 'src/utils/constants'

// Setting axios defaults to send cookies
Axios.defaults.withCredentials = true
Axios.defaults.baseURL = API_URL

const parseHeaders = (token: string | null) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
}

export default function usePortalApi() {
  const { getToken } = useAuth()
  const api = getNinjaAPI()

  return {
    portalApiGetBorrowerDetail: async () => {
      const token = await getToken()
      const { data } = await api.portalApiGetBorrowerDetail(parseHeaders(token))
      return data
    },
    portalApiGetLoanDetail: async () => {
      const token = await getToken()
      const { data } = await api.portalApiGetLoanDetail(parseHeaders(token))
      return data
    },
    portalApiGetLoanRepaymentSchedule: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiGetLoanRepaymentSchedule(loan_id, parseHeaders(token))
      return data
    },
    portalApiGetLoanTransactionHistory: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiGetLoanTransactionHistory(loan_id, parseHeaders(token))
      return data
    },
    portalApiGetDocumentHistory: async (loan_id: string, application_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiGetDocumentHistory(
        loan_id,
        application_id,
        parseHeaders(token),
      )
      return data
    },
    portalApiDownloadDocument: async (loan_id: string, document_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiDownloadDocument(
        loan_id,
        document_id,
        parseHeaders(token),
      )
      return data
    },
    portalApiGetPaymentDetail: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiGetPaymentDetail(loan_id, parseHeaders(token))
      return data
    },
    portalApiGetPaymentBreakdown: async (
      loan_id: string,
      payload: PaymentBreakdownRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiGetPaymentBreakdown(loan_id, payload, parseHeaders(token))
      return data
    },
    portalApiFlinksTrack: async (payload: FlinksTrackEventRequestSchema) => {
      const token = await getToken()
      const { data } = await api.portalApiFlinksTrack(payload, parseHeaders(token))
      return data
    },
    portalApiFlinksSetAccount: async (payload: FlinksSetAccountRequestSchema) => {
      const token = await getToken()
      const { data } = await api.portalApiFlinksSetAccount(payload, parseHeaders(token))
      return data
    },
    portalApiFlinksPaymentCheck: async (payload: FlinksPaymentCheckRequestSchema) => {
      const token = await getToken()
      const { data } = await api.portalApiFlinksPaymentCheck(payload, parseHeaders(token))
      return data
    },
    portalApiMakePayment: async (loan_id: string, amount: number, payment_type: string) => {
      const token = await getToken()
      const { data } = await api.portalApiMakePayment(
        loan_id,
        { amount, payment_type },
        parseHeaders(token),
      )
      return data
    },
    portalApiPaymentScheduleChangeValidateSchedule: async (
      loan_id: string,
      payload: PaymentScheduleValidationRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentScheduleChangeValidateSchedule(
        loan_id,
        payload,
        parseHeaders(token),
      )
      return data
    },

    portalApiPaymentScheduleChangeProcessSelectedDate: async (
      loan_id: string,
      payload: PaymentScheduleChangeProcessSelectedDateRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentScheduleChangeProcessSelectedDate(
        loan_id,
        payload,
        parseHeaders(token),
      )
      return data
    },

    portalApiPaymentScheduleChangeComplete: async (
      loan_id: string,
      payload: PaymentScheduleChangeCompleteRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentScheduleChangeComplete(
        loan_id,
        payload,
        parseHeaders(token),
      )
      return data
    },

    portalApiPaymentDeferralValidateSchedule: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentDeferralValidateSchedule(
        loan_id,
        parseHeaders(token),
      )
      return data
    },

    portalApiPaymentDeferralProcessSelectedDate: async (
      loan_id: string,
      payload: PaymentDeferralProcessSelectedDateRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentDeferralProcessSelectedDate(
        loan_id,
        payload,
        parseHeaders(token),
      )
      return data
    },

    portalApiPaymentDeferralComplete: async (
      loan_id: string,
      requestData: PaymentDeferralCompleteRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentDeferralComplete(
        loan_id,
        requestData,
        parseHeaders(token),
      )
      return data
    },
    portalApiPaymentDeferralGetDeferralForm: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentDeferralGetDeferralForm(
        loan_id,
        parseHeaders(token),
      )
      return data
    },
    portalApiPaymentScheduleChangeGetPadAgreement: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentScheduleChangeGetPadAgreement(
        loan_id,
        parseHeaders(token),
      )
      return data
    },

    portalApiGetManualPaymentDetail: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiGetManualPaymentDetail(loan_id, parseHeaders(token))
      return data
    },

    portalApiChangePaymentMethodLinkBankAccount: async (
      loan_id: string,
      payload: ChangePaymentMethodLinkAccountRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiChangePaymentMethodLinkBankAccount(
        loan_id,
        payload,
        parseHeaders(token),
      )
      return data
    },

    portalApiChangePaymentMethodGetPadAgreement: async (
      loan_id: string,
      payload: ChangePaymentMethodGetPadAgreementRequestSchema,
    ) => {
      const token = await getToken()
      const { data } = await api.portalApiChangePaymentMethodGetPadAgreement(
        loan_id,
        payload,
        parseHeaders(token),
      )
      return data
    },

    portalApiPaymentProtectionGetCertificateOfInsurance: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiPaymentProtectionGetCertificateOfInsurance(
        loan_id,
        parseHeaders(token),
      )
      return data
    },

    portalApiProfileSettingsUpdate: async (payload: ProfileSettingsUpdateRequestSchema) => {
      const token = await getToken()
      const { data } = await api.portalApiProfileSettingsUpdate(payload, parseHeaders(token))
      return data
    },

    portalApiProfileSettingsEmailChanged: async (email_uid: string) => {
      const token = await getToken()
      const { data } = await api.portalApiProfileSettingsEmailChanged(
        email_uid,
        parseHeaders(token),
      )
      return data
    },

    portalApiSaveLanguagePreference: async (locale: string) => {
      const token = await getToken()
      const { data } = await api.portalApiSaveLanguagePreference({ locale }, parseHeaders(token))

      return data
    },
  }
}
