import { ClerkProvider } from '@clerk/clerk-react'
import { useEffect } from 'react'
import { Route, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { frFR, enUS } from '@clerk/localizations'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import { GenericError } from 'src/components/Error/GenericError'
import RootLayout from 'src/components/common/RootLayout'
import { UnauthenticatedFreshChatWrapper } from 'src/components/common/UnauthenticatedFreshChatWrapper'
import {
  ConfigurationProvider,
  CreditRenewalProvider,
  OriginationProvider,
  PrequalificationProvider,
  AuthJwtProvider,
} from 'src/contexts'
import { AutoSaveProvider } from 'src/contexts/prequalification'
import { logoImgSvg } from 'src/images'
import { PortalRoutesEnum } from 'src/portal/utils/common'
import { PortalProvider } from 'src/portal/contexts/portal'
import AppRouterEndToEnd from 'src/routers/affiliate'
import { SentryRoutes } from 'src/routers/common'
import AppRouterCreditRenewal from 'src/routers/creditrenewals'
import AppRouterOrigination from 'src/routers/origination'
import AppRouterPortal from 'src/routers/portal'
import PortalSignin from 'src/portal/pages/auth/signinClerk'
import { CreditRenewalRouteBase, StepsEnum } from 'src/types'
import { log } from 'src/utils'
import { PUBLISHABLE_KEY } from 'src/utils/constants'
import Unsubscribe from 'src/unsubscribe'

export default function AppRouter() {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  if (!PUBLISHABLE_KEY) {
    log.error('PUBLISHABLE_KEY is not set, GenericError will be shown')
  }

  useEffect(() => {
    if (location.hash.startsWith('#/')) {
      navigate(location.hash.replace('#', '')) // or history.replace
    }
  }, [location])

  return (
    <SentryRoutes>
      <Route path="/" element={<Navigate to={`/${PortalRoutesEnum.PORTAL_BASE}/`} replace />} />

      <Route
        path={`/${StepsEnum.END_TO_END}/*`}
        element={
          <PrequalificationProvider>
            <ConfigurationProvider>
              <AutoSaveProvider>
                <UnauthenticatedFreshChatWrapper shouldShow>
                  <AppRouterEndToEnd />
                </UnauthenticatedFreshChatWrapper>
              </AutoSaveProvider>
            </ConfigurationProvider>
          </PrequalificationProvider>
        }
      />
      <Route
        path={`/${PortalRoutesEnum.PORTAL_BASE}/*`}
        element={
          PUBLISHABLE_KEY ? (
            <>
              <Helmet>
                <title>{t('Portal.title')}</title>
              </Helmet>
              <ClerkProvider
                routerPush={to => navigate(to)}
                routerReplace={to => navigate(to, { replace: true })}
                publishableKey={PUBLISHABLE_KEY}
                afterSignOutUrl={`/${PortalRoutesEnum.PORTAL_BASE}`}
                signInFallbackRedirectUrl={`/${PortalRoutesEnum.PORTAL_BASE}`}
                localization={language === 'fr' ? frFR : enUS}
              >
                <PortalProvider>
                  <AppRouterPortal />
                </PortalProvider>
              </ClerkProvider>
            </>
          ) : (
            <>
              <Helmet>
                <title>{t('Portal.title')}</title>
              </Helmet>
              <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
                <GenericError />
              </RootLayout>
            </>
          )
        }
      />
      <Route
        path={`/signin`}
        element={
          PUBLISHABLE_KEY ? (
            <ClerkProvider
              routerPush={to => navigate(to)}
              routerReplace={to => navigate(to, { replace: true })}
              publishableKey={PUBLISHABLE_KEY}
              afterSignOutUrl={`/${PortalRoutesEnum.PORTAL_BASE}`}
              signInFallbackRedirectUrl={`/${PortalRoutesEnum.PORTAL_BASE}`}
              localization={language === 'fr' ? frFR : enUS}
            >
              <PortalProvider>
                <PortalSignin />
              </PortalProvider>
            </ClerkProvider>
          ) : (
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError />
            </RootLayout>
          )
        }
      />
      {/* Credit renewal path */}
      <Route
        path={`/${CreditRenewalRouteBase}/*`}
        element={
          <ConfigurationProvider>
            <AuthJwtProvider>
              <CreditRenewalProvider>
                <AppRouterCreditRenewal />
              </CreditRenewalProvider>
            </AuthJwtProvider>
          </ConfigurationProvider>
        }
      />
      {/* Unsubscribe path */}
      <Route path={`/unsubscribe`} element={<Unsubscribe />} />
      {/* This will be redirected to the route path */}
      <Route path={`/#/*`} element={<div />} />
      <Route
        path={`/*`}
        element={
          <ConfigurationProvider>
            <AuthJwtProvider>
              <OriginationProvider>
                <AppRouterOrigination />
              </OriginationProvider>
            </AuthJwtProvider>
          </ConfigurationProvider>
        }
      />
    </SentryRoutes>
  )
}
