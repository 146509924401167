import { Box, GlobalStyles, Grid2, Typography } from '@mui/material' // Fixed Grid import
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PrimaryCTA } from 'src/components/common/Buttons'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { figLogo, genericErrorImg } from 'src/images'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import Header from 'src/portal/layout/header/Header'
import PortalTheme from 'src/themes/portal'
import { captureExceptionHelper } from 'src/utils'

export const ProfileEmailChanged = () => {
  const { portalApiProfileSettingsEmailChanged } = usePortalApi()
  const { t } = useTranslation()
  const { email_uid } = useParams()
  const hasMutated = useRef(false) // Track if mutate has been called

  const mutationFn = async ({ email_uid }: { email_uid: string }) => {
    return portalApiProfileSettingsEmailChanged(email_uid)
  }
  const { mutate, isSuccess, isLoading, isError } = useMutation(mutationFn)

  useEffect(() => {
    if (!hasMutated.current && email_uid) {
      try {
        if (!email_uid) {
          throw new Error('email_uid is undefined')
        }
        mutate(
          { email_uid },
          {
            onSuccess: () => {
              hasMutated.current = true // Prevent duplicate calls
            },
          },
        )
      } catch (error) {
        captureExceptionHelper('Error in the Profile Email Changed page', error)
      }
    }
  }, [email_uid])

  if (isSuccess) {
    return (
      <Box sx={{ height: '100%', width: '100%' }}>
        <GlobalStyles
          styles={{
            width: '100%',
            [PortalTheme.breakpoints.up('md')]: {
              '.fc-widget-normal': { bottom: '48px !important', right: '48px !important' },
            },
            [PortalTheme.breakpoints.down('md')]: {
              '.fc-widget-normal': { bottom: '120px !important', right: '24px !important' },
            },
            backgroundColor: PortalTheme.color.brand1,
          }}
        />
        <Header signOut={() => ''} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '.cl-internal-y44tp9': { display: 'none' },
            [PortalTheme.breakpoints.up('md')]: {
              backgroundColor: PortalTheme.color.brand1,
              paddingLeft: '40px',
              paddingRight: '40px',
              height: 'calc(100vh - 164px)',
            },
            [PortalTheme.breakpoints.down('sm')]: {
              height: '100vh',
              paddingBottom: '90px',
              backgroundColor: PortalTheme.color.brand1,
            },
          }}
          id="container"
        >
          <Grid2
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: PortalTheme.color.grey100,
              paddingY: '48px',
              paddingX: '36px',
              width: '400px',
              borderRadius: '8px',
            }}
            spacing={4}
          >
            <Grid2 display="flex" justifyContent="center" alignItems="center">
              <img
                src={figLogo}
                alt={t('Portal.Header.alt.figLogo')}
                aria-label={t('Portal.Header.alt.figLogo')}
              />
            </Grid2>

            <Grid2 display="flex" justifyContent="center">
              <Box
                width="100%"
                maxWidth="250px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                margin="auto"
              >
                <Typography variant="h1" marginTop="32px">
                  {t('Portal.EmailChanged.title')}
                </Typography>

                <Typography variant="body1" marginTop="32px" marginBottom="16px">
                  {t('Portal.EmailChanged.description')}
                </Typography>

                <PrimaryCTA
                  href={'https://my.fig.ca'}
                  buttonText={t('Portal.Intermittent.login')}
                />
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    )
  }

  if (isLoading) {
    return <LoadingAnimation />
  }
  if (isError) {
    return (
      <ErrorPage
        image={{
          src: genericErrorImg,
          alt: t('GenericError.alt'),
        }}
        title={t('GenericError.title')}
        content={t('GenericError.content')}
        buttons={[]}
      />
    )
  }
}
