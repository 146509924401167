import { AnalyticsSnippet } from '@segment/analytics-next'
import { Value as E164Number } from 'react-phone-number-input/input'

import { ConsentType, SavedRepaymentScheduleType } from 'src/types/api'

export type FeatureFlags = {
  enableOnfido: boolean
  skipOtp: boolean
  isMaintenance: boolean
  flinksDaysOfTransaction: boolean
  enableCreditKarmaLandingPage: boolean
  enablePiiRetry: boolean
  isMaintenanceBanner: boolean
  isFigLandingPromo: boolean
  isCreditRenewalApplyButtonEnabled: boolean
  isCashContest: boolean
}

export enum PartnersEnum {
  NEO = 'Neo',
  ALTERNA = 'Alterna Savings',
  BORROWELL = 'Borrowell',
  CREDIT_KARMA = 'Credit Karma',
  THE_BRICK = 'The Brick',
  PERKOPOLIS = 'Perkopolis',
  WALMART = 'Walmart',
  LFLGROUP = 'LFL Group',
  CREDIT_KARMA_PROMO = 'Credit Karma Promo',
  FAIRSTONE = 'Fairstone Financial',
  FIG = 'Fig Financial',
  FINTEL = 'Fintel',
  RATEHUB = 'Ratehub',
  TELUS = 'Telus Perks',
}

export const CREDIT_RENEWALS_BASE_PATH = 'creditrenewal'

export enum StepsEnum {
  END_TO_END = 'application',
  LANDING = 'landing',
  PREQUALIFICATION = 'prequalification',
  OFFER = 'loanoffer',
  SECOND_LOOK_OFFER = 'loanreferral',
  CONSENTS = 'consents',
  QC_CONSENTS = 'quebecconsents',
  PAYMENT_METHOD_SELECT = 'paymentmethod',
  BANK_AUTO_CONNECT = 'bank/autoconnect',
  BANK_SUCCESS = 'bank/success',
  FLINKS_PAYMENT_CHECK = 'bank/flinkscheck',
  KYC_VERIFY = 'kyc/verify',
  KYC_PERMISSIONS_ONFIDO_ERROR = 'kyc/permissionserror',
  KYC_SUCCESS = 'kyc/success',
  KYC_FLINKS_FAIL = 'kyc/flinksfail',
  KYC_FLINKS_INVALID_BANK_ACCOUNT = 'kyc/flinksinvalidaccount',
  KYC_ID_FAIL = 'kyc/fail',
  KYC_TOS_DECLINED = 'kyc/tosdeclined',
  KYC_LOADING = 'kyc/loading',
  KYC_RETRY_GENERIC = 'kyc/retrygeneric',
  KYC_PII_RETRY = 'kyc/piiretry',
  KYC_IMAGE_QUALITY_RETRY = 'kyc/imagequalityretry',
  KYC_TOKEN_EXPIRED = 'kyc/tokenexpired',
  ORDER_REVIEW = 'order/review',
  ORDER_DECLINED = 'order/declined',
  OTP = 'otp',
  PHONE = 'phone',
  REPAYMENT_SCHEDULE_CONFIRM = 'repaymentschedule/confirm',
  REPAYMENT_SCHEDULE = 'repaymentschedule',
  LOAN_AMOUNT = 'loanamount',
  LOAN_PENDING = 'loanpending',
  SELECT_OFFER = 'offer',
  ORDER_FINISH = 'order/finish',
  OCCUPATION = 'occupation',
  LOAN_PURPOSE = 'loanpurpose',
  ERROR = 'error',
  DECLINED = 'declined',
  BANK_CONNECT_ERROR = 'bank/error',
  SETTLED = 'settled',
  EXPIRED = 'expired',
  PAGE_NOT_FOUND = 'error/invalidurl',
  NO_APP_ID = 'error/noapplicationid',
  PAYMENT_PROTECTION = 'paymentprotection',
}
export const CreditRenewalRouteBase = 'creditrenewal'
export enum CreditRenewalStepsEnum {
  ELIGIBILITY = 'eligibility',
  DECLINED = 'declined',
  CONFIRM_INFORMATION = 'confirminformation',
  LOAN_DETAILS = 'loandetails',
  REPAYMENT_SCHEDULE = 'repaymentschedule',
  REVIEW = 'review',
  PAYMENT_PROTECTION = 'paymentprotection',
  FINISH = 'finish',
  ERROR = 'error',
  PAYMENT_METHOD_SELECT = 'paymentmethod',
  BANK_AUTO_CONNECT = 'bank/autoconnect',
  BANK_CONNECT_ERROR = 'bank/error',
  BANK_SUCCESS = 'bank/success',
  FLINKS_PAYMENT_CHECK = 'bank/flinkscheck',
}

export type HeaderType = {
  title: string
  content: string
  sx?: any
  icon?: JSX.Element
  promoBanner?: JSX.Element
}

export type IconType = {
  partner?: PartnersEnum
  mobileIcon?: string
  desktopIcon?: string
}

export type LogoType = {
  src: string
  alt: string
  width?: string
  height?: string
} | null

export enum StorageKeys {
  CACHED_APPLICATIONS = 'cached_applications',
}

export interface CachedApplication {
  selected_loan_amount?: number
  selected_loan_increase_amount?: number //credit renewal use
  selected_offer_id?: string
  selected_offer_term?: number
  selected_bank_account?: string
  saved_repayment_schedule?: SavedRepaymentScheduleType | null
  consents?: ConsentType
  consent_email_source?: StepsEnum | null
  employment_status?: EmploymentStatus
  occupation?: string
  occupation_language?: string
  employer?: string
  employment_start_date?: string
  income_source?: string
  loan_purpose_category?: string
  loan_purpose_other_reason?: string
  has_saved_qc_consents?: boolean
  phone_number?: E164Number | undefined
}

export interface CachedApplications {
  [key: string]: CachedApplication
}
export enum PaymentScheduleEnum {
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  TWICE_A_MONTH = 'TWICE_A_MONTH',
  ONCE_A_MONTH = 'ONCE_A_MONTH',
}

export enum MonthlyScheduleEnum {
  FIRST = 'FIRST',
  FIFTEENTH = 'FIFTEENTH',
  LAST = 'LAST',
  OTHER = 'OTHER',
}

export enum ApplicationStatus {
  DECLINED = 'DECLINED',
  PRE_AUTHORIZED = 'PRE_AUTHORIZED',
  EXPIRED = 'EXPIRED',
  SETTLED = 'SETTLED',
  AUTHORIZED = 'AUTHORIZED',
}

export enum DeclineCodes {
  EXISTING_EMAIL = 'MC_EXISTING_EMAIL',
  EXISTING_PHONE = 'MC_EXISTING_PHONENUMBER',
  EXISTING_EMAIL_PHONE = 'MC_EXISTING_EMAIL_AND_PHONENUMBER',
}

export enum ResidentialStatus {
  OWN = 'own',
  RENT = 'rent',
  OTHER = 'other',
}

export enum ProgressEnum {
  LOAN_AMOUNT = 'Loan Amount',
  OTP = 'Phone Verification',
  PHONE = 'Phone Input',
  PAYMENT_PLAN = 'Payment Plan',
  LINK_BANK_ACCOUNT = 'Link Bank Account',
  ID_VERIFICATION = 'ID Verification',
  OCCUPATION = 'Occupation',
  LOAN_PURPOSE = 'Loan Purpose',
  REVIEW = 'Review',
  PAYMENT_PROTECTION = 'Payment Protection',
}

export enum CreditRenewalProgressEnum {
  ELIGIBILITY = 'Eligibility',
  CONFIRM_INFORMATION = 'Confirm Information',
  LOAN_DETAILS = 'Loan Details',
  REPAYMENT_SCHEDULE = 'Repayment Schedule',
  REVIEW = 'Review Loan Increase',
  PAYMENT_PROTECTION = 'Payment Protection for Loan Amount Increase',
}

export enum EmploymentStatus {
  EMPLOYED = 'Employed',
  SELF_EMPLOYED = 'Self-Employed',
  UNEMPLOYED = 'Unemployed',
  RETIRED = 'Retired',
  STUDENT = 'Student',
  DISABILITY = 'Disability / Long-term Leave',
}

export enum ProvinceCodes {
  QUEBEC = 'QC',
}

export enum ApplyLaterStatus {
  NEW = 'new',
  EXISTING = 'existing',
  ERROR = 'error',
}

export type FigAutocompleteAddress = {
  formattedAddress: string
  address_1: string
  address_2: string
  postal_code: string
  province_code: string
  city: string
  country_code: string
}

export enum PurposeEnum {
  BILLS = 'Bills/Expenses',
  DEBT = 'Debt Consolidation',
  CREDIT = 'Credit Refinancing',
  MEDICAL = 'Medical Expenses',
  EMERGENCY = 'Emergency',
  EDUCATION = 'Education',
  HOME = 'Home Improvement',
  AUTO = 'Automobile Financing / Repair',
  WEDDING = 'Wedding',
  TRAVEL = 'Travel / Leisure',
  PURCHASE = 'Purchase an item',
  OTHER = 'Other',
}

export enum HttpStatusCode {
  INTERNAL_SERVER_ERROR = 500,
  FORBIDDEN = 403,
}

export enum FailedStatusCheckType {
  KYC = 'KYC',
  OTHER = 'OTHER',
}

declare global {
  interface Window {
    threatmetrix: {
      profile: (
        profiling_domain: string,
        org_id: string,
        session_id: string,
        page_id?: string,
      ) => void
    }
    Onfido: any
    analytics: AnalyticsSnippet
    initGoogleMaps: () => void
  }
}

export interface OfferItemType {
  term_length: number
  payment_amount: number
}

export type AxiosValues = {
  axiosCode: string | undefined
  isConnectionError: boolean
  url: string | undefined
  method: string | undefined
  status: number | undefined
  isNotFound: boolean
  response: any
}

export interface AsyncRequestsInProgressType {
  getOccupation: boolean
  getRepaymentSchedule: boolean
  saveOffer: boolean
  getOnfidoStatus: boolean
  saveOccupation: boolean
  savePurposeOfLoan: boolean
  savePhoneNumber: boolean
  paymentProtectionSelection?: boolean
}

export enum OnFidoDecisionStatusEnum {
  APPROVED = 'approved',
  DECLINED = 'declined',
  REVIEW = 'review',
}
export interface OnfidoDecisionType {
  attemptCount: number
  applicationDeclinedReason?: string
  isFinished: boolean
  isApplicationDeclined: boolean
  isUserActionComplete: boolean
  reviewReason: string
  status: string
  shouldRetry: boolean
  piiMismatchReason?: string
  piiMismatchInfo?: PIIMismatchInfo
}

export interface CreditRenewalDecisionType {
  eligible_for_renewal: boolean
  decline_reasons: string[]
}

export interface PIIMismatchInfo {
  firstName: string | undefined
  lastName: string | undefined
  dob: string | undefined
}

export enum PIIMismatchReasonsEnum {
  NAME_MATCH = 'NAME',
  DOB_MATCH = 'DOB',
  ALL_MATCH = 'ALL',
  NONE_MATCH = 'None',
  NOT_REVIEWED = 'NOT_REVIEWED',
}

export enum OnfidoReviewReasonsEnum {
  PII_MISMATCH = 'pii_mismatch',
  IMAGE_QUALITY = 'image_quality',
  OTHER = 'other',
}

export enum SecondLookAmount {
  HOMEOWNER = 50000,
  NON_HOMEOWNER = 12500,
}
