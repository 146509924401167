import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AlertColor, FigAlert } from 'src/components/common/FigAlert'
import { ProfileUpdateErrorsEnum } from 'src/portal/pages/profile/constants'
import theme from 'src/themes'

export const AlertError = ({ error }: any) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  let content = t('Portal.ProfileSettings.updateFailedContent')

  if (error.response.data.errors[0].title === ProfileUpdateErrorsEnum.EMAIL_TAKEN) {
    content = t('Portal.ProfileSettings.updateFailedEmailTakenContent')
  } else if (error.response.data.errors[0].title === ProfileUpdateErrorsEnum.PHONE_NUMBER_TAKEN) {
    content = t('Portal.ProfileSettings.updateFailedPhoneNumberTakenContent')
  }

  return (
    <FigAlert
      icon={<ReportProblemIcon color="error" />}
      title={t('Portal.ProfileSettings.updateFailedTitle')}
      content={content}
      color={AlertColor.ERROR}
      fade={{ lifespanMs: 3000 }}
      alertSx={{ top: isMobile ? '75vh' : '87vh', width: isMobile ? '87%' : '75%' }}
    />
  )
}
