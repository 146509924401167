import { Checkbox, Divider, Grid2, Link, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import Notification, { InformationIconStyled } from 'src/components/common/Notification'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { ActionModalContainer } from 'src/portal/components/modals/ActionModal/ActionModalContainer'
import useModalContext from 'src/portal/hooks/useModalContext'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { ContentColumn, FigAlertError } from 'src/portal/pages/reschedule/components/common'
import { DivRoot } from 'src/portal/pages/reschedule/styles'
import theme from 'src/themes'
import { PaymentScheduleEnum } from 'src/types'
import { captureExceptionHelper } from 'src/utils'
import { pdfFileDownload } from 'src/utils/files'
import {
  getAnalyticsScreenCallback,
  getAnalyticsPageView,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

const CheckboxStyled = styled(Checkbox)({
  padding: 0,
  marginRight: '14px',
  ':hover': {
    background: 'none',
  },
})

export const TypographyDetail = styled(Typography)({
  marginTop: '4px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  verticalAlign: 'middle',
  display: 'inline-flex',
})

const PolicyContainerDiv = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
})

const ContentContainer = styled(Grid2)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '16px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  width: '100%',
  marginBottom: '24px',
  marginTop: '24px',
}))

const ContentTitle = styled(Typography)(({ theme }) => ({
  color: theme.color.black,
  fontWeight: 500,
  marginBottom: '16px',
}))

const ContentDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.color.grey3,
  marginTop: '16px',
  marginBottom: '16px',
}))

interface ConfirmScheduleProps {
  firstPaymentDate: string
  withdrawAmount: string
  isPadChecked: boolean
  setIsPadChecked: (value: boolean) => void
}

export function ChangePaymentConfirmComponent({
  firstPaymentDate,
  withdrawAmount,
  isPadChecked,
  setIsPadChecked,
}: ConfirmScheduleProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { currencyFormat, dateFormatCustom } = useLocalizedFormatters()
  const { portalApiPaymentScheduleChangeGetPadAgreement } = usePortalApi()
  const { loanDetailData } = usePortalContext()
  const paymentMethod =
    language === 'fr' ? loanDetailData?.payment_method_french : loanDetailData?.payment_method

  const {
    portalApiPaymentScheduleChange: { processSelectedDateMutation },
  } = usePortalContext()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.PAYMENT_SCHEDULE_CONFIRM_VIEW)
  const fetchLoanAgreement = async () => {
    const resp = await portalApiPaymentScheduleChangeGetPadAgreement(loanDetailData?.id || '')
    pdfFileDownload(resp?.content, `PAD Agreement.pdf`)
    track(
      ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_SCHEDULE_CHANGE_CONFIRM_FILE_DOWNLOADED,
      ANALYTICS_OBJECT_CONSTANTS.FILE,
      ANALYTICS_ACTION_CONSTANTS.DOWNLOADED,
      {
        documentType: 'PAD Agreement',
      },
    )
  }

  const data = processSelectedDateMutation?.data
  const currentPayments = data?.current_payment_cycle_remainings || []
  const upcomingPayments = data?.upcoming_payment_cycle_remainings || []

  const getDuration = () => {
    const frequency = data?.payment_frequency
    if (frequency === PaymentScheduleEnum.EVERY_WEEK) {
      return t('Portal.Reschedule.ConfirmSchedule.ScheduleFrequency.weekly')
    } else if (frequency === PaymentScheduleEnum.EVERY_TWO_WEEKS) {
      return t('Portal.Reschedule.ConfirmSchedule.ScheduleFrequency.biweekly')
    } else if (frequency === PaymentScheduleEnum.TWICE_A_MONTH) {
      return t('Portal.Reschedule.ConfirmSchedule.ScheduleFrequency.twiceAMonth')
    } else if (frequency === PaymentScheduleEnum.ONCE_A_MONTH) {
      return t('Portal.Reschedule.ConfirmSchedule.ScheduleFrequency.monthly')
    }
  }

  return (
    <DivRoot>
      <Grid2 container>
        <Grid2 size={12} paddingBottom="8px" marginBottom="8px">
          <Typography variant="h2" textAlign="center">
            {t('Portal.Reschedule.ConfirmSchedule.title')}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 container>
        <Grid2 size={12}>
          <Typography variant="body2" color="black">
            {t('Portal.Reschedule.ConfirmSchedule.frequencyTitle', {
              startDate: firstPaymentDate
                ? dateFormatCustom(
                    parseISO(firstPaymentDate as string),
                    t('Portal.common.dateFormat'),
                  )
                : '',
              withdrawAmount,
              paymentMethod,
              duration: getDuration(),
            })}
          </Typography>
        </Grid2>

        <ContentContainer size={12}>
          {!!currentPayments.length && (
            <>
              <ContentTitle>{t('Portal.Reschedule.ConfirmSchedule.currentPayment')}</ContentTitle>

              {currentPayments.map(x => (
                <ContentColumn
                  key={x.date}
                  left={currencyFormat(Number(x.amount))}
                  right={
                    x.date
                      ? dateFormatCustom(parseISO(x.date as string), t('Portal.common.dateFormat'))
                      : ''
                  }
                />
              ))}

              <ContentDivider />
            </>
          )}

          {!!upcomingPayments.length && (
            <>
              <ContentTitle>{t('Portal.Reschedule.ConfirmSchedule.upcomingPayment')}</ContentTitle>

              {upcomingPayments.map(x => (
                <ContentColumn
                  key={x.date}
                  left={currencyFormat(Number(x.amount))}
                  right={
                    x.date
                      ? dateFormatCustom(parseISO(x.date as string), t('Portal.common.dateFormat'))
                      : ''
                  }
                />
              ))}
            </>
          )}
        </ContentContainer>

        <Grid2 size={12}>
          <Notification
            content={t('Portal.Reschedule.ConfirmSchedule.notification')}
            icon={<InformationIconStyled />}
          />
        </Grid2>

        <Grid2 size={12} marginTop="16px" marginBottom="16px" style={{ cursor: 'pointer' }}>
          <PolicyContainerDiv
            onClick={(ev: any) => {
              const elementClicked = ev.target as HTMLElement

              // Don't select when clicking on links.
              if (elementClicked.tagName.toLowerCase() !== 'a') {
                setIsPadChecked(!isPadChecked)
              }
            }}
            role="button"
            data-testid="policy-checkbox"
          >
            <CheckboxStyled disableRipple checked={isPadChecked} />
            <span>
              <Typography color={theme.color.grey9} variant="body2">
                <Trans
                  i18nKey="Portal.Reschedule.ConfirmSchedule.padAgreement.text"
                  values={{
                    loanAgreementLabel: t('Portal.Reschedule.ConfirmSchedule.padAgreement.link'),
                  }}
                >
                  I agree to Fig’s{' '}
                  <Link variant="linkMedium" onClick={fetchLoanAgreement}>
                    {t('Portal.Reschedule.ConfirmSchedule.padAgreement.link')}
                  </Link>
                  <Typography component="span" display="inline" variant="body2" color="primary">
                    *
                  </Typography>
                </Trans>
              </Typography>
            </span>
          </PolicyContainerDiv>
        </Grid2>
      </Grid2>
    </DivRoot>
  )
}

export default function ChangePaymentConfirmContainer({
  nextStep,
  previousStep,
}: {
  nextStep: VoidFunction
  previousStep: VoidFunction
}) {
  const { closeAllModals } = useModalContext()
  const { t } = useTranslation()
  const {
    portalApiGetLoanDetailRefetch,
    portalApiPaymentScheduleChange: { processSelectedDateMutation, completeMutation },
  } = usePortalContext()
  const [isPadChecked, setIsPadChecked] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.PAYMENT_SCHEDULE_CONFIRM_VIEW)
  const location = useLocation()
  const modalName = `${location.pathname}/(change-payment-schedule-confirm-modal)`
  useEffect(() => {
    getAnalyticsPageView(modalName)
  }, [])

  const selectedDateData = processSelectedDateMutation?.data
  const handleContinue = async () => {
    try {
      setIsConfirming(true)
      completeMutation.reset()
      await completeMutation.mutateAsync({
        selected_frequency: selectedDateData?.payment_frequency || '',
        selected_payment_date: (selectedDateData?.next_working_payment_date || '').toString(),
      })
      await portalApiGetLoanDetailRefetch()
      track(
        ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_SCHEDULE_CHANGE_CONFIRM_COMPLETE_BUTTON_CLICKED,
        ANALYTICS_OBJECT_CONSTANTS.BUTTON,
        ANALYTICS_ACTION_CONSTANTS.CLICKED,
      )
      nextStep()
      setIsConfirming(false)
    } catch (err: any) {
      captureExceptionHelper('Error continuing from Portal.ConfirmSchedule', err)
      setIsConfirming(false)
      track(
        ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_SCHEDULE_CONFIRM_ERROR_TRIGGERED,
        ANALYTICS_OBJECT_CONSTANTS.API,
        ANALYTICS_ACTION_CONSTANTS.RESPONSE,
      )
    }
  }
  return (
    <ActionModalContainer
      modalName={modalName}
      content={
        Object.keys(selectedDateData || {}).length === 0 ||
        completeMutation.isLoading ||
        isConfirming ? (
          <LoadingAnimation />
        ) : (
          <>
            <ChangePaymentConfirmComponent
              withdrawAmount={(selectedDateData?.regular_payment_amount || '').toString()}
              firstPaymentDate={selectedDateData?.next_working_payment_date || ''}
              isPadChecked={isPadChecked}
              setIsPadChecked={setIsPadChecked}
            />
            {completeMutation.isError && <FigAlertError />}
          </>
        )
      }
      nextButtonHandler={handleContinue}
      buttonText={t('Portal.Reschedule.ConfirmSchedule.buttonConfirm')}
      nextButtonEnabled={isPadChecked}
      closeButtonHandler={() => closeAllModals(modalName)}
      backButtonHandler={() => previousStep()}
    />
  )
}
