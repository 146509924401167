import { captureMessage } from '@sentry/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'

import { useOriginationContext } from 'src/hooks'
import useApi from 'src/hooks/useApi'
import { FeatureFlags, KYCStatusOnfidoResponseDataType, StepsEnum } from 'src/types'
import { captureExceptionHelper, getAxiosErrorValues, onfidoPollingOnfido } from 'src/utils'

export const useOnfidoPolling = () => {
  const {
    isBootstrapSuccess,
    applicationId,
    setStep,
    setStartOnfidoPolling,
    updateAsyncRequestStatus,
    onfidoDecision,
    setOnfidoDecision,
    setGenericErrorPageError,
  } = useOriginationContext()
  const { getKYCStatusOnfido } = useApi()
  const { enableOnfido } = useFlags<FeatureFlags>()

  const [isOnfidoVerifySuccess, setIsOnfidoVerifySuccess] = useState<boolean>(false)
  const pollingTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const startedTimestampRef = useRef<number | null>(null)

  const onfidoPollTimeMs = 4000

  // this query will be run once on init (if enableOnfido)
  // and if there is a workflow run with is_user_action_complete but is_finished is false,
  // will keep polling until is_finished is truemeet

  const { refetch: getKYCStatusQuery } = useQuery<KYCStatusOnfidoResponseDataType, any, any>(
    ['kyc_status_onfido', applicationId],
    getKYCStatusOnfido,
    {
      cacheTime: 0,
      enabled: isBootstrapSuccess && enableOnfido, // only enable if Onfido is enabled and after bootstrap
      onSuccess: (result: KYCStatusOnfidoResponseDataType) => {
        const data = result.data
        setOnfidoDecision({
          ...onfidoDecision,
          attemptCount: data.attempt_count,
          applicationDeclinedReason: data.application_declined_reason,
          isFinished: data.is_finished,
          isApplicationDeclined: data.is_application_declined,
          isUserActionComplete: data.is_user_action_complete,
          reviewReason: data.review_reason,
          status: data.status,
          piiMismatchReason: data.pii_mismatch_reason,
          piiMismatchInfo: {
            firstName: data.pii_mismatch_info?.first_name,
            lastName: data.pii_mismatch_info?.last_name,
            dob: data.pii_mismatch_info?.dob,
          },
        })

        if (!data.is_finished) {
          // keep polling until Onfido is complete
          if (!startedTimestampRef.current) {
            startedTimestampRef.current = Date.now()
            onfidoPollingOnfido('Started', StepsEnum.ORDER_FINISH, 'Event Fired')
          }
          continuePolling()
          return
        }
        if (startedTimestampRef.current) {
          // only send this event if we had to poll at least once
          onfidoPollingOnfido('Completed', StepsEnum.ORDER_FINISH, 'Event Fired')
        }
        setIsOnfidoVerifySuccess(true)
      },
      useErrorBoundary: false,
      onError: e => {
        const axiosValues = getAxiosErrorValues(e)
        if (axiosValues?.isNotFound) {
          return // dont poll if user hasn't started Onfido
        }
        if (axiosValues?.isConnectionError) {
          captureMessage('Onfido polling network error', 'info')
          continuePolling()
          return
        }
        captureExceptionHelper('Error polling for Onfido verification status', e)
        setGenericErrorPageError(new Error('Error polling for Onfido verification status'))
        setStep(StepsEnum.ERROR)
      },
    },
  )

  const clearExistingPollingTimeout = () => {
    clearTimeout(pollingTimeoutRef.current ?? undefined)
  }

  const continuePolling = () => {
    clearExistingPollingTimeout()
    pollingTimeoutRef.current = setTimeout(getKYCStatusQuery, onfidoPollTimeMs)
  }

  useEffect(() => {
    return clearExistingPollingTimeout
  }, [])

  useEffect(() => {
    updateAsyncRequestStatus('getOnfidoStatus', enableOnfido && !isOnfidoVerifySuccess)
    // consider the request as loading so long as we have NOT finished polling.
    // ignore loading if Onfido not enabled
  }, [enableOnfido, isOnfidoVerifySuccess])

  const startOnfidoPolling = () => {
    clearExistingPollingTimeout()
    getKYCStatusQuery()
  }

  useEffect(() => {
    setStartOnfidoPolling(() => startOnfidoPolling) // should wrap setState(func) in a function
  }, [getKYCStatusQuery])
}
