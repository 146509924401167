import { CheckCircleOutlined } from '@mui/icons-material'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AlertColor, FigAlert } from 'src/components/common/FigAlert'
import theme from 'src/themes'

export const AlertSuccess = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <FigAlert
      icon={<CheckCircleOutlined color="success" />}
      title={t('Portal.ProfileSettings.updateSuccess')}
      content=""
      color={AlertColor.SUCCESS}
      fade={{ lifespanMs: 3000 }}
      alertSx={{ top: isMobile ? '75vh' : '87vh', width: isMobile ? '87%' : '75%' }}
    />
  )
}
