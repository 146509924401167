import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  desktopMenuCardIcon,
  desktopMenuCardIconActive,
  desktopMenuDocumentIcon,
  desktopMenuDocumentIconActive,
  desktopMenuHelpIcon,
  desktopMenuHelpIconActive,
  desktopMenuHomeIcon,
  desktopMenuHomeIconActive,
  desktopMenuStickerIcon,
  desktopMenuStickerIconActive,
} from 'src/images'
import { PortalRoutesEnum } from 'src/portal/utils/common'
import { DesktopMenuContainer } from 'src/portal/layout/menu/desktop/DesktopMenuContainer'
import { DesktopMenuItem } from 'src/portal/layout/menu/desktop/DesktopMenuItem'
import { MenuDivider } from 'src/portal/layout/menu/MenuDivider'
import { ANALYTICS_SEGMENT_CONSTANTS } from 'src/portal/utils/analytics'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { ApplicationStatus } from 'src/types'

interface MenuItem {
  icon: string
  iconActive: string
  iconAlt: string
  label: string
  isHidden: boolean
  route?: PortalRoutesEnum
  externalLink?: string
  event?: ANALYTICS_SEGMENT_CONSTANTS
}

interface DesktopMenuProps {
  optionalItemList: MenuItem[]
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({ optionalItemList }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { loanDetailData } = usePortalContext()
  const isSettled =
    (loanDetailData?.application_status as ApplicationStatus) === ApplicationStatus.SETTLED

  const isActive = (route: PortalRoutesEnum) => {
    if (route === '') {
      return location.pathname === `/${PortalRoutesEnum.PORTAL_BASE}`
    }
    return location.pathname.split('/')[2] === route
  }

  const menuItems: MenuItem[] = [
    {
      icon: desktopMenuHomeIcon,
      iconActive: desktopMenuHomeIconActive,
      iconAlt: t('Portal.Menu.alt.homeIcon'),
      label: t('Portal.Menu.label.home'),
      isHidden: false,
      route: PortalRoutesEnum.PORTAL_INDEX,
      event: ANALYTICS_SEGMENT_CONSTANTS.NAV_HOME_CLICKED,
    },
    {
      icon: desktopMenuCardIcon,
      iconActive: desktopMenuCardIconActive,
      iconAlt: t('Portal.Menu.alt.paymentsIcon'),
      label: t('Portal.Menu.label.managePayments'),
      isHidden: !isSettled,
      route: PortalRoutesEnum.PORTAL_PAYMENTS,
      event: ANALYTICS_SEGMENT_CONSTANTS.NAV_MANAGE_PAYMENT_CLICKED,
    },
    {
      icon: desktopMenuStickerIcon,
      iconActive: desktopMenuStickerIconActive,
      iconAlt: t('Portal.Menu.alt.documentsIcon'),
      label: t('Portal.Menu.label.viewDocuments'),
      isHidden: false,
      route: PortalRoutesEnum.PORTAL_DOCUMENTS,
      event: ANALYTICS_SEGMENT_CONSTANTS.NAV_VIEW_DOCUMENTS_CLICKED,
    },
  ]

  const footerItems: MenuItem[] = [
    {
      icon: desktopMenuHelpIcon,
      iconActive: desktopMenuHelpIconActive,
      iconAlt: t('Portal.Menu.alt.helpCenterIcon'),
      label: t('Portal.Menu.label.helpCenter'),
      isHidden: false,
      externalLink: t('Portal.Menu.links.helpCenter'),
      event: ANALYTICS_SEGMENT_CONSTANTS.NAV_HELP_CENTER_CLICKED,
    },
    {
      icon: desktopMenuDocumentIcon,
      iconActive: desktopMenuDocumentIconActive,
      iconAlt: t('Portal.Menu.alt.termsIcon'),
      label: t('Portal.Menu.label.terms'),
      isHidden: false,
      externalLink: t('Portal.Menu.links.terms'),
      event: ANALYTICS_SEGMENT_CONSTANTS.NAV_TERMS_CLICKED,
    },
  ]

  return (
    <DesktopMenuContainer>
      {menuItems
        .filter(item => !item.isHidden)
        .map((item, index) => (
          <DesktopMenuItem
            key={index}
            item={item}
            index={index}
            navigate={navigate}
            isActive={isActive(item.route!)}
          />
        ))}

      {optionalItemList?.some(item => !item.isHidden) && <MenuDivider />}

      {optionalItemList
        ?.filter(item => !item.isHidden)
        .map((item, index) => (
          <DesktopMenuItem
            key={index}
            item={item}
            index={index}
            navigate={navigate}
            isActive={isActive(item.route!)}
          />
        ))}

      {footerItems.some(item => !item.isHidden) && <MenuDivider />}

      {footerItems
        .filter(item => !item.isHidden)
        .map((item, index) => (
          <DesktopMenuItem
            key={index}
            item={item}
            index={index}
            navigate={navigate}
            isActive={false} // footer items are external links
          />
        ))}
    </DesktopMenuContainer>
  )
}

export default DesktopMenu
