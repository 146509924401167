import * as Sentry from '@sentry/react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { FlinksPaymentCheckResponseSchema } from 'src/portal/api/api.schemas'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { ConnectionStepEnum } from 'src/portal/pages/flinks/types'
import { ENVIRONMENT } from 'src/utils/constants'
import { getAnalyticsPageView } from 'src/portal/utils/analytics'

interface FlinksPaymentCheckProps {
  flinksLoginId: string
  setStep: (value: string) => void
  setInstitutionName?: (value: string) => void
}

export default function FlinksPaymentCheck({
  flinksLoginId,
  setStep,
  setInstitutionName,
}: FlinksPaymentCheckProps) {
  const { t } = useTranslation()
  const [failureCount, setFailureCount] = useState(0)
  const { portalApiFlinksPaymentCheck } = usePortalApi()
  const { loanDetailData } = usePortalContext()
  const location = useLocation()
  useEffect(() => {
    getAnalyticsPageView(`${location.pathname}/(payment-method-check-view)`)
  }, [])

  // Poll for 5 minutes to see if the flinks webhook, and q2 payment saving
  // and linking are done
  const MAX_FAILURES = 60 * 5

  useQuery<FlinksPaymentCheckResponseSchema>(
    ['flinksPaymentCheck', flinksLoginId],
    () =>
      portalApiFlinksPaymentCheck({
        login_id: flinksLoginId,
        application_id: loanDetailData?.application_id || '',
      }),
    {
      refetchInterval: failureCount <= MAX_FAILURES ? 1000 : false,
      onSuccess: (result: FlinksPaymentCheckResponseSchema): void => {
        if (result.institution_name) {
          setInstitutionName?.(result.institution_name)
        }
        if (result.is_declined) {
          setStep(ConnectionStepEnum.DECLINED)
        } else if (result.is_bank_account_exists && result.is_bank_account_linked) {
          setStep(ConnectionStepEnum.ACCOUNT_ALREADY_LINKED)
        } else if (!result.is_pii_match) {
          Sentry.captureMessage('SSP FlinksPaymentCheck: PII does not match', {
            level: 'error',
            extra: { result, borrower_id: loanDetailData?.borrower_id },
          })
          setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
        } else if (!result.is_bank_account_valid && result.is_failed) {
          Sentry.captureMessage(
            'SSP FlinksPaymentCheck: is_bank_account_valid=false and is_failed=true',
            {
              level: 'error',
              extra: { result, borrower_id: loanDetailData?.borrower_id },
            },
          )
          setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
        } else if (result.is_failed) {
          Sentry.captureMessage('SSP FlinksPaymentCheck: is_failed=true', {
            level: 'error',
            extra: { result, borrower_id: loanDetailData?.borrower_id },
          })
          setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
        } else if (result.is_complete) {
          setStep(ConnectionStepEnum.CONFIRM_NEW_PAYMENT_METHOD)
        } else {
          if (failureCount < MAX_FAILURES) {
            setFailureCount(failureCount + 1)
          } else {
            Sentry.captureMessage(
              `SSP FlinksPaymentCheck: bank connect did not complete within 5 minutes ${ENVIRONMENT}`,
              'warning',
            )
            setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
          }
        }
      },
    },
  )

  return <LoadingAnimation subtitle={t('FlinksPaymentCheck.loading')} />
}
