import { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'

import usePortalApi from 'src/portal/hooks/usePortalApi'

interface ProfileSettingsFormProps {
  borrowerDetailData: any
  portalApiGetBorrowerDetailRefetch: () => void
}

export const useProfileSettingsForm = ({
  borrowerDetailData,
  portalApiGetBorrowerDetailRefetch,
}: ProfileSettingsFormProps) => {
  const { portalApiProfileSettingsUpdate } = usePortalApi()

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      borrower_address_1: '',
      borrower_province: '',
      borrower_city: '',
      borrower_country: '',
      postal_code: '',
      unit: '',
    },
  })

  const [hasEmailChanged, setHasEmailChanged] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showEmailAlert, setShowEmailAlert] = useState(false)

  useEffect(() => {
    if (borrowerDetailData) {
      setFormData({
        firstName: borrowerDetailData.first_name || '',
        lastName: borrowerDetailData.last_name || '',
        email: borrowerDetailData.email || '',
        phone: borrowerDetailData.phone || '',
        address: {
          borrower_address_1: borrowerDetailData.billing_street || '',
          borrower_city: borrowerDetailData.billing_city || '',
          borrower_country: borrowerDetailData.billing_country || '',
          borrower_province: borrowerDetailData.billing_state || '',
          postal_code: borrowerDetailData.billing_postal_code || '',
          unit: '',
        },
      })
      setHasEmailChanged(false) // Reset when new data is loaded
    }
  }, [borrowerDetailData])

  const handleChange = (field: string, value: any) => {
    setFormData(prev => {
      const updatedFormData = { ...prev, [field]: value }

      if (field === 'email' && value !== borrowerDetailData?.email) {
        setHasEmailChanged(true)
      }

      return updatedFormData
    })
  }

  const hasChanges = useMemo(() => {
    if (!borrowerDetailData) {
      return false
    }
    return (
      formData.firstName !== (borrowerDetailData.first_name || '') ||
      formData.lastName !== (borrowerDetailData.last_name || '') ||
      formData.email !== (borrowerDetailData.email || '') ||
      formData.phone !== (borrowerDetailData.phone || '') ||
      formData.address.borrower_address_1 !== (borrowerDetailData.billing_street || '') ||
      formData.address.borrower_city !== (borrowerDetailData.billing_city || '') ||
      formData.address.borrower_country !== (borrowerDetailData.billing_country || '') ||
      formData.address.borrower_province !== (borrowerDetailData.billing_state || '') ||
      formData.address.postal_code !== (borrowerDetailData.billing_postal_code || '') ||
      formData.address.unit !== ''
    )
  }, [formData, borrowerDetailData])

  const {
    mutate: updateProfile,
    isLoading,
    isError,
    error,
    isSuccess,
    reset,
  } = useMutation({
    useErrorBoundary: false,
    mutationFn: async () => {
      const payload = {
        borrower_address_1: formData.address.borrower_address_1,
        borrower_address_2: formData.address.unit,
        borrower_city: formData.address.borrower_city,
        borrower_country: formData.address.borrower_country,
        borrower_province: formData.address.borrower_province,
        postal_code: formData.address.postal_code,
        email_address: formData.email.toLowerCase(),
        phone_number: formData.phone,
      }

      return await portalApiProfileSettingsUpdate(payload)
    },
    onSuccess: () => {
      portalApiGetBorrowerDetailRefetch()
      setFormData(prev => ({ ...prev, email: borrowerDetailData.email })) //reset email to original value to indicate that the email must be verified before it is updated

      // Show success alert
      setShowSuccessAlert(true)
      setTimeout(() => setShowSuccessAlert(false), 3000) // Hide after 3 seconds

      // Show email alert only if the email was changed
      if (hasEmailChanged) {
        setShowEmailAlert(true)
        setTimeout(() => setShowEmailAlert(false), 3000) // Hide after 3 seconds
      }

      reset() // Reset mutation status
    },
  })

  return {
    formData,
    handleChange,
    updateProfile,
    isLoading,
    isError,
    error,
    isSuccess,
    hasChanges,
    hasEmailChanged,
    showSuccessAlert,
    showEmailAlert,
  }
}
