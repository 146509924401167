import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FigTextField from 'src/components/common/FigTextField'

interface PhoneInputProps {
  value: string
  label: string
  handleChange: (field: string, value: any) => void
}

export const PhoneInput = ({ value, handleChange, label }: PhoneInputProps) => {
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { t } = useTranslation()
  const phoneErrorMessage = t('Portal.ProfileSettings.errorMessage.phoneInput')

  const isValidPhoneNumber = (phoneNumber: string) => {
    return /^(\+1)?[0-9]{10}$/.test(phoneNumber)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value
    handleChange('phone', phoneNumber)

    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      setErrorState(true)
      setErrorMessage(phoneErrorMessage)
    } else {
      setErrorState(false)
      setErrorMessage('')
    }
  }

  return (
    <FigTextField
      data-testid="phone-input"
      value={value}
      autoComplete="phone"
      label={label}
      InputProps={{
        'aria-invalid': errorState,
        'aria-describedby': errorState ? 'email-input-error-text' : undefined,
        tabIndex: 0,
      }}
      onChange={handleEmailChange}
      onBlur={() => {
        if (value && !isValidPhoneNumber(value)) {
          setErrorState(true)
          setErrorMessage(phoneErrorMessage)
        }
      }}
      helperText={errorState ? errorMessage : ''}
      error={errorState}
      errorMessage={errorMessage}
      sx={{
        backgroundColor: 'white',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: errorState ? 'error.main' : 'grey.400',
          },
          '& .MuiInputBase-input': {
            color: errorState ? 'error.main' : 'grey.900',
          },
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: 'error.main',
        },
        '& .MuiFormHelperText-root': {
          color: errorState ? 'error.main' : 'grey.900',
        },
      }}
    />
  )
}
