import { createContext, ReactNode, useState } from 'react'

import { PortalModalsEnum } from 'src/portal/utils/common'
import { AutomaticPaymentsDetailModal } from 'src/portal/components/modals/AutomaticPayments/AutomaticPaymentsDetailModal'
import DelayNextPaymentModal from 'src/portal/components/modals/DelayNextPayment/index'
import { ErrorModal } from 'src/portal/components/modals/ErrorModal'
import ManualPaymentModal from 'src/portal/components/modals/ManualPayments/index'
import { ConnectBankAccountModal } from 'src/portal/pages/flinks'
import { ChangePaymentScheduleModal } from 'src/portal/pages/reschedule/index'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

interface ModalContextType {
  openModal: (modalName: keyof typeof modalConfig) => void
  closeModal: () => void
  closeAllModals: (modalName: string) => void
  paymentAmount: number
  setPaymentAmount: (amount: number) => void
  paymentType: string
  setPaymentType: (type: string) => void
  delayedNextPaymentDate: Date | null
  setDelayedNextPaymentDate: (date: Date | null) => void
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined)

export enum PaymentTypeEnum {
  overduePayment = 'overDuePay',
  nextPayment = 'nextPay',
  extraPayment = 'oneTimePay',
  remainingBalance = 'fullPay',
}

const modalConfig = {
  [PortalModalsEnum.AUTOMATIC_PAYMENTS_DETAIL]: () => <AutomaticPaymentsDetailModal />,
  [PortalModalsEnum.MANUAL_PAYMENT_MODAL]: () => <ManualPaymentModal />,
  [PortalModalsEnum.CHANGE_PAYMENT_RESCHEDULE_START]: () => <ChangePaymentScheduleModal />,
  [PortalModalsEnum.CHANGE_PAYMENT_METHOD_START]: () => <ConnectBankAccountModal />,
  [PortalModalsEnum.PAYMENT_ERROR_MODAL]: () => <ErrorModal />,
  [PortalModalsEnum.DELAY_NEXT_PAYMENT_MODAL]: () => <DelayNextPaymentModal />,
}

const RenderModal = ({ modalStack }: { modalStack: (keyof typeof modalConfig)[] }) => {
  const activeModal = modalStack[modalStack.length - 1]
  if (!activeModal) {
    return null
  }

  const ModalComponent = modalConfig[activeModal]
  return <ModalComponent />
}

/**
 * ModalProvider component that provides modal management functionality.
 *
 * @param {Object} props - The props object.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 *
 * @returns {JSX.Element} The ModalProvider component.
 *
 * @description
 * This component uses a context to manage a stack of modals. It provides functions to open and close modals,
 * and renders the active modal from the stack.
 *
 * @function openModal
 * @param {keyof typeof modalConfig} modalName - The name of the modal to open.
 * @description Adds the specified modal to the stack if it exists in the modalConfig.
 *
 * @function closeModal
 * @description Removes the most recently added modal from the stack.
 *
 * @function RenderModal
 * @description Renders the currently active modal from the stack. If no modal is active, it returns null.
 */
const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalStack, setModalStack] = useState<(keyof typeof modalConfig)[]>([])
  const [paymentAmount, setPaymentAmount] = useState<number>(0)
  const [paymentType, setPaymentType] = useState<string>('')
  const [delayedNextPaymentDate, setDelayedNextPaymentDate] = useState<Date | null>(null)
  const openModal = (modalName: keyof typeof modalConfig) => {
    if (modalConfig[modalName]) {
      setModalStack(prev => Array.from(new Set([...prev, modalName]).values()))
    }
  }

  const closeAllModals = (modalName: string) => {
    const track = getAnalyticsScreenCallback(modalName as ANALYTICS_SCREEN_CONSTANTS)
    track(
      `${modalName} / Close Button Clicked` as ANALYTICS_SEGMENT_CONSTANTS,
      ANALYTICS_OBJECT_CONSTANTS.BUTTON,
      ANALYTICS_ACTION_CONSTANTS.CLICKED,
    )
    setModalStack([])
  }

  const closeModal = () => {
    setModalStack(prev => prev.slice(0, -1))
  }

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        closeAllModals,
        paymentAmount,
        setPaymentAmount,
        paymentType,
        setPaymentType,
        delayedNextPaymentDate,
        setDelayedNextPaymentDate,
      }}
    >
      {children}
      <RenderModal modalStack={modalStack} />
    </ModalContext.Provider>
  )
}

export { ModalProvider }
