import { useState } from 'react'

import { SimpleActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import StartManualPaymentModal from 'src/portal/components/modals/ManualPayments/ManualPaymentModal'
import ConfirmPaymentModal from 'src/portal/components/modals/ManualPayments/ConfirmPaymentModal'
import PaymentSuccessModal from 'src/portal/components/modals/ManualPayments/PaymentSuccessModal'

enum StepEnum {
  CONFIRM = 'CONFIRM',
  SUCCESS = 'SUCCESS',
  EDIT = 'EDIT',
}

function ChangePaymentScheduleContainer() {
  const [step, setStep] = useState<StepEnum>(StepEnum.EDIT)

  if (step === StepEnum.CONFIRM) {
    return (
      <ConfirmPaymentModal
        nextStep={() => setStep(StepEnum.SUCCESS)}
        previousStep={() => setStep(StepEnum.EDIT)}
      />
    )
  }
  if (step === StepEnum.SUCCESS) {
    return <PaymentSuccessModal />
  }
  return <StartManualPaymentModal nextStep={() => setStep(StepEnum.CONFIRM)} />
}

export default function ManualPaymentModal() {
  return (
    <SimpleActionModal modalName={''}>
      <ChangePaymentScheduleContainer />
    </SimpleActionModal>
  )
}
