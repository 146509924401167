import { useState } from 'react'

import { SimpleActionModal } from 'src/portal/components/modals/ActionModal/ActionModal'
import Select from 'src/portal/pages/reschedule/components/Select'
import EditPaymentSchedule from 'src/portal/pages/reschedule/components/EditPaymentSchedule'
import ConfirmSchedule from 'src/portal/pages/reschedule/components/ConfirmSchedule'
import Success from 'src/portal/pages/reschedule/components/Success'
import { ChangePaymentScheduleStepEnum } from 'src/portal/pages/reschedule/types'

function ChangePaymentScheduleContainer() {
  const [step, setStep] = useState<ChangePaymentScheduleStepEnum>(
    ChangePaymentScheduleStepEnum.SELECT,
  )

  if (step === ChangePaymentScheduleStepEnum.EDIT) {
    return (
      <EditPaymentSchedule
        nextStep={() => setStep(ChangePaymentScheduleStepEnum.CONFIRM)}
        previousStep={() => setStep(ChangePaymentScheduleStepEnum.SELECT)}
      />
    )
  }
  if (step === ChangePaymentScheduleStepEnum.CONFIRM) {
    return (
      <ConfirmSchedule
        nextStep={() => setStep(ChangePaymentScheduleStepEnum.SUCCESS)}
        previousStep={() => setStep(ChangePaymentScheduleStepEnum.EDIT)}
      />
    )
  }
  if (step === ChangePaymentScheduleStepEnum.SUCCESS) {
    return <Success />
  }
  return <Select nextStep={() => setStep(ChangePaymentScheduleStepEnum.EDIT)} />
}

export function ChangePaymentScheduleModal() {
  return (
    <SimpleActionModal modalName={''}>
      <ChangePaymentScheduleContainer />
    </SimpleActionModal>
  )
}
