import { useEffect, useState } from 'react'

import {
  getAnalyticsScreenCallback,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

interface FreshChatCustomParams {
  cf_application_id?: string
  cf_borrower_id?: string
  cf_environment: string
  cf_partner_name?: string
}

interface FreshChatContactParams {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  phoneCountryCode?: string
}

type FreshChatInitParams = {
  token: string
  externalId?: string
  host: string
  widgetUuid?: string
  restoreId?: string
}

declare global {
  interface Window {
    fcWidget: any
  }
}

const SCRIPT_ID = 'freshchat-js-sdk'

export default useFreshChatWidget
/*
 *
 */
function useFreshChatWidget({
  scriptSrc,
  shouldShow,
  freshChatCustomParams,
  freshChatContactParams,
  freshChatInitParams,
}: {
  scriptSrc: string
  shouldShow: boolean
  freshChatCustomParams: FreshChatCustomParams
  freshChatContactParams?: FreshChatContactParams
  freshChatInitParams: FreshChatInitParams
}) {
  const [isShowing, setIsShowing] = useState(false)
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.FRESH_CHAT_WIDGET)

  useEffect(() => {
    if (!freshChatContactParams) {
      return
    }

    const { phone, firstName, lastName, email } = freshChatContactParams
    if (isShowing) {
      if (firstName) {
        window?.fcWidget?.user?.setFirstName(firstName)
      }
      if (lastName) {
        window?.fcWidget?.user?.setLastName(lastName)
      }
      if (email) {
        window?.fcWidget?.user?.setEmail(email)
      }
      if (phone) {
        window?.fcWidget?.user?.setPhone?.(phone)
      }
    }
  }, [isShowing, freshChatContactParams?.phone])

  useEffect(() => {
    // If more params are added, keep it mind, it will load once
    // From App.tsx, so ensure all params are passed,
    // and shouldShow is true only once all params are populated.
    const initFreshChat = () => {
      window?.fcWidget?.init({ ...freshChatInitParams, ...freshChatContactParams })

      window?.fcWidget?.on('widget:loaded', async function () {
        await window?.fcWidget?.user?.setProperties?.({
          ...freshChatContactParams,
          ...freshChatCustomParams,
        })
        setIsShowing(true)
      })

      window?.fcWidget?.on('widget:opened', function () {
        track(
          ANALYTICS_SEGMENT_CONSTANTS.FRESH_CHAT_WIDGET_OPEN,
          ANALYTICS_OBJECT_CONSTANTS.FRESH_CHAT_WIDGET,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
      })

      window?.fcWidget?.on('widget:closed', function () {
        track(
          ANALYTICS_SEGMENT_CONSTANTS.FRESH_CHAT_WIDGET_CLOSE,
          ANALYTICS_OBJECT_CONSTANTS.FRESH_CHAT_WIDGET,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
      })

      window?.fcWidget?.on('message:sent', async function () {
        track(
          ANALYTICS_SEGMENT_CONSTANTS.FRESH_CHAT_WIDGET_MESSAGE_SENT,
          ANALYTICS_OBJECT_CONSTANTS.FRESH_CHAT_WIDGET,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
      })

      window?.fcWidget?.on('message:received', async function () {
        track(
          ANALYTICS_SEGMENT_CONSTANTS.FRESH_CHAT_WIDGET_MESSAGE_RECEIVED,
          ANALYTICS_OBJECT_CONSTANTS.FRESH_CHAT_WIDGET,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
      })

      window?.fcWidget?.on('dialog:opened', async function () {
        track(
          ANALYTICS_SEGMENT_CONSTANTS.FRESH_CHAT_WIDGET_DIALOG_OPEN,
          ANALYTICS_OBJECT_CONSTANTS.FRESH_CHAT_WIDGET,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
      })

      window?.fcWidget?.on('dialog:closed', async function () {
        track(
          ANALYTICS_SEGMENT_CONSTANTS.FRESH_CHAT_WIDGET_DIALOG_CLOSE,
          ANALYTICS_OBJECT_CONSTANTS.FRESH_CHAT_WIDGET,
          ANALYTICS_ACTION_CONSTANTS.CLICKED,
        )
      })
    }

    const cleanup = () => {
      const existingScript = document.getElementById(SCRIPT_ID)
      existingScript?.remove()
      if (window.fcWidget?.isInitialized()) {
        window?.fcWidget?.destroy?.()
        delete window?.fcWidget
        delete (window.history as any).pushState_fc_observer
        delete (window.history as any).replaceState_fc_observer
      }
      setIsShowing(false)
    }

    if (!shouldShow) {
      return cleanup
    }

    const script = document.createElement('script')
    script.id = SCRIPT_ID
    script.onload = initFreshChat
    script.async = true
    script.src = scriptSrc
    document.head.appendChild(script)

    return cleanup
  }, [shouldShow])
  // If params is passed here as a dependency, it will cause
  // the widget to force a full browser navigation change with
  // our SPA app. This will lead to unintended bugs.
  // So the dependancy must be specific to shouldShow.
}
