import React from 'react'
import { AppBar, Toolbar, Link, Container, Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

import theme from 'src/themes'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

const LinkStyled = styled(Link)(({ theme }) => ({
  margin: theme.spacing(1),
}))

const Footer: React.FC = () => {
  const { t } = useTranslation()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.NAVIGATION)

  return (
    <AppBar
      position="fixed"
      style={{
        top: 'auto',
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
      }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          <Box
            style={{
              flexGrow: 1,
            }}
          >
            <LinkStyled
              href={t('Finish.helpCentre.url')}
              onClick={() => {
                track(
                  ANALYTICS_SEGMENT_CONSTANTS.NAV_HELP_CENTER_CLICKED,
                  ANALYTICS_OBJECT_CONSTANTS.MENU,
                  ANALYTICS_ACTION_CONSTANTS.CLICKED,
                )
              }}
              target="_blank"
              style={{ textTransform: 'capitalize' }}
            >
              {t('common.helpLinkLabelGeneric')}
            </LinkStyled>
          </Box>
          <Box>
            <LinkStyled
              href={t('common.privacyPolicyUrl')}
              onClick={() => {
                track(
                  ANALYTICS_SEGMENT_CONSTANTS.NAV_PRIVACY_CLICKED,
                  ANALYTICS_OBJECT_CONSTANTS.MENU,
                  ANALYTICS_ACTION_CONSTANTS.CLICKED,
                )
              }}
              target="_blank"
            >
              {t('common.privacyPolicyLabel')}
            </LinkStyled>
            <LinkStyled
              href={t('common.termsAndConditionsUrl')}
              onClick={() => {
                track(
                  ANALYTICS_SEGMENT_CONSTANTS.NAV_TOC_CLICKED,
                  ANALYTICS_OBJECT_CONSTANTS.MENU,
                  ANALYTICS_ACTION_CONSTANTS.CLICKED,
                )
              }}
              target="_blank"
            >
              {t('common.termsOfServiceLabel')}
            </LinkStyled>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Footer
