import { Box, Link, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import { useBorrowerProvince } from 'src/portal/hooks/useBorrowerIsFromQuebec'
import PortalTheme from 'src/themes/portal'
interface RemainingBalanceContentProps {
  principalRemaining: number
  interestAccrued: number
  feesAccrued: number
  totalRemaining: number
}
export const LoanStatusContent = () => {
  return (
    <Trans
      i18nKey="Portal.Components.tooltip.content.accountStatus"
      components={{
        br: <br />,
        strong: <strong />,
        typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
      }}
    />
  )
}

export const RemainingBalanceContent = ({
  principalRemaining,
  interestAccrued,
  feesAccrued,
  totalRemaining,
}: RemainingBalanceContentProps) => {
  const { currencyFormat } = useLocalizedFormatters()
  const { t } = useTranslation()

  const balanceBreakdownContent = [
    { label: t('Portal.Components.tooltip.label.principalRemaining'), value: principalRemaining },
    { label: t('Portal.Components.tooltip.label.interestAccrued'), value: interestAccrued },
    { label: t('Portal.Components.tooltip.label.feesAccrued'), value: feesAccrued },
    { label: t('Portal.Components.tooltip.label.total'), value: totalRemaining },
  ]

  return (
    <>
      <Trans
        i18nKey="Portal.Components.tooltip.content.remainingBalance"
        components={{
          br: <br />,
          strong: <strong />,
          typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
        }}
      />
      <Box mt="32px">
        {balanceBreakdownContent.map(({ label, value }, index) => (
          <>
            {index === balanceBreakdownContent.length - 1 ? <StretchDivider /> : null}
            <SpacedRow key={label}>
              <Typography variant="body2" color={PortalTheme.color.grey9}>
                {label}
              </Typography>

              <Typography variant="body2" color={PortalTheme.color.grey9} fontWeight={'500'}>
                {currencyFormat(value)}
              </Typography>
            </SpacedRow>
          </>
        ))}
      </Box>
    </>
  )
}

export const LoanIdContent = () => (
  <Trans
    i18nKey="Portal.Components.tooltip.content.loanId"
    components={{
      typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
    }}
  />
)

export const InterestRateContent = () => {
  const { t } = useTranslation()
  return (
    <>
      <Trans
        i18nKey="Portal.Components.tooltip.content.interestRate"
        components={{
          typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
        }}
      />
      <Link
        href="https://support.fig.ca/en/support/solutions/articles/151000182957-understanding-interest-and-fees-on-your-fig-loan"
        color={PortalTheme.color.brand1}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('Portal.Components.label.learnMore')}
      </Link>
    </>
  )
}

export const LoanTermContent = () => (
  <Trans
    i18nKey="Portal.Components.tooltip.content.loanTerm"
    components={{
      typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
    }}
  />
)

export const UpcomingPaymentAmountContent = () => {
  return (
    <Trans
      i18nKey="Portal.Components.tooltip.content.upcomingPaymentAmount"
      components={{
        typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
      }}
    />
  )
}
export const UpcomingPaymentDateContent = () => {
  return (
    <Trans
      i18nKey="Portal.Components.tooltip.content.upcomingPaymentDate"
      components={{
        typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
      }}
    />
  )
}

interface TransactionRowContentProps {
  principal: number
  creditCharges: number
  paymentProtectionPremium: number | undefined
  paymentProtectionPremiumTax: number | undefined
  nsfFees: number | null
  totalAmount: number
}

export const TransactionRowContent = ({
  principal,
  creditCharges,
  paymentProtectionPremium,
  paymentProtectionPremiumTax,
  nsfFees,
  totalAmount,
}: TransactionRowContentProps) => {
  const { t } = useTranslation()
  const { currencyFormat } = useLocalizedFormatters()
  const { isFromQC } = useBorrowerProvince()

  const balanceBreakdownContent = [
    { label: t('Portal.Components.tooltip.label.principal'), value: principal },
    {
      label: isFromQC
        ? t('Portal.Components.tooltip.label.creditCharges')
        : t('Portal.Components.tooltip.label.interest'),
      value: creditCharges,
    },
    paymentProtectionPremium !== undefined
      ? {
          label: t('Portal.Components.tooltip.label.paymentProtectionPremium'),
          value: paymentProtectionPremium,
        }
      : null,
    paymentProtectionPremiumTax !== undefined
      ? {
          label: t('Portal.Components.tooltip.label.paymentProtectionPremiumTax'),
          value: paymentProtectionPremiumTax,
        }
      : null,
    nsfFees !== 0 && nsfFees !== undefined && nsfFees !== null
      ? { label: t('Portal.Components.tooltip.label.nsfFees'), value: nsfFees }
      : null,
    { label: t('Portal.Components.tooltip.label.totalAmount'), value: totalAmount },
  ].filter(item => item !== null)

  return (
    <Box mt="32px">
      {balanceBreakdownContent.map(({ label, value }, index) => (
        <>
          {index === balanceBreakdownContent.length - 1 ? <StretchDivider /> : null}
          <SpacedRow key={label}>
            <Typography variant="body2" color={PortalTheme.color.grey7}>
              {label}
            </Typography>

            <Typography variant="body2" color={PortalTheme.color.grey7}>
              {currencyFormat(value || 0)}
            </Typography>
          </SpacedRow>
        </>
      ))}
    </Box>
  )
}

interface UpcomingPaymentsContentProps {
  principal: number
  creditCharges: number
  paymentProtectionPremiumFees: number | undefined
  nsfFees: number | null
  totalAmount: number
}
export const UpcomingPaymentsContent = ({
  principal,
  creditCharges,
  paymentProtectionPremiumFees,
  nsfFees,
  totalAmount,
}: UpcomingPaymentsContentProps) => {
  const { t } = useTranslation()
  const { currencyFormat } = useLocalizedFormatters()
  const { isFromQC } = useBorrowerProvince()

  const balanceBreakdownContent = [
    { label: t('Portal.Components.tooltip.label.principal'), value: principal },
    {
      label: isFromQC
        ? t('Portal.Components.tooltip.label.creditCharges')
        : t('Portal.Components.tooltip.label.interest'),
      value: creditCharges,
    },
    paymentProtectionPremiumFees !== undefined
      ? {
          label: t('Portal.Components.tooltip.label.paymentProtectionPremium'),
          value: paymentProtectionPremiumFees,
        }
      : null,
    nsfFees !== null
      ? { label: t('Portal.Components.tooltip.label.nsfFees'), value: nsfFees }
      : null,
    { label: t('Portal.Components.tooltip.label.totalAmount'), value: totalAmount },
  ].filter(item => item !== null)

  return (
    <Box mt="32px">
      {balanceBreakdownContent.map(({ label, value }, index) => (
        <>
          {index === balanceBreakdownContent.length - 1 ? <StretchDivider /> : null}
          <SpacedRow key={label}>
            <Typography variant="body2" color={PortalTheme.color.grey7}>
              {label}
            </Typography>

            <Typography variant="body2" color={PortalTheme.color.grey7}>
              {currencyFormat(value || 0)}
            </Typography>
          </SpacedRow>
        </>
      ))}
    </Box>
  )
}
interface PaymentDeferralContentProps {
  contractualDueDate: string
  monthlyPaymentAmount: number
}
export const PaymentDeferralContent = ({
  contractualDueDate,
  monthlyPaymentAmount,
}: PaymentDeferralContentProps) => {
  const { currencyFormat } = useLocalizedFormatters()
  const { t } = useTranslation()

  return (
    <>
      <Trans
        i18nKey="Portal.Components.tooltip.paymentDeferral.content"
        components={{
          typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
        }}
      />
      <Box mt="32px">
        <Typography variant="body2" color={PortalTheme.color.grey9} fontWeight={'500'}>
          {t('Portal.Components.tooltip.paymentDeferral.label.monthlyPaymentDetails')}
        </Typography>
        <SpacedRow>
          <Typography variant="body2" color={PortalTheme.color.grey9}>
            {t('Portal.Components.tooltip.paymentDeferral.label.contractualDueDate')}
          </Typography>

          <Typography variant="body2" color={PortalTheme.color.grey9}>
            {contractualDueDate}
          </Typography>
        </SpacedRow>
        <SpacedRow>
          <Typography variant="body2" color={PortalTheme.color.grey9}>
            {t('Portal.Components.tooltip.paymentDeferral.label.monthlyPaymentAmount')}
          </Typography>

          <Typography variant="body2" color={PortalTheme.color.grey9}>
            {currencyFormat(monthlyPaymentAmount)}
          </Typography>
        </SpacedRow>
      </Box>
    </>
  )
}
