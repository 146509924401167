import { Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { LoanStatusEnum } from 'src/portal/utils/common'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { LoanStatusLabel } from 'src/portal/pages/home/summary/content/LoanStatusLabel'
import { RemainingBalanceTitle } from 'src/portal/pages/home/summary/content/Titles'
import { LoanStatusContent } from 'src/portal/pages/home/summary/content/TooltipContent'
import { ANALYTICS_SCREEN_CONSTANTS } from 'src/portal/utils/analytics'

interface LoanStatusContainerProps {
  loanStatus: LoanStatusEnum
}

export const LoanStatusContainer: React.FC<LoanStatusContainerProps> = ({ loanStatus }) => {
  const { t } = useTranslation()
  return (
    <SpacedRow>
      <Grid2>
        <LoanStatusLabel loanStatus={loanStatus} />

        <TooltipContainer
          title={t('Portal.Components.tooltip.title.accountStatus')}
          content={<LoanStatusContent />}
          eventScreen={ANALYTICS_SCREEN_CONSTANTS.SUMMARY_TAB}
          eventName="Account Status Clicked"
        />
      </Grid2>
      <RemainingBalanceTitle />
    </SpacedRow>
  )
}
