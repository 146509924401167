import { Grid2, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PrimaryCTA } from 'src/components/common/Buttons'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { AddressInput } from 'src/portal/pages/profile/components/AddressInput'
import { AlertCheckEmail } from 'src/portal/pages/profile/components/alert/AlertCheckEmail'
import { AlertError } from 'src/portal/pages/profile/components/alert/AlertError'
import { AlertSuccess } from 'src/portal/pages/profile/components/alert/AlertSuccess'
import { EmailInput } from 'src/portal/pages/profile/components/EmailInput'
import { NameInput } from 'src/portal/pages/profile/components/NameInput'
import { PhoneInput } from 'src/portal/pages/profile/components/PhoneInput'
import { useProfileSettingsForm } from 'src/portal/pages/profile/useProfileSettingsForm'
import theme from 'src/themes'
import PortalTheme from 'src/themes/portal'
import {
  getAnalyticsScreenCallback,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'

export default function ProfileSettings() {
  const { t } = useTranslation()
  const { borrowerDetailData, portalApiGetBorrowerDetailRefetch } = usePortalContext()
  const {
    formData,
    handleChange,
    updateProfile,
    isLoading,
    isError,
    error,
    showSuccessAlert,
    showEmailAlert,
  } = useProfileSettingsForm({ borrowerDetailData, portalApiGetBorrowerDetailRefetch })
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.MANUAL_PAYMENT)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const addressDefaultValue = `${formData.address.borrower_address_1}, ${formData.address.borrower_city}, ${formData.address.borrower_province}, ${formData.address.postal_code}`

  if (!borrowerDetailData) {
    return <LoadingAnimation />
  }

  return (
    <Grid2 container flexDirection="column">
      <Grid2 pb="24px">
        <Typography variant="h2" color={PortalTheme.color.black}>
          {t('Portal.ProfileSettings.title')}
        </Typography>
        <Typography variant="body2" color={PortalTheme.color.grey7} pt="24px">
          {t('Portal.ProfileSettings.description')}
        </Typography>
      </Grid2>
      <Grid2 mb="16px">
        <NameInput value={formData.firstName} label={t('Portal.ProfileSettings.firstName')} />
      </Grid2>
      <Grid2 mb="16px">
        <NameInput value={formData.lastName} label={t('Portal.ProfileSettings.lastName')} />
      </Grid2>
      <Grid2 mb="16px">
        <EmailInput
          label={t('Portal.ProfileSettings.emailAddress')}
          value={formData.email}
          handleChange={handleChange}
        />
      </Grid2>
      <Grid2 mb="16px">
        <PhoneInput
          label={t('Portal.ProfileSettings.phoneNumber')}
          value={formData.phone}
          handleChange={handleChange}
        />
      </Grid2>
      <Grid2 mb="16px">
        <AddressInput
          label={t('Portal.ProfileSettings.address')}
          defaultValue={addressDefaultValue}
          handleChange={handleChange}
        />
      </Grid2>
      <Grid2>
        <PrimaryCTA
          buttonText={t('Portal.ProfileSettings.submitButtonText')}
          style={{ maxHeight: '48px', marginTop: '8px', marginBottom: '36px' }}
          sx={!isMobile ? { px: '24px', py: '8px', maxWidth: '200px' } : {}}
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            updateProfile()
            track(
              ANALYTICS_SEGMENT_CONSTANTS.PROFILE_UPDATE_SUBMIT_BUTTON_CLICKED,
              ANALYTICS_OBJECT_CONSTANTS.BUTTON,
              ANALYTICS_ACTION_CONSTANTS.CLICKED,
            )
          }}
        />
      </Grid2>

      {isError && <AlertError error={error} />}
      {showSuccessAlert && <AlertSuccess />}
      {showEmailAlert && <AlertCheckEmail />}
    </Grid2>
  )
}
