import { Grid, Typography, styled } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useCreditRenewalContext } from 'src/hooks'
import { thankYouImg } from 'src/images'
import {
  ApplicationStatus,
  AuthorizeResponseDataType,
  CreditRenewalStepsEnum,
  StepsEnum,
} from 'src/types'
import { anyEmpty, helpCentreClicked } from 'src/utils'
import { ENVIRONMENT, EXPIRY_DETAIL, PRE_AUTH_INVALID_STATUS_DETAIL } from 'src/utils/constants'

const ImgStyle = styled('img')({
  width: '80px',
  height: '80px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

interface FinishInputType {
  bootstrapRefetch: () => void
}

export default function CreditRenewalFinish({ bootstrapRefetch }: FinishInputType) {
  const { authorize } = useApi()
  const { t } = useTranslation()
  const {
    setCRStep,
    cachedApplication,
    setStep,
    setAuthorizePaymentStartError,
    bootstrapInfo,
    asyncRequestsInProgress,
    isApplicationSettled,
    setApplicationSettled,
    setLoanAmountChangeError,
    setGenericErrorPageError,
  } = useCreditRenewalContext()
  const { jwtApiKey } = useParams()
  const cachedConsents = cachedApplication.consents!
  const [disbursalDay, setDisbursalDay] = useState('')

  const authorizeConsents = useMemo(
    () => ({
      accept_pad: cachedConsents?.accept_pad,
      accept_toc: cachedConsents?.accept_toc,
      accept_policy: true,
      check_fico: true,
      is_credit_renewal: true,
    }),
    [cachedConsents],
  )

  const timeoutRef = useRef<NodeJS.Timeout | undefined>()
  const timeToWait = 2 * 60 * 1000
  // credit renewals only needs to wait for these two async requests to complete
  const asyncRequestsAreComplete = (
    ['getRepaymentSchedule', 'saveOffer'] as (keyof typeof asyncRequestsInProgress)[]
  ).some(key => asyncRequestsInProgress[key] === false)

  useEffect(() => {
    if (!asyncRequestsAreComplete) {
      timeoutRef.current = setTimeout(() => {
        Sentry.captureMessage(
          `Async Requests are still loading after ${timeToWait / (60 * 1000)}m`,
          {
            level: 'error',
            extra: { asyncRequestsInProgress },
          },
        )
      }, timeToWait)
    }

    const cleanup = () => clearTimeout(timeoutRef.current)

    if (asyncRequestsAreComplete) {
      cleanup()
    }

    return cleanup
  }, [asyncRequestsAreComplete])

  const { isSuccess: isAuthorizeSuccess } = useQuery<AuthorizeResponseDataType>(
    ['authorize', authorizeConsents],
    () => authorize(authorizeConsents),
    {
      useErrorBoundary: false,
      enabled:
        asyncRequestsAreComplete &&
        bootstrapInfo?.application?.status !== ApplicationStatus.SETTLED,

      onSuccess: result => {
        setApplicationSettled(
          result.data.status === ApplicationStatus.SETTLED ||
            result.data.status === ApplicationStatus.AUTHORIZED,
        )
        bootstrapRefetch()
        setDisbursalDay(result.data.disbursal_day)
      },
      onError: (result: any) => {
        const { detail, errors, status } = result?.response?.data || {}
        if (
          detail === PRE_AUTH_INVALID_STATUS_DETAIL &&
          (status || '').toLowerCase() === 'declined'
        ) {
          setCRStep(CreditRenewalStepsEnum.DECLINED, jwtApiKey!)
          return
        }
        bootstrapRefetch()
        if (detail === EXPIRY_DETAIL) {
          setStep(StepsEnum.EXPIRED)
        } else if (
          (errors?.[0]?.detail || '').toLowerCase().indexOf('regenerate offer and schedule') !== -1
        ) {
          setLoanAmountChangeError(true)
          setCRStep(CreditRenewalStepsEnum.REVIEW, jwtApiKey!)
        } else if (
          (errors?.[0]?.detail || '')
            .toLowerCase()
            .indexOf('start date is less than system date') !== -1
        ) {
          setAuthorizePaymentStartError(true)
          setCRStep(CreditRenewalStepsEnum.REVIEW, jwtApiKey!)
        } else {
          Sentry.captureMessage(`Error on authorize ${ENVIRONMENT}`, {
            level: 'error',
            extra: { result, errors: JSON.stringify(errors, null, 2) },
          })
          setGenericErrorPageError(
            new Error(`Error on authorize ${ENVIRONMENT}: ${JSON.stringify(errors, null, 2)}`),
          )
          setCRStep(CreditRenewalStepsEnum.ERROR, jwtApiKey!)
        }
      },
    },
  )

  const { name: partnerName, dashboard_page_url: partnerUrl } = bootstrapInfo?.partner ?? {}
  useEffect(() => {
    if (anyEmpty(partnerName, partnerUrl)) {
      Sentry.captureMessage(
        `Missing partner info: ${JSON.stringify(bootstrapInfo?.partner)}`,
        'warning',
      )
    }
  }, [])
  const helpCenterLabel = t('Finish.helpCentre.label')

  //  authorize in progress will see a loading animation
  if (!isAuthorizeSuccess) {
    return <LoadingAnimation subtitle={t('Finish.loading')} />
  }

  // new and previously settled applications will see the finish page
  if (isApplicationSettled || disbursalDay || bootstrapInfo?.application?.disbursal_day) {
    return (
      <DivRoot>
        <Grid container>
          <Grid item xs={12} paddingBottom={'0px'} marginBottom={'16px'}>
            <ImgStyle
              src={thankYouImg}
              alt={t('CreditRenewals.Finish.alt')}
              role="img"
              aria-label={t('CreditRenewals.Finish.alt')}
            />
            <Typography textAlign="center" variant="h1" marginTop={'32px'}>
              {t('CreditRenewals.Finish.title')}
            </Typography>
            <Typography textAlign="center" variant="body2" marginTop={'16px'}>
              {t('CreditRenewals.Finish.disbursalDate', {
                disbursalDay: disbursalDay || bootstrapInfo?.application?.disbursal_day,
              })}
            </Typography>
            <Typography display="block" textAlign="center" variant="label" marginTop={'16px'}>
              {t('Finish.questionsTitle')}
            </Typography>
            <Typography textAlign="center" variant="body2" marginTop={'16px'}>
              {t('Finish.questionsContent')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="center">
            <PrimaryCTA
              href={t('Finish.helpCentre.url')}
              onClick={() => {
                helpCentreClicked(helpCenterLabel, StepsEnum.ORDER_FINISH)
              }}
              buttonText={helpCenterLabel}
              aria-label={helpCenterLabel}
            />
          </Grid>
        </Grid>
      </DivRoot>
    )
  }
}
