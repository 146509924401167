import { Checkbox, FormControlLabel, Link, Typography, Grid2 } from '@mui/material'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import usePortalApi from 'src/portal/hooks/usePortalApi'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { ConnectionStepEnum, LinkBankAccountStatusEnum } from 'src/portal/pages/flinks/types'
import PortalTheme from 'src/themes/portal'
import { captureExceptionHelper } from 'src/utils'
import { pdfFileDownload } from 'src/utils/files'
import {
  getAnalyticsScreenCallback,
  getAnalyticsPageView,
  ANALYTICS_SCREEN_CONSTANTS,
  ANALYTICS_SEGMENT_CONSTANTS,
  ANALYTICS_OBJECT_CONSTANTS,
  ANALYTICS_ACTION_CONSTANTS,
} from 'src/portal/utils/analytics'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { RoundedBox } from 'src/portal/components/RoundedBox'

const ConfirmNewPaymentMethodContent = ({
  consentCheckboxChecked,
  setConsentCheckboxChecked,
  refetchPadAgreement,
}: {
  consentCheckboxChecked: boolean
  setConsentCheckboxChecked: (value: boolean) => void
  refetchPadAgreement: () => void
}) => {
  const { loanDetailData, paymentDetailData } = usePortalContext()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.PAYMENT_METHOD_CHANGE_VIEW)

  const fetchLoanAgreement = async () => {
    refetchPadAgreement()
    track(
      ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_METHOD_FILE_DOWNLOADED,
      ANALYTICS_OBJECT_CONSTANTS.FILE,
      ANALYTICS_ACTION_CONSTANTS.DOWNLOADED,
      {
        documentType: 'PAD Agreement',
      },
    )
  }

  const checkBoxHandler = () => {
    setConsentCheckboxChecked(!consentCheckboxChecked)
    track(
      !consentCheckboxChecked
        ? ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_METHOD_CONFIRM_CONSENT_CHECKED
        : ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_METHOD_CONFIRM_CONSENT_UNCHECKED,
      ANALYTICS_OBJECT_CONSTANTS.CHECKBOX,
      ANALYTICS_ACTION_CONSTANTS.CLICKED,
    )
  }
  if (!loanDetailData || !paymentDetailData) {
    return <LoadingAnimation />
  }
  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={consentCheckboxChecked} onChange={checkBoxHandler} />}
        label={
          <Trans
            i18nKey="Portal.Components.modal.confirmNewPaymentMethod.label.consentCheckbox"
            components={{
              typography: <Typography variant="body2" color={PortalTheme.color.grey9} />,
              linkComponent: (
                <Link
                  color={PortalTheme.color.brand1}
                  onClick={(ev: any) => {
                    ev?.preventDefault()
                    fetchLoanAgreement()
                  }}
                >
                  PAD agreement
                </Link>
              ),
            }}
          />
        }
      />
    </>
  )
}

export const ConfirmNewPaymentMethod = ({
  setStep,
  institutionName,
}: {
  setStep: (value: string) => void
  institutionName?: string
}) => {
  const { t } = useTranslation()
  const { loanDetailData, portalApiGetLoanDetailRefetch } = usePortalContext()
  const {
    portalApiChangePaymentMethodGetPadAgreement,
    portalApiChangePaymentMethodLinkBankAccount,
  } = usePortalApi()
  const track = getAnalyticsScreenCallback(ANALYTICS_SCREEN_CONSTANTS.PAYMENT_METHOD_CHANGE_VIEW)
  const location = useLocation()
  const modalName = `${location.pathname}/(payment-method-confirm-view)`
  useEffect(() => {
    getAnalyticsPageView(modalName)
  }, [])

  const [consentCheckboxChecked, setConsentCheckboxChecked] = useState(false)
  const { refetch: refetchPadAgreement, isLoading: isPadAgreementLoading } = useQuery(
    ['padAgreement', loanDetailData?.id],
    () =>
      portalApiChangePaymentMethodGetPadAgreement(loanDetailData?.id || '', {
        application_id: loanDetailData?.application_id || '',
      }),
    {
      enabled: false,
      onError: (error: any) => {
        captureExceptionHelper('Error getting PAD agreement', error)
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_METHOD_ERROR_TRIGGERED,
          ANALYTICS_OBJECT_CONSTANTS.API,
          ANALYTICS_ACTION_CONSTANTS.RESPONSE,
        )
      },
      onSuccess: result => {
        if (result.content) {
          pdfFileDownload(result.content, `PAD Agreement.pdf`)
        }
      },
    },
  )

  const { mutate: linkBankAccount, isLoading: isLinkBankAccountLoading } = useMutation(
    ({ loanId }: { loanId: string }) =>
      portalApiChangePaymentMethodLinkBankAccount(loanId, {
        application_id: loanDetailData?.application_id || '',
      }),
    {
      onSuccess: data => {
        if (data.status === LinkBankAccountStatusEnum.ALREADY_LINKED) {
          setStep(ConnectionStepEnum.ACCOUNT_ALREADY_LINKED)
        } else {
          setStep(ConnectionStepEnum.BANK_SUCCESS)
        }
        portalApiGetLoanDetailRefetch()
      },
      onError: (error: any) => {
        captureExceptionHelper('Error connecting bank account', error)
        setStep(ConnectionStepEnum.BANK_CONNECT_ERROR)
        track(
          ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_METHOD_ERROR_TRIGGERED,
          ANALYTICS_OBJECT_CONSTANTS.API,
          ANALYTICS_ACTION_CONSTANTS.RESPONSE,
        )
      },
    },
  )
  const handleConfirm = () => {
    linkBankAccount({ loanId: loanDetailData?.id || '' })
    track(
      ANALYTICS_SEGMENT_CONSTANTS.PAYMENT_METHOD_CONFIRM_COMPLETE_BUTTON_CLICKED,
      ANALYTICS_OBJECT_CONSTANTS.API,
      ANALYTICS_ACTION_CONSTANTS.RESPONSE,
    )
  }

  return (
    <RoundedBox>
      <Grid2
        data-testid="action-modal-container"
        container
        direction="column"
        sx={{
          height: '50vh',
          overflow: 'hidden', // Prevent outer scroll
        }}
      >
        {/* Scrollable Content */}
        <Grid2
          sx={{
            flex: 1,
            overflowY: 'auto',
            px: '24px',
          }}
        >
          <Grid2 size={12} textAlign="center" pb="24px">
            <Typography variant="h2" fontWeight={500} color={PortalTheme.color.grey10}>
              {t('Portal.Components.modal.confirmNewPaymentMethod.title')}
            </Typography>

            <Typography variant="body2" color={PortalTheme.color.grey10}>
              {t('Portal.Components.modal.confirmNewPaymentMethod.description', {
                bankAccountName: institutionName || '',
              })}
            </Typography>
          </Grid2>

          <Grid2 sx={{ flexGrow: 1 }} display="flex" justifyContent="center" alignItems="center">
            {isLinkBankAccountLoading ? (
              <LoadingAnimation />
            ) : (
              <ConfirmNewPaymentMethodContent
                consentCheckboxChecked={consentCheckboxChecked}
                setConsentCheckboxChecked={setConsentCheckboxChecked}
                refetchPadAgreement={refetchPadAgreement}
              />
            )}
          </Grid2>
        </Grid2>

        {/* Sticky CTA */}
        <Grid2
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            padding: '16px',
            borderTop: `1px solid ${PortalTheme.color.grey2}`,
            zIndex: 10,
          }}
        >
          <PrimaryCTA
            data-testid="action-modal-next-button"
            onClick={handleConfirm}
            fullWidth
            buttonText={t('Portal.Components.modal.confirmNewPaymentMethod.buttonText')}
            disabled={!consentCheckboxChecked || isPadAgreementLoading || isLinkBankAccountLoading}
          />
        </Grid2>
      </Grid2>
    </RoundedBox>
  )
}
