import { Box, Grid2, Typography } from '@mui/material'

import PortalTheme from 'src/themes/portal'

interface PaymentMenuItemProps {
  title: string
  description: string
  icon: string
  onClickHandler: () => void
}
/**
 * Component representing a payment menu item. To be rendered on the payments page.
 *
 * @param {string} title - The title of the payment menu item.
 * @param {string} description - The description of the payment menu item.
 * @param {string} icon - The URL of the icon to be displayed.
 * @param {function} onClickHandler - The function to be called when the item is clicked.
 *
 * @returns {JSX.Element} The rendered payment menu item component.
 */
export const PaymentMenuItem = ({
  title,
  description,
  icon,
  onClickHandler,
}: PaymentMenuItemProps) => {
  return (
    <Box
      onClick={onClickHandler}
      sx={{
        backgroundColor: '#F3F4F6',
        px: '16px',
        py: '24px',
        mb: '24px',
        minHeight: '130px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#E0E0E0',
        },
      }}
    >
      <Grid2 container>
        <Grid2 size={9}>
          <Typography variant="body1" fontWeight={500} color={PortalTheme.color.grey10}>
            {title}
          </Typography>
          <Typography variant="body3" color={PortalTheme.color.grey7}>
            {description}
          </Typography>
        </Grid2>
        <Grid2 size={3} pl="16px" display="flex" alignItems="center" justifyContent="center">
          <img src={icon} />
        </Grid2>
      </Grid2>
    </Box>
  )
}
