import Axios, { AxiosPromise } from 'axios'

import {
  AgreementRequestType,
  AuthorizeRequestType,
  GetOccupationsRequestType,
  LanguagePreferenceType,
  OnfidoTokenRequest,
  PaymentProtectionSelectionType,
  PhoneNumberRequestType,
  PurposeOfLoanRequestType,
  RepaymentScheduleRequestType,
  UpdateApplicantDataRequestType,
} from 'src/types/api'
import { API_URL } from 'src/utils/constants'
import useAuthJwtContext from 'src/hooks/useAuthJWTContext'

// Setting axios defaults to send cookies
Axios.defaults.withCredentials = true

interface ApiReturnProps {
  id?: number
  axiosPost: typeof Axios.post
  axiosGet: typeof Axios.get
  saveOffer: (offer_id: string) => AxiosPromise
  saveOfferTerm: (data: {
    loan_amount: number
    term: number
    estimated_due_amount: number
  }) => AxiosPromise
  getOffers: (loan_amount: number) => AxiosPromise
  getPaymentMethods: () => AxiosPromise
  linkPaymentMethod: (payment_method_id: string) => AxiosPromise
  bootstrap: () => AxiosPromise
  getIsVerified: () => AxiosPromise
  getAgreement: (payload: AgreementRequestType) => AxiosPromise
  generateRepaymentSchedule: (payload: RepaymentScheduleRequestType) => AxiosPromise
  authorize: (payload: AuthorizeRequestType) => AxiosPromise
  flinksTrack: (payload: { eventName: string; borrowerId: string }) => AxiosPromise
  flinksSetAccount: (payload: {
    loginId: string
    accountId: string
    borrowerId: string
  }) => AxiosPromise
  flinksPaymentCheck: (loginId: string) => AxiosPromise
  saveOccupation: (payload: {
    employmentStatus: string
    occupation: string
    employer?: string
    duration?: number
    incomeSource?: string
  }) => AxiosPromise
  savePhoneNumber: (payload: PhoneNumberRequestType) => AxiosPromise
  savePurposeOfLoan: (payload: PurposeOfLoanRequestType) => AxiosPromise
  paymentProtectionSelection: (payload: PaymentProtectionSelectionType) => AxiosPromise
  savePartnerSession: (payload: string) => AxiosPromise
  saveCampaignId: (payload: string) => AxiosPromise
  saveEnteredOrigination: () => AxiosPromise
  saveEmailConsent: (consent: boolean) => AxiosPromise
  getVerification: (transaction_id: string) => AxiosPromise
  getKYCStatusOnfido: () => AxiosPromise
  setOnfidoUserActionComplete: () => AxiosPromise
  getOccupations: (payload: GetOccupationsRequestType) => AxiosPromise
  sessionVerify: (page: string) => AxiosPromise
  getOnfidoToken: (payload: OnfidoTokenRequest) => AxiosPromise
  savePiiData: (data: {
    first_name?: string | null
    last_name?: string | null
    date_of_birth?: string | null
  }) => AxiosPromise
  getAuthorizedTimestamp: () => AxiosPromise
  creditRenewalCheckEligibility: () => AxiosPromise
  creditRenewalUpdateApplicantData: (payload: UpdateApplicantDataRequestType) => AxiosPromise
  getApplicationSyncStatus: () => AxiosPromise
}

const getHeaders = (jwtOrigination: string) => ({
  'Content-Type': 'application/json',
  authorization: jwtOrigination,
})

export const saveLanguagePreference = async (
  payload: LanguagePreferenceType,
  jwtOrigination: string,
) => {
  const res = await Axios.post(
    `${API_URL}v1/applications/save_language_preference`,
    {
      ...payload,
    },
    { headers: getHeaders(jwtOrigination) },
  )
  return res
}

export const saveQuebecConsents = async (jwtOrigination: string) => {
  return Axios.post(
    `${API_URL}v1/applications/save_quebec_consents`,
    {},
    { headers: getHeaders(jwtOrigination) },
  )
}

export default useApi
/*
 *
 */
function useApi(): ApiReturnProps {
  const { jwtApiKey } = useAuthJwtContext()
  const headers = getHeaders(jwtApiKey)

  return {
    axiosPost: (url: string, data?: any) => {
      return Axios.post(url, data, { headers })
    },
    axiosGet: (url: string) => {
      return Axios.post(url, { headers })
    },
    saveOffer: async (offer_id: string) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/save_offer`,
        { offer_id },
        { headers },
      )
      return res
    },
    saveOfferTerm: async (data: {
      loan_amount: number
      term: number
      estimated_due_amount: number
    }) => {
      const res = await Axios.post(`${API_URL}v1/applications/save_offer_term`, data, {
        headers,
      })
      return res
    },
    getOffers: async (loan_amount: number) => {
      const { data } = await Axios.post(
        `${API_URL}v1/applications/offers`,
        { loan_amount },
        { headers },
      )
      return data
    },
    getPaymentMethods: async () => {
      const { data } = await Axios.get(`${API_URL}v1/borrowers/get_payment_methods`, { headers })

      return data
    },
    linkPaymentMethod: async (payment_method_id: string) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/link_payment_method`,
        { payment_method_id },
        { headers },
      )
      return res
    },
    bootstrap: async () => {
      const { data } = await Axios.get(`${API_URL}v1/applications/bootstrap`, {
        headers,
        withCredentials: true,
      })
      return data
    },
    getIsVerified: async () => {
      const { data } = await Axios.post(`${API_URL}v1/applications/is_verified`, {}, { headers })
      return data
    },
    getAgreement: async (payload: AgreementRequestType) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/get_agreement`,
        { ...payload },
        { headers },
      )
      return res
    },
    generateRepaymentSchedule: async (payload: RepaymentScheduleRequestType) => {
      const { data } = await Axios.post(
        `${API_URL}v1/applications/v2/generate_payment_schedule`,
        { ...payload },
        { headers },
      )
      return data
    },
    authorize: async (payload: AuthorizeRequestType) => {
      const { data } = await Axios.post(
        `${API_URL}v1/applications/authorize`,
        { ...payload },
        {
          headers,
          withCredentials: true,
        },
      )
      return data
    },
    flinksTrack: async ({ eventName, borrowerId }: { eventName: string; borrowerId: string }) => {
      return await Axios.post(
        `${API_URL}integrations/flinks/track`,
        {
          event_name: eventName,
          borrower_id: borrowerId,
        },
        { headers },
      )
    },
    flinksSetAccount: async ({
      loginId,
      accountId,
      borrowerId,
    }: {
      loginId: string
      accountId: string
      borrowerId: string
    }) => {
      return await Axios.post(
        `${API_URL}integrations/flinks/set-account`,
        {
          login_id: loginId,
          account_id: accountId,
          borrower_id: borrowerId,
        },
        { headers },
      )
    },
    flinksPaymentCheck: async (login_id: string) => {
      const { data } = await Axios.post(
        `${API_URL}v1/applications/flinks_payment_check`,
        { login_id },
        { headers },
      )
      return data
    },
    saveOccupation: async ({
      employmentStatus,
      occupation,
      employer,
      duration,
      incomeSource,
    }: {
      employmentStatus: string
      occupation: string
      employer?: string
      duration?: number
      incomeSource?: string
    }) => {
      return await Axios.post(
        `${API_URL}v1/applications/save_employment_info`,
        {
          occupation,
          employment_status: employmentStatus,
          employer,
          duration,
          income_source: incomeSource,
        },
        { headers },
      )
    },
    savePhoneNumber: async (payload: PhoneNumberRequestType) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/save_phone_number`,
        { ...payload },
        { headers },
      )
      return res
    },
    paymentProtectionSelection: async (payload: PaymentProtectionSelectionType) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/payment_protection_selection`,
        { ...payload },
        { headers },
      )
      return res
    },
    savePurposeOfLoan: async (payload: PurposeOfLoanRequestType) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/save_purpose_of_loan`,
        { ...payload },
        { headers },
      )
      return res
    },
    savePartnerSession: async (partner_session_id: string) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/save_partner_session`,
        { partner_session_id },
        { headers },
      )
      return res
    },
    saveCampaignId: async (campaign_id: string) => {
      const res = await Axios.post(
        `${API_URL}v1/applications/save_campaign_id`,
        { campaign_id },
        { headers },
      )
      return res
    },
    saveEnteredOrigination: async () => {
      const res = await Axios.post(
        `${API_URL}v1/applications/save_entered_origination_timestamp`,
        {},
        { headers },
      )
      return res
    },
    saveEmailConsent: async (consent: boolean) =>
      Axios.post(`${API_URL}v1/applications/save_email_consent`, { consent }, { headers }),
    getVerification: async (transaction_id: string) => {
      const { data } = await Axios.post(
        `${API_URL}integrations/kyc/status`,
        { transaction_id },
        { headers },
      )
      return data
    },
    getKYCStatusOnfido: async () => {
      const { data } = await Axios.get(`${API_URL}v1/applications/kyc_status_onfido`, { headers })
      return data
    },
    setOnfidoUserActionComplete: async () => {
      const { data } = await Axios.post(
        `${API_URL}v1/applications/set_onfido_user_action_complete`,
        {},
        { headers },
      )
      return data
    },
    getOccupations: async ({ language }: GetOccupationsRequestType) => {
      const { data } = await Axios.get(`${API_URL}v1/occupation/get_occupations`, {
        headers,
        params: { language },
      })
      return data
    },
    savePiiData: async (data: {
      first_name?: string | null
      last_name?: string | null
      date_of_birth?: string | null
    }) => {
      const res = await Axios.post(`${API_URL}v1/applications/save_pii_data`, data, {
        headers,
      })
      return res
    },
    getApplicationSyncStatus: async () => {
      const { data } = await Axios.get(`${API_URL}v1/applications/get_application_sync_status`, {
        headers,
      })
      return data
    },
    /**
     * This should run in the background
     */
    sessionVerify: async (page: string) => {
      return Axios.post(`${API_URL}v1/applications/verify`, { page }, { headers })
    },
    getOnfidoToken: async (payload: OnfidoTokenRequest) => {
      const { data } = await Axios.post(
        `${API_URL}integrations/kyc/token_onfido`,
        { ...payload },
        { headers },
      )
      return data
    },
    getAuthorizedTimestamp: async () => {
      const { data } = await Axios.get(`${API_URL}credit_renewals/get_authorized_timestamp`, {
        headers,
      })
      return data
    },
    creditRenewalCheckEligibility: async () => {
      const { data } = await Axios.get(`${API_URL}credit_renewals/check_eligibility`, { headers })
      return data
    },
    creditRenewalUpdateApplicantData: async (payload: any) => {
      const { data } = await Axios.post(
        `${API_URL}credit_renewals/update_applicant`,
        { ...payload },
        { headers },
      )
      return data
    },
  }
}
