import { useMemo } from 'react'

import { LoanStatusEnum } from 'src/portal/utils/common'
import usePortalContext from 'src/portal/hooks/usePortalContext'

/**
 * Custom hook to determine the eligibility for payment deferral.
 *
 * @returns An object containing:
 * - `isEligibleToDelayNextPayment`: A boolean indicating if the user is eligible to delay the next payment.
 * - `manualPaymentsOn`: A boolean indicating if the user has manual payments enabled.
 * - `loanStatus`: The current status of the loan.
 *
 * The eligibility is determined based on several conditions:
 * - The user must not have manual payments enabled.
 * - The loan status must not be overdue.
 */
export const usePaymentDeferralEligibility = () => {
  const { loanDetailData } = usePortalContext()

  const manualPaymentsOn = !!loanDetailData?.manual_pay

  const loanStatusOverdue = useMemo(
    () => (loanDetailData?.loan_status as LoanStatusEnum) === LoanStatusEnum.OVERDUE,
    [loanDetailData?.loan_status],
  )
  const isEligibleToDelayNextPayment = ![
    manualPaymentsOn, // manual pay users
    loanStatusOverdue, // users with overdue payments
  ].some(Boolean)
  return { isEligibleToDelayNextPayment, manualPaymentsOn, loanStatus: loanDetailData?.loan_status }
}
